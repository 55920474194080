import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CurrentDataService } from './current-data.service';
import { environment } from '../../environments/environment';

const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient,
    private currentData: CurrentDataService) { }
  
  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Authorization':  localStorage.getItem('token')
    });
    return headers;
  }

  getFile(path: any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams ({fromObject:{path}});
    return this.http.get(apiUrl + '/File/GetFileBase64', {headers, params});
  }
    getFileS(path: any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams ({fromObject:{path}});
    return this.http.get(apiUrl + '/File/GetFileBase64Serealizado', {headers, params});
  }
  getfileprueba(){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    //let params = new HttpParams ({fromObject:{path}});
    return this.http.get(apiUrl + '/File/GetFile', {headers});
  }
}
