import * as CryptoJS from 'crypto-js';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';

import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';

const apiUrl = environment.apiUrl;


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  flag: string = 'TokenInvalid';
  claveSecret = 'clave-secreta-api';

  constructor(private http: HttpClient) { }

  login(credentials) {
    return this.http.post(apiUrl + '/Login/Autentificate', credentials);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token') || "";
    const jwtHelper = new JwtHelperService();
    return !jwtHelper.isTokenExpired(token);
  }

  getSeeschweiler(): boolean {
    this.http.get(apiUrl + 'users/seeschweiler').subscribe(data => {
    });
    return true;
  }

  private decodeToken() {
    const token = localStorage.getItem('token');
    if (token) {
      const jwtData = token.split('.')[1];

      const decodedJwtJsonData = this.b64DecodeUnicode(jwtData);
      const decodedJwtData = JSON.parse(decodedJwtJsonData);
      return decodedJwtData;
    }
    return
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  decodeTokenPassword() {

    const tokenUri = localStorage.uri;



    var signature = CryptoJS.HmacSHA256(tokenUri.split('.')[0] + "." + tokenUri.split('.')[1], this.claveSecret).toString(CryptoJS.enc.Base64);

    let input = tokenUri.split('.')[2]
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    let signatureFinally = input + '='

    if (signatureFinally === signature) {
      const header = tokenUri.split('.')[0];
      const decodedheader = window.atob(header);
      if (decodedheader.indexOf('') != -1) {
        return this.flag
      } else {
        try {
          const parseHeader = JSON.parse(window.atob(header));
          if (parseHeader.alg === 'HS256') {
            const jwtData = tokenUri.split('.')[1];
            if (jwtData !== undefined) {
              const decodedJwtJsonData = window.atob(jwtData);
              try {
                const decodedJwtDataEmail = JSON.parse(decodedJwtJsonData);
                return decodedJwtDataEmail
              }
              catch (error) {
                if (error instanceof SyntaxError) {
                  return this.flag
                }
              }
            } else {
              return this.flag
            }
          } else {
            return this.flag
          }
        }
        catch (error) {
          if (error instanceof SyntaxError) {
            return this.flag
          }
        }
      }
    } else {
      return this.flag
    }
  }

  getUser() {
    return JSON.parse(this.decodeToken().Users);
  }
  getProfile() {
    return JSON.parse(this.decodeToken().Profile);
  }
  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return headers;
  }

  getDataToken(token, session = true) {
    let headers = this.getHeaders();
    let sender = {
      token: token,
      session: session
    }
    if (token) {
      return this.http.post(apiUrl + `/TokenDecode/getInformation`, sender, { headers }).subscribe(
        (data: any) => {
          if (!session) {
            localStorage.setItem('token', data.Token);
          }
        });
    }
  }
  isValidateSession() {
    const token = localStorage.getItem('token').slice(7);
    const jwtData = token.split('.')[1];
    const decodedJwtJsonData = window.atob(jwtData);
    const decodedJwtData = JSON.parse(decodedJwtJsonData);
    let fecha1 = moment(new Date());
    let fecha2 = moment(new Date(decodedJwtData.exp * 1000));
    let minutosRestantes = fecha2.diff(fecha1, 'minutes');

    if (minutosRestantes <= 5 && minutosRestantes >= 0) {
      this.getDataToken(token, false);
    }
  }


  forgotPassword(email: any) {
    let params = new HttpParams({ fromObject: { email } });
    return this.http.get(apiUrl + '/login/ForgetPdw', { params });

  }
}
