import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  HttpParams } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { CurrentDataService } from './current-data.service';

const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class CatUsersService {

  constructor(private http: HttpClient,
    private currentData: CurrentDataService) { }
  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Authorization':  localStorage.getItem('token')
    });
    return headers;
  }

  getHeadersToken(): HttpHeaders {
    const headers = new HttpHeaders({
        'Authorization': 'Bearer '+localStorage.uri
    });
    return headers;
  }
  getData(){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/catsUser/GetCatUser', {headers});
  }

  getDataByGeneralSearch(idStation: any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params  = new HttpParams ({fromObject:{idStation}});
    return this.http.get(apiUrl + '/catsUser/GetUsersByStation',  {headers, params});
  }


  getDataStations(idUser: any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams ({fromObject:{idUser}});
    return this.http.get(apiUrl + '/catsUser/GetRelStationRegion', {headers, params});
  }

   //agregar usuario
   addUser(send : any){
     this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.put(apiUrl + '/catsUser/AddUser', send, {headers:headers});
  }

  //update service
  updateUser(send : any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.put(apiUrl + '/catsUser/UpdateUser', send, {headers:headers});
  }
  //delete service
  deleteService(idUserSend: string, idUser : string ){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params  = new HttpParams ({fromObject:{idUserSend,idUser}});
    return this.http.get(apiUrl + '/catsUser/DeleteUser',  {headers, params});
  }

  getExcel( ){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/catsUser/GetUserExcel',  {headers:headers});
  }

  changePassword(send: any){
   let headers = this.getHeadersToken();
    return this.http.put(apiUrl + '/catsUser/ResetUser', send, {headers:headers});
  }

  changePasswordFirst(send: any){
   let headers = this.getHeaders();
    return this.http.put(apiUrl + '/catsUser/ResetUser', send, {headers:headers});
  }

}
