<mat-toolbar>
  <mat-toolbar-row class="fond_color">
    <div class="div-menu">
      <a class="navbar-brand">
        <img class="imgLogo" [defaultImage]="defaultImage" [lazyLoad]="image">
      </a>
      <span class="menu-spacer"></span>
      <!--<span class="fill-space" *ngIf="isLogued()"></span>-->
      <div class="menu-responsive" style="margin-left: 1%;">
        <!-- Menu Diseño -->
        <div class="dropdown menuPrincipal side-space" [hidden]="!isLogued() || !canDesign" fxShow="true">
          <a class="btn btn-default dropdown-toggle menuPrincipal" role="button" id="dropdownMenuLink"
            data-bs-toggle="dropdown" aria-expanded="false"> {{varDesign}}<span class="caret"></span> </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li style="background-color:#002266 !important;" *ngIf="canCatalogsDesign">
              <a class="dropdown-item submenu" id="multilevelDropdownMenu1" tabindex="-1"
                (click)="stateMenuDesign(flagMenu = 'catalogsMenu')" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                {{varCatalogs}}<i class="fa fa-caret-right" style="margin-left: 2%;"></i>
              </a>
              <ul class="dropdown-menu dropdown-submenu" *ngIf="catalogsMenu === true"
                aria-labelledby="multilevelDropdownMenu1">
                <li *ngIf="canCycles" class="dropdown-item" style="background-color:#0044cc !important;">
                  <a class="dropdown-item" tabindex="-1" [routerLink]="['/catCycles']"
                    class="submenuOptions list-group-item list-group-item-action">
                    {{varCycles}}
                  </a>
                </li>
                <li *ngIf="canServiceCode" class="dropdown-item" style="background-color:#0044cc !important;">
                  <a class="dropdown-item" tabindex="-1" [routerLink]="['/catCodeServs']"
                    class="submenuOptions list-group-item list-group-item-action">
                    {{varServiceCode}}
                  </a>
                </li>
                <li *ngIf="canPotential" class="dropdown-item" style="background-color:#0044cc !important;">
                  <a class="dropdown-item" tabindex="-1" [routerLink]="['/consultPotential']"
                    class="submenuOptions list-group-item list-group-item-action">
                    {{varPotential}}
                  </a>
                </li>
                <li *ngIf="canBoardingTable" class="dropdown-item" style="background-color:#0044cc !important;">
                  <a tabindex="-1" [routerLink]="['/boardingTable']"
                    class="submenuOptions list-group-item list-group-item-action">
                    {{varBoardingTable}}
                  </a>
                </li>
              </ul>
            </li>
            <li class="dropdown-item" style="background-color:#002266 !important;" *ngIf="canGeneralConsultation">
              <a tabindex="-1" [routerLink]="['/generalConsult']"
                class="test submenu list-group-item list-group-item-action">
                {{varGeneralConsultation}}
              </a>
            </li>
            <li class="dropdown-item" style="background-color:#002266 !important;" *ngIf="canFoods">
              <a tabindex="-1" [routerLink]="['/catFoods']" class="test submenu list-group-item list-group-item-action">
                {{varFoods}}
              </a>
            </li>
            <li style="background-color:#002266 !important;" *ngIf="canPreselectMain">
              <a class="dropdown-item submenu" id="multilevelDropdownMenu1" tabindex="-1"
                (click)="stateMenuDesign(flagMenu = 'catalogsMenu')" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                {{varPreselect}}<i class="fa fa-caret-right" style="margin-left: 2%;"></i>
              </a>
              <ul class="dropdown-menu dropdown-submenu" *ngIf="catalogsMenu === true"
                aria-labelledby="multilevelDropdownMenu1">
                <li *ngIf="canPreselectEmail" class="dropdown-item" style="background-color:#0044cc !important;">
                  <a class="dropdown-item" tabindex="-1" [routerLink]="['/catpreselectemails']"
                    class="submenuOptions list-group-item list-group-item-action">
                    {{varPreselectEmails}}
                  </a>
                </li>
                <li *ngIf="canFlyings" class="dropdown-item" style="background-color:#0044cc !important;">
                  <a class="dropdown-item" tabindex="-1" [routerLink]="['/main']"
                    class="submenuOptions list-group-item list-group-item-action">
                    {{varFlyings}}
                  </a>
                </li>
                <li *ngIf="canStattionsFlying" class="dropdown-item" style="background-color:#0044cc !important;">
                  <a class="dropdown-item" tabindex="-1" [routerLink]="['/main']"
                    class="submenuOptions list-group-item list-group-item-action">
                    {{varStationsFlying}}
                  </a>
                </li>
                <li *ngIf="canPreselectCodes" class="dropdown-item" style="background-color:#0044cc !important;">
                  <a tabindex="-1" [routerLink]="['/main']"
                    class="submenuOptions list-group-item list-group-item-action">
                    {{varPreselectionCodes}}
                  </a>
                </li>
                <li *ngIf="canSpecialMealCodes" class="dropdown-item" style="background-color:#0044cc !important;">
                  <a tabindex="-1" [routerLink]="['/main']"
                    class="submenuOptions list-group-item list-group-item-action">
                    {{varSpecialMealCodes}}
                  </a>
                </li>
              </ul>
            </li>
            <li *ngIf="canDigitalMenu" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/digitalMenu']" class="test submenu list-group-item list-group-item-action">
                {{varDigitalMain}}
              </a>
            </li>
          </ul>
        </div>
        <!-- Menu Planeacion -->
        <div class="dropdown menuPrincipal side-space" [hidden]="!canPlanning || !isLogued()" fxShow="true">
          <button class="btn btn-default dropdown-toggle menuPrincipal" type="button" data-bs-toggle="dropdown"
            [disabled]="varMenu === 'leftMenu'">{{varPlanning}}
            <span class="caret"></span></button>
          <ul class="dropdown-menu">
            <li *ngIf="canFleetRelationship" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/fleetList']" class="test submenu list-group-item list-group-item-action"
                style="background-color:#002266 !important;">
                {{varFleetRelationship}}
              </a>
            </li>
            <li *ngIf="canMaster" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/catMaster']" style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varMaster}}
              </a>
            </li>
            <li *ngIf="canMatrix" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/catMatriz']" style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varMatrix}}
              </a>
            </li>
            <li *ngIf="canTSU" class="dropdown-item" style="background-color:#002266 !important;"><a tabindex="-1"
                [routerLink]="['/catTsu']" style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varTSU}}
              </a>
            </li>
            <li *ngIf="canGraphics" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/graphics']" style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varGraphics}}
              </a>
            </li>
            <li *ngIf="canAccommodations" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/ConsultTrolleyMealDist']" style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varAccommodations}}
              </a>
            </li>
            <li *ngIf="canBarList" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/listBar']" style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varBarList}}
              </a>
            </li>
            <li *ngIf="canServiceGuide" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/guideService']" style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varServiceGuide}}
              </a>
            </li>
            <li *ngIf="canServiceGuide" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/guideServiceSisea']" class="dropdown-item"
                style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varServiceGuideSisea}}
              </a>
            </li>
            <li *ngIf="canMaterials" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/catMaterials']" class="dropdown-item"
                style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varMaterials}}
              </a>
            </li>
            <li *ngIf="canManuals" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/catManuals']" class="dropdown-item"
                style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varManuals}}
              </a>
            </li>
            <li *ngIf="canProducts" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/consultProducts']" class="dropdown-item"
                style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varProducts}}
              </a>
            </li>
            <li *ngIf="canEquipment" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/consultEquipment']" class="dropdown-item"
                style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varEquipment}}
              </a>
            </li>
            <li *ngIf="canRestockCap" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/restockCap']" class="dropdown-item"
                style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varRestockCap}}
              </a>
            </li>
          </ul>
        </div>
        <!-- Menu Irregularidades -->
        <div class="dropdown menuPrincipal side-space" [hidden]="!isLogued() || !canIrregularities" fxShow="true">
          <button class="btn btn-default dropdown-toggle menuPrincipal" type="button" data-bs-toggle="dropdown"
            [disabled]="varMenu === 'leftMenu'">{{varIrregularities}}
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu submenu">
            <li *ngIf="canStatistics" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/statistics']" class="dropdown-item"
                style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action">
                {{varStatistics}}
              </a>
            </li>
            <li *ngIf="canReportsIrregularities" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" href="#" style="background-color:#002266 !important;"
                class="test submenu list-group-item list-group-item-action" [routerLink]="['/reportIrregularities']">
                {{varReports}}
              </a>
            </li>
          </ul>
        </div>
        <!-- Menu Comunicados -->
        <div class="dropdown menuPrincipal side-space" [hidden]="!isLogued() || !canNotices" fxShow="true">
          <button class="btn btn-default dropdown-toggle menuPrincipal" type="button" data-bs-toggle="dropdown"
            [disabled]="varMenu === 'leftMenu'">{{varNotices}}
            <span class="caret"></span></button>
          <ul class="dropdown-menu submenu">
            <li *ngIf="canTracing" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/consultNotice']"
                class="test submenu list-group-item list-group-item-action">
                {{varTracing}}
              </a>
            </li>
            <li *ngIf="canShipping" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" [routerLink]="['/sendNotice']"
                class="test submenu list-group-item list-group-item-action">
                {{varShipping}}
              </a>
            </li>
          </ul>
        </div>
        <!-- Menu Directorio -->
        <div class="dropdown menuPrincipal side-space" [hidden]="!isLogued() || !canDirectory" fxShow="true">
          <button class="btn btn-default dropdown-toggle menuPrincipal" type="button" data-bs-toggle="dropdown"
            [disabled]="varMenu === 'leftMenu'">{{varDirectory}}
            <span class="caret"></span></button>
          <ul class="dropdown-menu submenu">
            <li *ngIf="canProvider" class="dropdown-item" style="background-color:#002266 !important;">
              <a tabindex="-1" class="test submenu list-group-item list-group-item-action"
                [routerLink]="['/catProviders']">
                {{varProvider}}
              </a>
            </li>
          </ul>
        </div>
        <!-- Menu Configuracion -->
        <div class="dropdown menuPrincipal side-space" [hidden]="!isLogued() || !canConfiguration" fxShow="true">
          <button class="btn btn-default dropdown-toggle menuPrincipal" type="button" data-bs-toggle="dropdown"
            [disabled]="varMenu === 'leftMenu'" id="dropdownMenuconfig">{{varConfiguration}}
            <span class="caret"></span></button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuconfig">
            <li style="background-color:#002266 !important;" *ngIf="canCatalogsConfiguration">
              <a class="dropdown-item submenu" tabindex="-1" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" id="dropdownSubMenuConfig"
                (click)="stateMenuDesign(flagMenu = 'catalogsMenuRegion')">
                {{varCatalogs}}&nbsp;<i class="fas fa-caret-right"></i>
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu dropdown-submenu" *ngIf="catalogsMenuRegion === true"
                aria-labelledby="dropdownSubMenuConfig">
                <li class="dropdown-item" style="background-color:#0044cc !important;" *ngIf="CanRegions">
                  <a tabindex="-1" class="submenuOptions list-group-item list-group-item-action"
                    [routerLink]="['/catRegions']">{{varRegions}}</a>
                </li>
                <li class="dropdown-item" style="background-color:#0044cc !important;" *ngIf="CanStations">
                  <a tabindex="-1" class="submenuOptions list-group-item list-group-item-action"
                    [routerLink]="['/catStation']">{{varStations}}</a>
                </li>
                <li class="dropdown-item" style="background-color:#0044cc !important;" *ngIf="CanIrregularityCriteria">
                  <a tabindex="-1" class="submenuOptions list-group-item list-group-item-action"
                    [routerLink]="['/irregularityCriteria']">{{varIrregularityCriteria}}</a>
                </li>
              </ul>
            </li>
            <li class="dropdown-item" style="background-color:#002266 !important;" *ngIf="CanUsers">
              <a tabindex="-1" [routerLink]="['/catUsers']" class="test submenu list-group-item list-group-item-action">
                {{varUsers}}<span class="caret"></span>
              </a>
            </li>
            <li class="dropdown-item" style="background-color:#002266 !important;" *ngIf="canProfiles">
              <a tabindex="-1" [routerLink]="['/profile']" class="test submenu list-group-item list-group-item-action">
                {{varProfile}}<span class="caret"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <span class="fill-space"></span>
      <!--Notificaciones-->
      <div class="form-inline my-2 my-lg-0 side-space notifications" [routerLink]="['/consultNotice']"
        *ngIf="isLogued()">
        <button type="button" class="btn-options">
          <mat-icon class="icon" style="position:relative; color:white;">notifications</mat-icon>
          <span class="badge">{{badgeCount}}</span>
        </button>
      </div>
      <span class="fill-space"></span>
      <!--Idioma-->
      <div class="switch float-right divLanguage" style="font-size:13px;  color: white !important; position: relative;">
        <label>
          <span>{{languageGlobal.spanish}}</span>
          <input type="checkbox" [(ngModel)]="isChecked" (change)="checkValue(!isChecked)">
          <span class="lever" style="    margin-left: 5px; margin-right: 5px;"></span>
          <span>{{languageGlobal.english}}</span>
        </label>
      </div>
      <!--Boton de configuracion-->
      <!-- <div class="switch float-right divLanguage" style="position: relative; margin-left: 1%;">
        <button tabindex="-1" align-right type="button" aria-label="Toggle" mat-icon-button *ngIf="isLogued()"
      (click)="openDialog()">
        <mat-icon aria-label="Side nav toggle icon" style="color: white;">
            manage_accounts
        </mat-icon>
      </button>
      </div> -->

      <button tabindex="-1" align-right type="button" aria-label="Toggle sidenav" mat-icon-button (click)="showSide()"
        *ngIf="isLogued()" class="menu-side button-menu">
        <mat-icon aria-label="Side nav toggle icon" style="color: white;"> menu
        </mat-icon>
      </button>
      <!--Boton de login -->
      <div style="color: white !important; margin-left: 1%; font-size: 22px;" (click)="showInfo()" *ngIf="isLogued()"
        class="menu-responsive">
        <i class="fa fa-user" style="cursor: pointer;" aria-hidden="true"></i>
      </div>

    </div>
  </mat-toolbar-row>
</mat-toolbar>

<!--Informacion del usuario cuando la pantalla es mayor a 1300px-->
<div class="card animated bounceInRight fast z-depth-4 card-info" *ngIf="isInfo && isLogued()" style="height: 20rem; width: 24rem; position: absolute; z-index: 1; right: 0px;
border-radius: 0px 0px 0px 200px;">
  <div class="card-block">
    <div class="row flex-column justify-content-center align-items-center">
      <button type="button" class="btn_home btn_home_movil" style=" margin-top: 10%;" [routerLink]="['/main']">
        <i class="fas fa-home"></i> <span style="    font-size: 14px; margin-left: 5px;">{{varStart}}</span>
      </button>
      <div class="dropdown-divider"></div>
      <label class="h1" style="text-align: center;">{{varWelcome}} &nbsp;</label>
      <label class="information" style="text-align: center;">{{name}}</label>
      <label class="information" style="text-align: center;">{{ dateNow | dates: 'navbar' }}</label>
      <label class="information" style="text-align: center;">{{version}}</label>
      <img class="imgRedonda" [defaultImage]="defaultNumerouno" [lazyLoad]="numerouno" alt="num1">
      <button type="button" (click)="logout()" class="btn_home" style=" margin-top: 5%;"><b class="logOut">{{varSignOff}} </b>&nbsp;<i
          class="fas fa-sign-out-alt"></i></button>
    </div>

  </div>
</div>

<div class="container-menu animated bounceInRight fast z-depth-4" *ngIf="isLogued()" id="menuSide"
  style="height: 85%; overflow-y: auto; position: absolute;">
  <mat-nav-list fxShow="true" style="background-color: #0b172f">
    <mat-nav-list>
      <mat-expansion-panel [hidden]="!isLogued() || !canDesign" style="background: #0b172f;"
        [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header (click)="clearMenu()">
          {{varDesign}}
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-expansion-panel *ngIf="canCatalogsDesign" style="background: #0b172f;" [class.mat-elevation-z0]="true">
            <mat-expansion-panel-header (click)="stateMenuDesign(flagMenu = 'catalogsMenu')">
              {{varCatalogs}}
            </mat-expansion-panel-header>
            <mat-nav-list>
              <a *ngIf="canCycles" mat-list-item [routerLink]="['/catCycles']">{{varCycles}}</a>
              <a *ngIf="canServiceCode" mat-list-item [routerLink]="['/catCodeServs']">{{varServiceCode}}</a>
              <a *ngIf="canPotential" mat-list-item [routerLink]="['/consultPotential']">{{varPotential}}</a>
              <a *ngIf="canBoardingTable" mat-list-item [routerLink]="['/boardingTable']">{{varBoardingTable}}</a>
            </mat-nav-list>
          </mat-expansion-panel>
          <a *ngIf="canGeneralConsultation" mat-list-item [routerLink]="['/generalConsult']">
            <span>{{varGeneralConsultation}}</span>
          </a>
          <a *ngIf="canFoods" mat-list-item [routerLink]="['/catFoods']">
            <span>{{varFoods}}</span>
          </a>

          <mat-expansion-panel *ngIf="canPreselectMain" style="background: #0b172f;" [class.mat-elevation-z0]="true">
            <mat-expansion-panel-header (click)="stateMenuDesign(flagMenu = 'catalogsMenu')">
              {{varPreselect}}
            </mat-expansion-panel-header>
            <mat-nav-list>
              <a *ngIf="canPreselectEmail" mat-list-item [routerLink]="['/catpreselectemails']">{{varPreselectEmails}}</a>
              <a *ngIf="canFlyings" mat-list-item [routerLink]="['/main']">{{varFlyings}}</a>
              <a *ngIf="canStattionsFlying" mat-list-item [routerLink]="['/main']">{{varStationsFlying}}</a>
              <a *ngIf="canPreselectCodes" mat-list-item [routerLink]="['/main']">{{varPreselectionCodes}}</a>
              <a *ngIf="canSpecialMealCodes" mat-list-item [routerLink]="['/main']">{{varSpecialMealCodes}}</a>
            </mat-nav-list>
          </mat-expansion-panel>
          <a *ngIf="canFoods" mat-list-item [routerLink]="['/digitalMenu']">
            <span>{{varDigitalMain}}</span>
          </a>

        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel [hidden]="!isLogued() || !canPlanning" style="background: #0b172f;"
        [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
          {{varPlanning}}
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a *ngIf="canFleetRelationship" mat-list-item [routerLink]="['/fleetList']">{{varFleetRelationship}}</a>
          <a *ngIf="canMaster" mat-list-item [routerLink]="['/catMaster']">{{varMaster}}</a>
          <a *ngIf="canMatrix" mat-list-item [routerLink]="['/catMatriz']">{{varMatrix}}</a>
          <a *ngIf="canTSU" mat-list-item [routerLink]="['/catTsu']">{{varTSU}}</a>
          <a *ngIf="canGraphics" mat-list-item [routerLink]="['/graphics']">{{varGraphics}}</a>
          <a *ngIf="canAccommodations" mat-list-item
            [routerLink]="['/ConsultTrolleyMealDist']">{{varAccommodations}}</a>
          <a *ngIf="canBarList" mat-list-item [routerLink]="['/listBar']">{{varBarList}}</a>
          <a *ngIf="canServiceGuide" mat-list-item [routerLink]="['/guideService']">{{varServiceGuide}}</a>
          <a *ngIf="canServiceGuide" mat-list-item [routerLink]="['/guideServiceSisea']">{{varServiceGuideSisea}}</a>
          <a *ngIf="canMaterials" mat-list-item [routerLink]="['/catMaterials']">{{varMaterials}}</a>
          <a *ngIf="canManuals" mat-list-item [routerLink]="['/catManuals']">{{varManuals}}</a>
          <a *ngIf="canProducts" mat-list-item [routerLink]="['/consultProducts']">{{varProducts}}</a>
          <a *ngIf="canEquipment" mat-list-item [routerLink]="['/consultEquipment']">{{varEquipment}}</a>
          <a *ngIf="canRestockCap" mat-list-item [routerLink]="['/restockCap']">{{varRestockCap}}</a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel [hidden]="!isLogued() || !canIrregularities" style="background: #0b172f;"
        [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
          {{varIrregularities}}
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a *ngIf="canStatistics" mat-list-item [routerLink]="['/statistics']">{{varStatistics}}</a>
          <a *ngIf="canReportsIrregularities" mat-list-item [routerLink]="['/reportIrregularities']">{{varReports}}</a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel [hidden]="!isLogued() || !canNotices" style="background: #0b172f;"
        [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
          {{varNotices}}
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a *ngIf="canTracing" mat-list-item [routerLink]="['/consultNotice']">{{varTracing}}</a>
          <a *ngIf="canShipping" mat-list-item [routerLink]="['/sendNotice']">{{varShipping}}</a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel [hidden]="!isLogued() || !canDirectory" style="background: #0b172f;"
        [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
          {{varDirectory}}
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a *ngIf="canProvider" mat-list-item [routerLink]="['/catProviders']">{{varProvider}}</a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel [hidden]="!isLogued() || !canConfiguration" style="background: #0b172f;"
        [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header (click)="clearMenu()">
          {{varConfiguration}}
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-expansion-panel *ngIf="canCatalogsConfiguration" style="background: #0b172f;"
            [class.mat-elevation-z0]="true">
            <mat-expansion-panel-header (click)="stateMenuDesign(flagMenu = 'catalogsMenuRegion')">
              {{varCatalogs}}
            </mat-expansion-panel-header>
            <mat-nav-list *ngIf="catalogsMenuRegion === true ">
              <a *ngIf="CanRegions" mat-list-item [routerLink]="['/catRegions']">{{varRegions}}</a>
              <a mat-list-item [routerLink]="['/catStation']">{{varStations}}</a>
              <a *ngIf="CanIrregularityCriteria" mat-list-item
                [routerLink]="['/irregularityCriteria']">{{varIrregularityCriteria}}</a>
            </mat-nav-list>
          </mat-expansion-panel>
          <a *ngIf="CanUsers" mat-list-item [routerLink]="['/catUsers']">
            <span>{{varUsers}}</span>
          </a>
          <a *ngIf="canProfiles" mat-list-item [routerLink]="['/profile']">
            <span>{{varProfile}}</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-nav-list>
  </mat-nav-list>
  <div style="margin-left: 6%; margin-top: 6%;" fxShow="true" fxHide.gt-lg fxHide.gt-md>
    <div class="menuLateral">
      <button type="button" class="btn_home btn_home_movil" [routerLink]="['/main']">
        <i class="fas fa-home"></i> {{varStart}}
      </button>
      &nbsp;
      <br>
      <hr>
      <label class="h1">{{varWelcome}} &nbsp;</label>
      <label class="information">{{name}}</label> <br>
      <label class="information">{{ dateNow | dates: 'navbar' }}</label><br>
      <label class="information">V2.1.0</label><br>
      <img class="imgRedonda" style="align-content: center" [defaultImage]="defaultNumerouno" [lazyLoad]="numerouno"
        alt="num1">
    </div>
    <button type="button" (click)="logout()" class="btn_home" style=" margin-top: 10%;">
      <b class="logOut">{{varSignOff}} </b>&nbsp;<i class="fas fa-sign-out-alt"></i>
    </button>
  </div>
</div>
<div>
  <router-outlet></router-outlet>
</div>
