import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { LanguageService } from 'src/app/Services/language.service';
import { UntypedFormBuilder } from '@angular/forms';
import { MatrizService } from 'src/app/Services/matriz.service';
import { LoginService } from 'src/app/Services/Login.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-matriz',
  templateUrl: './add-matriz.component.html',
  styleUrls: ['./add-matriz.component.css']
})
export class AddMatrizComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;

  // Variables permisos --------------
  public canCreate;
  public canRead;
  public canUpdate;
  public canDelete;

  public idUser: number;
  //Variables Idioma
  public varName: string
  public varObservations: string
  public varLanguage: string
  public varSessionExpired: string
  public varSuccessful: string
  public varWarning: string
  public varKitCreated: string
  public varTryAgain: string
  public varConnectionError: string
  public varLoading: string
  public varLocalStorage: string
  public varStatus: string
  public varAdd: string
  public varEdit: string
  public varDelete: string
  public varYes: string
  public varClose: string
  public varGlobalFilter: string
  public varSave: string
  public varClass: string
  public varEquipmentFamily: string
  public varProduct: string
  public varQuantityPremier: string
  public varQuantityTourist: string
  public varLot: string
  public varCancel: string
  public quantityTourist: string
  public quantityPremier: string
  public varSend: string
  public varMatrixForm : string
  //Lote
  public loteSelect: any[];
  public lotes: any[];
  public valueLote: string;
  public idSelectedLote: any;
  public idSelectedLoteEnglish: string;
  //equipmentFamily
  public equipmentFamilySelect: any[];
  public equipmentsFamily: any[];
  public valueEquipmentFamily: string;
  public idSelectedEquipmentFamily: any;
  public idSelectedEquipmentFamilyEnglish: string;
  public varMsgLot: string
  public varMsgEquipment: string
  public varMsgProduct: string
  public varMsgQuantityTourist: string
  public varMsgQuantityPremier: string
  public send: any
  //product
  public productSelect: any[];
  public products: any[];
  public valueProduct: string;
  public idSelectedProduct: any;
  public idSelectedProductEnglish: string;
  // Varibales lenguaje
  public language;
  public languageGlobal;
  public languageModule;
  public  personal;

  //Variable FechaVigencia
  dateStart: Date;

  constructor(
    private permissionsService: PermissionsService,
    private toastr: ToastrService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private MatrizService: MatrizService,
    private languagueService: LanguageService,
    private loginService: LoginService) {

      this.language = languagueService.getLanguage();
      this.languageGlobal = this.language.global;
      this.languageModule = this.language.modules.matrix;

      this.subscription.add(this.languagueService.changeLanguage.subscribe(
        isEnglish => {
          this.language = languagueService.getLanguage();
          this.languageGlobal = this.language.global;
          this.languageModule = this.language.modules.matrix;
          this.getVariables();

        }
      ));
    // Permisos
    this.canCreate = permissionsService.CanSubMenu(2, 24, 'C');
    this.canRead = permissionsService.CanSubMenu(2, 24, 'R');
    this.canUpdate = permissionsService.CanSubMenu(2, 24, 'U');
    this.canDelete = permissionsService.CanSubMenu(2, 24, 'D');

  }

  ngOnInit() {
    this.varLocalStorage = localStorage.getItem('language')
    this.valueLanguge();
    //let personal = JSON.parse(localStorage.getItem("personal"));
    let personal = this.loginService.getUser();
    this.idUser = parseInt(personal.idUser.toString())
    this.getVariables()
    this.getCatMaster()
  }

  valueLanguge() {
    if (localStorage.getItem('language') === 'true') {
      this.varLanguage = 'Ingles'
    } else if (localStorage.getItem('language') === 'false') {
      this.varLanguage = 'Español'
    }
  }


  getVariables() {
    this.varSessionExpired = this.languageGlobal.sessionExpired;
    this.varSuccessful = this.languageGlobal.successful;
    this.varWarning = this.languageGlobal.warning;
    this.varKitCreated = this.languageModule.kitCreated;
    this.varTryAgain = this.languageGlobal.tryAgain;
    this.varConnectionError = this.languageGlobal.connectionError;
    this.varLoading = this.languageGlobal.loading;
    this.varStatus = this.languageGlobal.status;
    this.varAdd = this.languageGlobal.add;
    this.varDelete = this.languageGlobal.delete;
    this.varEdit = this.languageGlobal.edit;
    this.varYes = this.languageGlobal.yes;
    this.varClose = this.languageGlobal.close;
    this.varGlobalFilter = this.languageGlobal.globalFilter;
    this.varSave = this.languageGlobal.save;
    this.varEquipmentFamily = this.languageModule.equipmentFamily;
    this.varProduct = this.languageModule.product;
    this.varQuantityTourist = this.languageModule.touristQuantity;
    this.varQuantityPremier = this.languageModule.premierQuantity;
    this.varLot = this.languageModule.lote;
    this.varCancel = this.languageGlobal.cancel;
    this.varMsgLot = this.languageModule.msgselectLot;
    this.varMsgEquipment = this.languageModule.msgEquipmentSelect;
    this.varMsgProduct = this.languageModule.msgProduct;
    this.varMsgQuantityTourist = this.languageModule.msgQuantityTourist;
    this.varMsgQuantityPremier = this.languageModule.msgQuantityPremier;
    this.varSend = this.languageGlobal.send;
    this.varMatrixForm = this.languageModule.matrixForm;
  }

  getCatMaster() {
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.MatrizService.getData().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();
            //Select Lote
            this.loteSelect = []
            this.lotes = data.lote;
            let lengthCatSupplyng = this.lotes.length
            for (let i = 0; i < lengthCatSupplyng; i++) {
              this.loteSelect.push({ name: this.lotes[i].description, code: this.lotes[i].id })
            }
            //Select Equipment
            this.equipmentFamilySelect = []
            this.equipmentsFamily = data.equipment;
            let catSupplyngLength = this.equipmentsFamily.length
            for (let i = 0; i < catSupplyngLength; i++) {
              this.equipmentFamilySelect.push({ name: this.equipmentsFamily[i].description, code: this.equipmentsFamily[i].id })
            }
            //Select Product
            this.productSelect = []
            this.products = data.product;
            let supplyngLength = this.products.length
            for (let i = 0; i < supplyngLength; i++) {
              this.productSelect.push({ name: this.products[i].description, code: this.products[i].id })
            }
          }

        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));

  }
  NumQuantityPremier(e: any) {
    let input = e.target.value;
    var out = '';
    var filtro = '1234567890';
    for (var i = 0; i < input.length; i++)
      if (filtro.indexOf(input.charAt(i)) != -1)
        out += input.charAt(i);
    this.quantityPremier = out.toLocaleUpperCase()
    return out;
  }

  NumQuantityTourist(e: any) {
    let input = e.target.value;
    var out = '';
    var filtro = '1234567890';
    for (var i = 0; i < input.length; i++)
      if (filtro.indexOf(input.charAt(i)) != -1)
        out += input.charAt(i);
    this.quantityTourist = out.toLocaleUpperCase()
    return out;
  }

  sendData() {

    this.blockUI.start(this.varLoading);
    if (this.idSelectedLote != null) {
      if (this.idSelectedEquipmentFamily != null) {
        if (this.idSelectedProduct != null) {
          if (this.quantityTourist != undefined) {
            if (this.quantityPremier != undefined) {
              this.send = {
                lote: this.idSelectedLote.name,
                equipmentFamily: this.idSelectedEquipmentFamily.code,
                product: this.idSelectedProduct.code,
                quantityTourist: this.quantityTourist,
                quantityPremier: this.quantityPremier,
                idUser: this.idUser,
                EffectiveDate: this.dateStart
              }
              this.subscription.add(this.MatrizService.add(this.send).subscribe((data: any) => {
                this.blockUI.stop();
                if (data.ClassInfo.Status === 200) {
                  this.router.navigate(['/catMatriz'], { queryParams: {message: this.idSelectedLote.name, type: 'add'}});
                }
                this.blockUI.stop();
              }, error => {
                this.blockUI.stop();
                if (error.status === 401 || error.status === 0) {
                  this.blockUI.stop();
                  this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
                  localStorage.setItem('logued', 'false');
                  this.router.navigate(['/login']);
                } else if (error.status === 404) {
                  this.blockUI.stop();
                  this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
                } else if (error.status === 500 || error.status === 400) {
                  this.blockUI.stop();
                  this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
                }
              }));

            } else {
              this.blockUI.stop();
              this.toastr.warning(this.varMsgQuantityPremier, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
            }
          } else {
            this.blockUI.stop();
            this.toastr.warning(this.varMsgQuantityTourist, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        } else {
          this.blockUI.stop();
          this.toastr.warning(this.varMsgProduct, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
        }

      } else {
        this.blockUI.stop();
        this.toastr.warning(this.varMsgEquipment, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    } else {
      this.blockUI.stop();
      this.toastr.warning(this.varMsgLot, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
