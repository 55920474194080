import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RestockCapRoutingModule } from "./restock-cap-routing.module";
import { RestockCapComponent } from "./restock-cap.component";

import { LazyLoadImageModule } from 'ng-lazyload-image'; // <-- import it
import { BlockUIModule } from 'ng-block-ui';
import {TableModule} from 'primeng/table';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { PipesModule } from 'src/app/Pipes/pipes.module';


@NgModule({
    declarations: [RestockCapComponent],
    imports: [
        CommonModule,
        RestockCapRoutingModule,
        LazyLoadImageModule,
        BlockUIModule.forRoot(),
        TableModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule
    ]
})
export class RestockModule { }