 <app-navbar-logued>

</app-navbar-logued>   


 <!-- <div *ngIf="isLogued()" >
         <app-navbar-logued></app-navbar-logued>  
</div>

<div *ngIf="!isLogued()">
        <app-navbar></app-navbar>   
</div>   -->