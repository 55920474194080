<block-ui>
  <div class="container-fluid">
    <div class="row">
      <section style="background:url('../../assets/images/aeromexico-frontPage.jpg');" class="titleCat titleMaster">
        <h1 class="titleComponent">{{varAddKit}}</h1>
      </section>
    </div>
    <div class="containerMaster ui-g col-sm-12 col-md-12 col-12">
      <!-- Segunda seccion de actualizacion -->
      <!-- Listado de Materiales -->

      <p-splitter [style]="{'height': '100%','border-style': 'none', 'width': '100%'}" [panelSizes]="[30,70]">
        <ng-template pTemplate>
          <div>
            <p-table [columns]="cols" [value]="availableMaterials" dataKey="tableImagen" #dtt [scrollable]="true"
              scrollHeight="650px">
              <ng-template pTemplate="caption">
                <div style="text-align: left;">
                  <input class="containerFiltro " type="text" pInputText size="50" placeholder={{varGlobalFilter}}
                    (input)="filterMaterials($event.target.value)" style="width:auto" (onChange)="ngOnInit()">
                </div>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th class="headertable">{{varMaterials}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-tableMaterial let-columns="columns">
                <tr [pSelectableRow]="contId" data-b-toggle="modal" data-b-target="#AddSuply">
                  <td class="ui-helper-clearfix" pDraggable="materials" (onDragStart)="dragStart($event,tableMaterial)"
                    (onDragEnd)="dragEnd($event)">

                    <div style="text-align: center; font-weight: bold;">{{tableMaterial.partNumber}} </div>
                    <div class="h-divider">
                      <div class="shadow"></div>
                    </div>

                    <div style="text-align: center">{{tableMaterial.shortDescription}} </div>
                    <div style="text-align: center; margin-bottom: 20px;">
                      {{tableMaterial.familydescription}}
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </ng-template>
        <ng-template pTemplate>
          <div class="divTableKit" pDroppable="materials" (onDrop)="drop($event)"
            [ngClass]="{'ui-highlight-material':draggedMaterial}">
            <p-table class="tableMaster" [columns]="cols" [value]="arrayTable" [(selection)]="selectedCar1"
              dataKey="partNumber">
              <ng-template pTemplate="header" let-columns>
                <tr style="text-align: center;">
                  <th style="width: 3em" class="headertable">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th class="headertable">No. </th>
                  <th class="headertable">{{varQuantity}}</th>
                  <th class="headertable">{{languageModule.reusable}}</th>
                  <th class="headertable">{{languageModule.commentary}}</th>
                  <th class="headertable">{{varPartNumber}}</th>
                  <th class="headertable">{{varShortDescription}}</th>
                  <th class="headertable">{{varDescriptionEnglish}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-contId let-columns="columns">
                <tr pDraggable="test" (onDragStart)="dragStartKit($event,contId)" (onDragEnd)="dragEndKit($event)"
                  [pSelectableRow]="contId" style="text-align: center;">
                  <td>
                    <p-tableCheckbox [value]="contId" (click)="idKit(contId);"></p-tableCheckbox>
                  </td>
                  <td>{{contId.id}} </td>
                  <td pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="contId.quantity">
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{contId.quantity}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td style="text-align: center!important;">
                    <input (click)="check()" type="checkbox" [(ngModel)]="contId.reusable" />
                  </td>
                  <td pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="contId.commentary">
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{contId.commentary}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>{{contId.partNumber}}</td>
                  <td>{{contId.shortDescription}}</td>
                  <td>{{contId.descriptionEnglish}}</td>
                </tr>

              </ng-template>
              <ng-template pTemplate="summary">
                <div style="text-align: right;">
                  <button type="button" class="btn-options styleButtonTable" (click)="deleteMaterial ();">
                    <i class="fa fa-trash-alt iconDelete"></i>&nbsp; {{varDelete}}
                  </button>
                </div>
                <div style="text-align: right; margin-top: 15px;">
                  <button type="button" class="btn-options styleButtonTable" (click)="sendCompleteKit(arrayTable);">
                    <i class="fa fa-save"></i>&nbsp; {{varSave}}
                  </button>
                </div>
              </ng-template>
            </p-table>

            <div class="ui-g col-sm-12 col-md-12 col-12" style="margin-top: 20px;" *ngIf="dataMaster">


              <p-splitter [style]="{'height': '100%','border-style': 'none', 'width': '100%', 'border-left': '10px'}"
                [panelSizes]="[50,50]" gutterSize="0">
                <ng-template pTemplate>
                  <div class="col-sm-6 col-md-6 col-6" style="text-align: initial; width: 100%;">
                    <label class="fontSizeLabelModal"><b>&nbsp; {{varLot}}:</b> {{dataMaster.lote}} </label>
                    <br>
                    <label class="fontSizeLabelModal"><b>&nbsp; {{varDescription}}:</b> {{dataMaster.description}}
                    </label>
                    <br>
                    <label class="fontSizeLabelModal"><b>&nbsp; {{varDescriptionEnglish}}:</b>
                      {{dataMaster.englishDescription}}
                    </label>
                    <br>
                    <label class="fontSizeLabelModal"><b>&nbsp; {{varContainer}}:</b> {{dataMaster.container}} </label>
                    <br>
                    <label class="fontSizeLabelModal"><b>&nbsp; UM:</b> {{dataMaster.idUM}} </label>
                    <br>

                  </div>
                </ng-template>
                <ng-template pTemplate>
                  <div class="col-sm-6 col-md-6 col-6" style="text-align: initial; width: 100%;">
                    <label class="fontSizeLabelModal"><b>&nbsp; {{varLocalStation}}:</b> {{dataMaster.idLocalStations}}
                    </label>
                    <br>
                    <label class="fontSizeLabelModal"><b>&nbsp; {{varInternationalStation}}:</b>
                      {{dataMaster.idInternationalStaions}} </label>
                    <br>
                    <label class="fontSizeLabelModal"><b>&nbsp; {{languageModule.cycle}}:</b> {{dataMaster.cycle}}
                    </label>
                    <br>
                    <label class="fontSizeLabelModal"><b>&nbsp; {{languageModule.commentary}}:</b>
                      {{dataMaster.commentary}}
                    </label>
                    <br>
                    <label class="fontSizeLabelModal"><b>&nbsp; {{varPicture}}:</b> {{dataMaster.nameFile}} </label>
                    <br>

                  </div>
                </ng-template>
              </p-splitter>
            </div>
          </div>
        </ng-template>
      </p-splitter>
    </div>
  </div>
</block-ui>
