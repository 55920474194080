import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ProfileService } from '../../Services/profile.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from 'primeng/api';
import { Location } from '@angular/common';
import { PermissionsService } from 'src/app/Services/permissions.service';
import Swal from 'sweetalert2';
import { CurrentDataService } from 'src/app/Services/current-data.service';
import { LoginService } from 'src/app/Services/Login.service';
import { LanguageService } from 'src/app/Services/language.service';
import { TableModule } from 'primeng/table';


declare var $;

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})

export class UpdateProfileComponent implements OnInit {


  @BlockUI() blockUI: NgBlockUI;
  // @share
  @Output() sendReult = new EventEmitter();
  /*Menu */
  public menus: any;
  public selectedRules: TreeNode[];
  public allMenuT: TreeNode[];
  public selectCheck: boolean;
  public idUSer: number;
  /*variables */
  public varProfiles: string;
  public varStatus: string;
  public varLoading: string;
  public varSessionExpired: string;
  public varSuccessful: string;
  public varWarning: string;
  public varTryAgain: string;
  public varConnectionError: string;
  public allMenu: string;
  public varlabelAll: string;
  public varCreate: string;
  public varREADING: string;
  public varEDIT: string;
  public varDELETE: string;
  public instruccionAddProfile: string;
  public varGlobalFilter: string;
  public varMenu: string;
  public varSumbernu: string;
  public option: string;
  public add: string;
  public permitionProfile: string;
  public namePRofileAlert: string;
  public varLocalStorage: string;
  public varSave: string;
  public cancel: string;
  public cols2: any;

  public canCreate;
  public canRead;
  public canUpdate;
  public canDelete;

  /* Select Permitions */
  public permitionSelect: any[];
  public Selectpermition: any[];
  public permition: string;
  public nameProfileVar: string;
  /*agregar menu */
  public nameProfile: string;
  public menuSelect: any[] = [];


  /*Table */
  public tableInsert: any[];
  public cols: any[];
  public sendProfiles: any[];
  public profiles: any[];

  selectedPermission: string[] = [];
  idSelected: any;
  isTotal: boolean;
  personal: any;
  public varmsgDeleteProfile: string
  public varmsgDeletePermiso: string

  // varLanguage
  public language;
  public languageGlobal;
  public languageModule;

  constructor(private service: ProfileService, private languagueService: LanguageService,
              private toastr: ToastrService, private router: Router, private location: Location,
              permissionsService: PermissionsService,
              private loginService: LoginService) {
    this.language = languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.updateProfile;


    this.languagueService.changeLanguage.subscribe(
      English => {
        this.language = languagueService.getLanguage();
        this.languageGlobal = this.language.global;
        this.languageModule = this.language.modules.updateProfile;
        this.getVariables();
        this.get();
        this.madePermitions();
        this.madeDetailTable();
        this.profileUpdate();
        setTimeout(() => {
          this.disbledCheched();
        }, 1000);
        setTimeout(() => {
          this.unDisableTotal();
        }, 1100);
      }
    )

    this.blockUI.start(this.varLoading);
    setTimeout(() => {
      this.blockUI.stop();
    }, 500);
    this.canCreate = permissionsService.CanSubMenu(6, 28, 'C');
    this.canRead = permissionsService.CanSubMenu(6, 28, 'R');
    this.canUpdate = permissionsService.CanSubMenu(6, 28, 'U');
    this.canDelete = permissionsService.CanSubMenu(6, 28, 'D');
    this.personal = this.loginService.getUser();
    this.idUSer = this.personal.idUser;
  }
  // @share
  @Input() update;
  ngOnInit() {
    this.tableInsert = [];
    this.get();
    this.getVariables();
    this.madePermitions();
    this.nameProfile = this.update.nameProfile;
    this.madeDetailTable();

    this.location.subscribe(x => {
      history.go(1);
    });
    setTimeout(() => {
      this.disbledCheched();
    }, 1000);
    setTimeout(() => {
      this.unDisableTotal();
    }, 1100);
  }
  profileUpdate() {
    const a = this.service.sendUpdate();
    this.update = a;
  }
  disbledCheched() {
    $.each($('input:checkbox'), function (i, l) {
      if ($(l).attr('id') !== undefined &&
          $(l).attr('id') !== 'C' &&
          $(l).attr('id') !== 'R' &&
          $(l).attr('id') !== 'U' &&
          $(l).attr('id') !== 'D') {
        if ($(l).is(':checked')) {
          $(l).prop('disabled', true);
        }
      }
    });
  }
  getVariables() {
    this.varLoading = this.languageGlobal.loading;
    this.varSessionExpired = this.languageGlobal.sessionExpired;
    this.varSuccessful = this.languageGlobal.successful;
    this.varWarning = this.languageGlobal.warning;
    this.varTryAgain = this.languageGlobal.tryAgain;
    this.varConnectionError = this.languageGlobal.connectionError;
    this.allMenu = this.languageModule.allMenu;
    this.varEDIT = this.languageGlobal.edit;
    this.varCreate = this.languageModule.create;
    this.varREADING = this.languageModule.consult;
    this.varDELETE = this.languageGlobal.delete;
    this.nameProfileVar = this.languageModule.nameProfile;
    this.instruccionAddProfile = this.languageModule.instruccionAddProfile;
    this.varGlobalFilter = this.languageGlobal.globalFilter;
    this.varMenu = this.languageGlobal.menu;
    this.varSumbernu = this.languageGlobal.submenu;
    this.option = this.languageModule.option;
    this.add = this.languageGlobal.add;
    this.permitionProfile = this.languageModule.permitionProfile;
    this.namePRofileAlert = this.languageModule.namePRofileAlert;
    this.varSave = this.languageGlobal.save;
    this.cancel = this.languageGlobal.cancel;
    this.varProfiles = this.languageModule.profile;
    this.varStatus = this.languageGlobal.status;
    this.varmsgDeleteProfile = this.languageModule.msgDeleteProfile;
    this.varmsgDeletePermiso = this.languageModule.msgDeletePermiso;


  }
  get() {
    this.blockUI.start(this.varLoading);
    this.service.getDataMenu().subscribe((data: any) => {
      const status = data.ClassInfo.Status;
      if (status === 200) {
        this.blockUI.stop();
        this.madeMenu(data.menus);
      }
    }, error => {
      this.blockUI.stop();
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    });
  }
  madeMenu(menus: any) {
    const menuLength = menus.length;
    this.menus = [];
    this.nameProfile = this.update.nameProfile;

    if (localStorage.getItem('language') === 'true') {
      for (let i = 0; i < menuLength; i++) {
        const sumbenus = []
        const sumbenusLength = menus[i].submenus.length;
        const submenu = menus[i].submenus;

        for (let j = 0; j < sumbenusLength; j++) {
          let menuOptionLength = submenu[j].menuOptions.length
          let menuOptions = []
          let menuOption = submenu[j].menuOptions
          for (let k = 0; k < menuOptionLength; k++) {
            let valSubmenOption = "o" + menuOption[k].idSubMenuOptions
            let check = this.optionCheck(valSubmenOption)
            menuOptions.push({ description: menuOption[k].descriptionEnglish, val: valSubmenOption, check: check })
          }
          let sumMenuVal = "s" + submenu[j].idSubMenu
          let check = this.subMenuCheck(sumMenuVal)
          sumbenus.push({ description: submenu[j].descriptionEnglish, val: sumMenuVal, menuOptions: menuOptions, check: check });
        }
        let valueMenu = "m" + menus[i].idMenu
        let check = this.menuCheck(valueMenu)
        this.menus.push({ description: menus[i].descriptionEnglish, val: valueMenu, submenus: sumbenus, check: check })
      }
    } else if (localStorage.getItem('language') === 'false') {
      for (let i = 0; i < menuLength; i++) {
        let sumbenus = []
        let sumbenusLength = menus[i].submenus.length
        let submenu = menus[i].submenus

        for (let j = 0; j < sumbenusLength; j++) {
          let menuOptionLength = submenu[j].menuOptions.length
          let menuOptions = []
          let menuOption = submenu[j].menuOptions
          for (let k = 0; k < menuOptionLength; k++) {
            let valSubmenOption = "o" + menuOption[k].idSubMenuOptions
            let check = this.optionCheck(valSubmenOption)
            menuOptions.push({ description: menuOption[k].description, val: valSubmenOption, check: check })
          }
          let sumMenuVal = "s" + submenu[j].idSubMenu
          let check = this.subMenuCheck(sumMenuVal)
          sumbenus.push({ description: submenu[j].description, val: sumMenuVal, menuOptions: menuOptions, check: check });
        }
        let valueMenu = "m" + menus[i].idMenu
        let checkMenu = this.menuCheck(valueMenu)
        this.menus.push({ description: menus[i].description, val: valueMenu, submenus: sumbenus, checkMenu: checkMenu })

      }
    }


  }
  menuCheck(id: string): boolean {
    let response = false
    let lengthMenusProfile = this.update.menu.length
    for (let i = 0; i < lengthMenusProfile; i++) {
      let profiles = this.update.menu[i];
      let menu = profiles.idMenu
      if (id === menu) {
        response = true;
      }
    }
    return response
  }
  subMenuCheck(id: string): boolean {
    let response = false
    let lengthMenusProfile = this.update.menu.length
    for (let i = 0; i < lengthMenusProfile; i++) {
      let profiles = this.update.menu[i];
      let submenu = profiles.idSubmenu
      if (id === submenu) {
        response = true;
      }
    }
    return response
  }
  optionCheck(id: string): boolean {
    let response = false
    let lengthMenusProfile = this.update.menu.length
    for (let i = 0; i < lengthMenusProfile; i++) {
      let profiles = this.update.menu[i];
      let option = profiles.idOptionMenu
      if (id === option) {
        response = true;
      }
    }
    return response
  }
  validateInput(data: any) {
    let input = data.target.value;
    var out = '';
    //Se añaden caracteres validos
    let filtro = 'abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ ';
    for (var i = 0; i < input.length; i++) {
      if (filtro.indexOf(input.charAt(i)) != -1)
        out += input.charAt(i);
      this.nameProfile = out.toUpperCase();

    }
    return out.toUpperCase();
  }

  selectMenu(select: any) {
    this.menuSelect = [];
    let idSelect = select.target.id
    this.idSelected = idSelect;
    let checkSelect = "#" + select.target.id
    let letterM = idSelect.substring(0, 1)
    let selectChect = false;
    if (letterM == "o") {
      if ($(checkSelect).prop('checked')) {
        selectChect = true;
        $(checkSelect).prop("checked", true);
        this.selectOption(idSelect);
      } else {
        selectChect = false;
        $(checkSelect).prop("checked", false);
        this.selectOption(idSelect);
      }
    }

    if (letterM == "s") {
      if ($(checkSelect).prop('checked')) {
        selectChect = true;
        this.selectSubmenu(idSelect, true)
      }
      else {
        selectChect = false;
        this.selectSubmenu(idSelect, false)
      }
    }
    if (letterM == "m") {

      if ($(checkSelect).prop('checked')) {
        selectChect = true;
        this.selectMenuArea(idSelect, true)
      }
      else {
        selectChect = false;
        this.selectMenuArea(idSelect, false)
      }
    }
    if (letterM == "t") {
      if ($(checkSelect).prop('checked')) {
        selectChect = true;
        this.selectAll(true)
      }
      else {
        selectChect = false;
        this.selectAll(false)
      }
    }

    if (selectChect == true) {
      $('#saveMenu').modal('show');
      $("body").removeAttr("style");
    }
    this.deleteRepeat()
    if (selectChect == false) {
      this.selectCheck = false
      this.menuSelect = []
      this.madeTable(true);
    } else {
      this.selectCheck = true;
    }
  }
  selectMenuArea(idSelect: string, checkS: Boolean) {
    let menuLength = this.menus.length
    for (let i = 0; i < menuLength; i++) {
      let sumbenusLength = 0
      sumbenusLength = this.menus[i].submenus.length
      let submenu = this.menus[i].submenus

      for (let j = 0; j < sumbenusLength; j++) {
        let menuOptionLength = submenu[j].menuOptions.length
        let menuOption = submenu[j].menuOptions
        if (menuOptionLength > 0) {
          for (let k = 0; k < menuOptionLength; k++) {
            let selectsMenuOptions = menuOption[k].val
            if (idSelect == this.menus[i].val) {
              $('#' + selectsMenuOptions).prop("checked", checkS);
              this.menuSelect.push({ menu: this.menus[i].description, idMenu: this.menus[i].val, subMenu: submenu[j].description, idSubMenu: submenu[j].val, option: menuOption[k].description, idOption: menuOption[k].val })
            }
          }
        } else {
          if (idSelect == this.menus[i].val) {
            this.menuSelect.push({ menu: this.menus[i].description, idMenu: this.menus[i].val, subMenu: submenu[j].description, idSubMenu: submenu[j].val, option: null, idOption: null })
          }
        }
        let selectsSubMenu = submenu[j].val
        if (idSelect == this.menus[i].val) {
          $('#' + selectsSubMenu).prop("checked", checkS);
        }

      }
      let selectsMenu = this.menus[i].val
      if (idSelect == selectsMenu) {
        $('#' + selectsMenu).prop("checked", checkS);
      }
    }
  }

  selectOption(idSelect: string) {
    let menuLength = this.menus.length
    for (let i = 0; i < menuLength; i++) {
      let sumbenusLength = 0
      sumbenusLength = this.menus[i].submenus.length
      let submenu = this.menus[i].submenus
      for (let j = 0; j < sumbenusLength; j++) {
        let menuOptionLength = submenu[j].menuOptions.length
        let menuOption = submenu[j].menuOptions
        for (let k = 0; k < menuOptionLength; k++) {
          let selectsMenuOptions = menuOption[k].val
          if (idSelect == selectsMenuOptions) {
            this.menuSelect.push({ menu: this.menus[i].description, idMenu: this.menus[i].val, subMenu: submenu[j].description, idSubMenu: submenu[j].val, option: menuOption[k].description, idOption: menuOption[k].val })
          }
        }
      }
    }
  }
  selectSubmenu(idSelect: string, checkS: Boolean) {
    let menuLength = this.menus.length
    for (let i = 0; i < menuLength; i++) {
      let sumbenusLength = 0
      sumbenusLength = this.menus[i].submenus.length
      let submenu = this.menus[i].submenus
      for (let j = 0; j < sumbenusLength; j++) {
        let menuOptionLength = submenu[j].menuOptions.length
        let menuOption = []
        menuOption = submenu[j].menuOptions
        if (menuOptionLength > 0) {
          for (let k = 0; k < menuOptionLength; k++) {
            if (submenu[j].val == idSelect) {
              let selectsMenuOptions = menuOption[k].val
              $('#' + selectsMenuOptions).prop("checked", checkS);
              this.menuSelect.push({ menu: this.menus[i].description, idMenu: this.menus[i].val, subMenu: submenu[j].description, idSubMenu: submenu[j].val, option: menuOption[k].description, idOption: menuOption[k].val })
            }
          }
        } else {
          if (submenu[j].val == idSelect) {
            this.menuSelect.push({ menu: this.menus[i].description, idMenu: this.menus[i].val, subMenu: submenu[j].description, idSubMenu: submenu[j].val, option: null, idOption: null })
          }
        }
        let selectsSubMenu = submenu[j].val
        if (submenu[j].val == idSelect) {
          $('#' + selectsSubMenu).prop("checked", checkS);
        }
      }
      let selectsMenu = this.menus[i].val
    }
  }
  selectAll(checkS: Boolean) {
    let menuLength = this.menus.length
    for (let i = 0; i < menuLength; i++) {
      let sumbenusLength = 0
      sumbenusLength = this.menus[i].submenus.length
      let submenu = this.menus[i].submenus

      for (let j = 0; j < sumbenusLength; j++) {
        let menuOptionLength = submenu[j].menuOptions.length
        let menuOption = submenu[j].menuOptions
        if (menuOptionLength > 0) {
          for (let k = 0; k < menuOptionLength; k++) {
            let selectsMenuOptions = menuOption[k].val
            $('#' + selectsMenuOptions).prop("checked", checkS);
            this.menuSelect.push({ menu: this.menus[i].description, idMenu: this.menus[i].val, subMenu: submenu[j].description, idSubMenu: submenu[j].val, option: menuOption[k].description, idOption: menuOption[k].val })
          }
        } else {
          this.menuSelect.push({ menu: this.menus[i].description, idMenu: this.menus[i].val, subMenu: submenu[j].description, idSubMenu: submenu[j].val, option: null, idOption: null })
        }
        let selectsSubMenu = submenu[j].val
        $('#' + selectsSubMenu).prop("checked", checkS);
      }
      let selectsMenu = this.menus[i].val
      $('#' + selectsMenu).prop("checked", checkS);
    }
  }
  deleteRepeat() {
    let lengthTable = this.tableInsert.length
    let lengthMenuPreselect = this.menuSelect.length;
    for (let i = 0; i < lengthMenuPreselect; i++) {
      for (let j = 0; j < lengthTable; j++) {
        if (this.tableInsert[j].menuId == this.menuSelect[i].idMenu && this.tableInsert[j].subMenuId == this.menuSelect[i].idSubMenu && this.tableInsert[j].idOption == this.menuSelect[i].idOption) {
          let index = this.tableInsert.indexOf(this.tableInsert[j]);
          this.tableInsert.splice(index, 1);
          lengthTable = lengthTable - 1;
        }
      }
    }
  }
  madeTable(addAlert: boolean) {

    let dataPermition = []
    let read = null;
    let update = null;
    let add = null;
    let deletes = null;
    if ($('#C').prop('checked')) {
      add = "a";
      dataPermition.push("C")
    }
    if ($('#D').prop('checked')) {
      deletes = "d";
      dataPermition.push("D")
    }
    if ($('#R').prop('checked')) {
      read = "r";
      dataPermition.push("R")
    }
    if ($('#U').prop('checked')) {
      update = "u";
      dataPermition.push("U")
    }
    let lengthPermition = dataPermition.length
    if (lengthPermition > 0 || this.selectCheck == false) {
      $('#saveMenu').modal('hide');
      $("body").change(function () {
        $(this).val('style');
      });
      this.clearOptionsModal()
      let responsePermition = 0
      let permition = "";

      for (let i = 0; i < lengthPermition; i++) {
        let permitionAux = dataPermition[i]
        permition = permition + permitionAux.toString()
      }
      responsePermition = this.casePermit(permition)
      let lengthMenuPreselect = this.menuSelect.length;
      // sendProfiles

      for (let i = 0; i < lengthMenuPreselect; i++) {
        this.deleteOptionMenu(this.menuSelect[i]);
        this.tableInsert.unshift({
          menu: this.menuSelect[i].menu,
          menuId: this.menuSelect[i].idMenu,
          submenu: this.menuSelect[i].subMenu,
          subMenuId: this.menuSelect[i].idSubMenu,
          menuOption: this.menuSelect[i].option,
          idOption: this.menuSelect[i].idOption,
          read: read,
          add: add,
          update: update,
          deletes: deletes,
          responsePermition: responsePermition
        })
      }
      this.cols = [
        { field: 'menu', header: this.varMenu },
        { field: 'submenu', header: this.varSumbernu },
        { field: 'menuOption', header: this.option },
        { field: 'read', header: this.varREADING },
        { field: 'add', header: this.add },
        { field: 'update', header: this.varEDIT },
        { field: 'deletes', header: this.varDELETE }
      ];
    } else {
      if (addAlert) {
        this.toastr.warning(this.permitionProfile, 'Warning:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }
    this.disbledCheched();

  }
  deleteOptionMenu(menuOption: any) {
  }

  clearOptionsModal() {
    $('#C').prop('checked', false)
    $('#D').prop('checked', false)
    $('#R').prop('checked', false)
    $('#U').prop('checked', false)
  }
  getPermitionTable(idPermition: number): string {
    let responsePermition = ""
    switch (idPermition) {
      case 1:
        responsePermition = "CDRU"
        break;
      case 2:
        responsePermition = "C"
        break;
      case 3:
        responsePermition = "R"
        break;
      case 4:
        responsePermition = "U"
        break;
      case 5:
        responsePermition = "D"
        break;
      case 6:
        responsePermition = "CR"
        break;
      case 7:
        responsePermition = "CU"
        break;
      case 8:
        responsePermition = "CD"
        break;
      case 9:
        responsePermition = "RU"
        break;
      case 10:
        responsePermition = "DR"
        break;
      case 11:
        responsePermition = "DU"
        break;
      case 12:
        responsePermition = "CRU"
        break;
      case 13:
        responsePermition = "CDR"
        break;
      case 14:
        responsePermition = "DRU"
        break;
    }
    return responsePermition;
  }
  casePermit(permition): number {

    let responsePermition = 0
    switch (permition) {
      case "CDRU":
        responsePermition = 1
        break;
      case "C":
        responsePermition = 2
        break;
      case "R":
        responsePermition = 3
        break;
      case "U":
        responsePermition = 4
        break;
      case "D":
        responsePermition = 5
        break;
      case "CR":
        responsePermition = 6
        break;
      case "CU":
        responsePermition = 7
        break;
      case "CD":
        responsePermition = 8
        break;
      case "RU":
        responsePermition = 9
        break;
      case "DR":
        responsePermition = 10
        break;
      case "DU":
        responsePermition = 11
        break;
      case "CRU":
        responsePermition = 12
        break;
      case "CDR":
        responsePermition = 13
        break;
      case "DRU":
        responsePermition = 14
        break;
    }

    return responsePermition;
  }
  madePermitions() {
    this.permitionSelect = [];
    this.permitionSelect.push({ label: this.varCreate, value: 'C' })
    this.permitionSelect.push({ label: this.varREADING, value: 'R' })
    this.permitionSelect.push({ label: this.varEDIT, value: 'U' })
    this.permitionSelect.push({ label: this.varDELETE, value: 'D' })
  }

  closeModalSave() {
    this.Selectpermition = []
    this.permitionSelect = []
    this.menuSelect = []
    this.madePermitions()
    $('#C').prop("checked", false);
    $('#R').prop("checked", false);
    $('#U').prop("checked", false);
    $('#D').prop("checked", false);
    $('#saveMenu').modal('hide');
    $("body").change(function () {
      $(this).val('style');
    });
  }

  cancelProfile() {
    this.clearAdd()
    this.sendReult.emit(false)
  }
  clearAdd() {
    this.selectAll(false)
    this.nameProfile = "";
    this.Selectpermition = []
    this.permitionSelect = []
    this.menuSelect = []
    this.tableInsert = []
    this.madePermitions()
    $('#C').prop("checked", false);
    $('#R').prop("checked", false);
    $('#U').prop("checked", false);
    $('#D').prop("checked", false);
    this.madeTable(false)
  }
  updateProfile() {
    if (this.nameProfile != null) {
      let lengthMenu = this.tableInsert.length;
      let insert = [];
      for (let i = 0; i < lengthMenu; i++) {
        let lengthMenu = this.tableInsert[i].menuId.length
        let lengthSubMenu = this.tableInsert[i].subMenuId.length
        let idOption = null
        if (this.tableInsert[i].idOption != null) {
          let lengthOption = this.tableInsert[i].idOption.length
          idOption = this.tableInsert[i].idOption.substring(1, lengthOption)
        } else {
          idOption = this.tableInsert[i].idOption
        }


        let idMEnu = this.tableInsert[i].menuId.substring(1, lengthMenu)
        let idSubMenu = this.tableInsert[i].subMenuId.substring(1, lengthSubMenu)

        let permition = this.setPermition(this.tableInsert[i]);
        if (idOption === "0") {
          idOption = null;
        }

        insert.push({ idMenu: idMEnu, idSubmenu: idSubMenu, idMenuOption: idOption, idTypePermition: permition })

      }

      let send =
      {
        idUser: this.idUSer,
        nameProfile: this.nameProfile,
        profiles: insert,
        idProfile: this.update.idProfile
      }
      this.blockUI.start(this.varLoading);
      this.service.addProfile(send).subscribe((data: any) => {
        this.sendReult.emit(false)
        let status = 0;
        status = data.ClassInfo.Status
        if (status == 200) {
          this.blockUI.stop();
          if (data.AddProfile.isInsert) {
            if (localStorage.getItem('language') === 'true') {
              this.toastr.success(data.AddProfile.errorMessagesEn, this.varSuccessful, { positionClass: 'toast-bottom-full-width', closeButton: true });

            } else if (localStorage.getItem('language') === 'false') {
              this.toastr.success(data.AddProfile.errorMessagesEs, this.varSuccessful, { positionClass: 'toast-bottom-full-width', closeButton: true });
            }
          } else {
            if (localStorage.getItem('language') === 'true') {
              this.toastr.success(data.AddProfile.errorMessagesEn, this.varSuccessful, { positionClass: 'toast-bottom-full-width', closeButton: true });
            } else if (localStorage.getItem('language') === 'false') {
              this.toastr.success(data.AddProfile.errorMessagesEs, this.varSuccessful, { positionClass: 'toast-bottom-full-width', closeButton: true });
            }
          }
        }
      }, error => {
        this.blockUI.stop();
        if (error.status === 401 || error.status === 0) {
          this.blockUI.stop();
          this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          localStorage.setItem('logued', 'false');
          this.router.navigate(['/login']);
        } else if (error.status === 404) {
          this.blockUI.stop();
          this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        } else if (error.status === 500 || error.status === 400) {
          this.blockUI.stop();
          this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        }

      });

      this.clearAdd()
    } else {
      this.toastr.warning(this.namePRofileAlert, 'Warning:', { positionClass: 'toast-bottom-full-width', closeButton: true });
    }
  }
  setPermition(profile) {

    let permition = "";
    let permiso;
    if (profile.add === "1" || profile.add === "a") {
      permition += "C";
    }
    if (profile.deletes === "1" || profile.deletes === "d") {
      permition += "D";
    }
    if (profile.read === "1" || profile.read === "r") {
      permition += "R";
    }
    if (profile.update === "1" || profile.update === "u") {
      permition += "U";
    }

    permiso = this.casePermit(permition);
    return permiso;
  }
  madeDetailTable() {
    this.tableInsert = []
    setTimeout(() => {
      this.update.menu.forEach(m => {
        let permition = this.getPermitionTable(m.permissions);
        let read = ""

        if (permition.indexOf("R") >= 0) {
          read = "1"
        }
        let add = ""
        if (permition.indexOf("C") >= 0) {
          add = "1"
        }
        let update = ""
        if (permition.indexOf("U") >= 0) {
          update = "1"
        }
        let deletes = ""
        if (permition.indexOf("D") >= 0) {
          deletes = "1"
        }
        this.tableInsert.push({
          menu: localStorage.getItem('language') === 'true' ? m.MenuEn : m.MenuEs,
          menuId: m.idMenu,
          submenu: localStorage.getItem('language') === 'true' ? m.submenuEn : m.submenuEs,
          subMenuId: m.idSubmenu,
          menuOption: localStorage.getItem('language') === 'true' ? m.descriptionEn : m.descriptionEs,
          idOption: m.idOptionMenu,
          read: read,
          add: add,
          update: update,
          deletes: deletes,
          responsePermition: m.permissions
        })
      })
    }, 500);

    this.cols = [
      { field: 'menu', header: this.varMenu.toUpperCase() },
      { field: 'submenu', header: this.varSumbernu },
      { field: 'menuOption', header: this.option },
      { field: 'read', header: this.varREADING.toUpperCase() },
      { field: 'add', header: this.add.toUpperCase() },
      { field: 'update', header: this.varEDIT.toUpperCase() },
      { field: 'deletes', header: this.varDELETE.toUpperCase() }
    ];
  }

  onChecked(profile, option) {
    let idMenu = profile.menuId;
    let idSubmenu = profile.subMenuId;
    let idOption = profile.idOption;
    let index;
    let profileTemp;

    if (idMenu && idSubmenu && idOption) {
      index = this.tableInsert.findIndex(p => p.menuId === idMenu && p.subMenuId === idSubmenu && p.idOption === idOption);
      profileTemp = this.tableInsert.find(p => p.menuId === idMenu && p.subMenuId === idSubmenu && p.idOption === idOption);
    } else if (idMenu && idSubmenu) {
      index = this.tableInsert.findIndex(p => p.menuId === idMenu && p.subMenuId === idSubmenu);
      profileTemp = this.tableInsert.find(p => p.menuId === idMenu && p.subMenuId === idSubmenu);
    }

    if ((index || index === 0) && profileTemp) {

      if (option === 'read') {
        profileTemp.read = (profileTemp.read === null || profileTemp.read === "") ? '1' : null
      } else if (option === 'add') {
        profileTemp.add = (profileTemp.add === null || profileTemp.add === "") ? '1' : null
      } else if (option === 'update') {
        profileTemp.update = (profileTemp.update === null || profileTemp.update === "") ? '1' : null
      } else if (option === 'deletes') {
        profileTemp.deletes = (profileTemp.deletes === null || profileTemp.deletes === "") ? '1' : null
      }
      if ((profileTemp.read === "" || profileTemp.read === null) &&
        (profileTemp.add === "" || profileTemp.add === null) &&
        (profileTemp.update === "" || profileTemp.update === null) &&
        (profileTemp.deletes === "" || profileTemp.deletes === null)) {
        this.tableInsert.splice(index, 1);
        this.unDisabledOptionMenu(profileTemp);

      } else {
        this.tableInsert.splice(index, 1, profileTemp);
      }
    }
  }

  deleteModal(data: any) {
    Swal.fire({
      title: (localStorage.getItem('language') === 'true') ? "Do you want to delete the profile?" : "¿Desea eliminar el perfil?",
      text: (localStorage.getItem('language') === 'true') ? "If you delete the profile, all users associated with the profile will be deactivated" : "Si elimina el perfil, todos los usuarios asociados al perfil quedaran desactvados",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.cancel,
      confirmButtonText: this.varDELETE,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.deleteOptionTable(data);

      } else {

      }
    });
  }
  deleteOptionTable(data: any) {
    let index;
    if (data.menuOption !== null || data.idOption !== null) {
      index = this.tableInsert.findIndex(p => (p.menuOption === data.menuOption || p.idOption === data.idOption))
    } else if (data.submenu !== null || data.subMenuId !== null) {
      index = this.tableInsert.findIndex(p => (p.submenu === data.submenu || p.subMenuId === data.subMenuId))
    }

    if (index !== undefined) {
      this.tableInsert.splice(index, 1);
      this.unDisabledOptionMenu(data);
    }

  }

  unDisabledOptionMenu(profile) {
    this.menus;
    let id;
    if (profile.menuOption !== null) {
      id = profile.idOption;
    } else if (profile.submenu !== null) {
      id = profile.subMenuId;
    }

    $(`#${id}`).prop('disabled', false);
    $(`#${id}`).prop('checked', false);
    this.toastr.success(this.varmsgDeleteProfile, this.varSuccessful, { positionClass: 'toast-bottom-full-width', closeButton: true });

  }

  unDisableTotal() {
    let contador = 0;
    $.each($("input:checkbox"), function (i, l) {
      if ($(l).attr("id") !== undefined && $(l).attr("id") !== 'C' && $(l).attr("id") !== 'R' && $(l).attr("id") !== 'U' && $(l).attr("id") !== 'D') {
        if ($(l).is(":checked")) {
          contador++;
        }
      }
    });

    this.isTotal = (contador === 33) ? false : true;
  }
}
