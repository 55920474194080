import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { MasterService } from 'src/app/Services/master.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MaterialsService } from 'src/app/Services/materials.service';
import { LoginService } from 'src/app/Services/Login.service';
import { LanguageService } from 'src/app/Services/language.service';
import { Subscription } from 'rxjs';


declare var $;

@Component({
  selector: 'app-cat-master',
  templateUrl: './cat-master.component.html',
  styleUrls: ['./cat-master.component.css'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})

export class CatMasterComponent implements OnInit, OnDestroy {
  materialsSave: any;
  private subscription: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;
  // Variables permisos --------------
  public canCreate;
  public canRead;
  public canUpdate;
  public canDelete;
  //----------------------------------

  public idUser: number;

  //Variables Idioma
  public varLanguage: string
  public varSessionExpired: string
  public varSuccessful: string
  public varWarning: string
  public varSentNotice: string
  public varTryAgain: string
  public varConnectionError: string
  public varLoading: string
  public varLocalStorage: string
  public varStatus: string
  public varAdd: string
  public varEdit: string
  public varDelete: string
  public varYes: string
  public varClose: string
  public varGlobalFilter: string
  public varSave: string
  public varClass: string
  public varTitleMaster: string
  public varDeletedKit: string
  public varLot: string
  public varDescription: string
  public varContainer: string
  public varLocalStation: string
  public varInternationalStation: string
  public varPicture: string
  public varEffectiveDate: string;
  public varIdMaterials: string
  public varDescriptionEnglish: string
  public varShortDescription: string
  public varSupplyng: string
  public varQuantity: any
  public varPartNumber: string
  public varEditedKit: string
  public repeatPartNumberEdit: string

  public availableMaterials = [];
  public image: any
  public cols: any[];
  public lote: string

  expandedRows: {} = {};
  public allData: any;
  public idMaster: string
  public arrayKit: Kit[]

  public varCancel: string
  public varAddMaterials: string
  public varAddKit: string
  public varMsgPicture: string
  public varMsgLot: string
  public varMsgDescription: string
  public varMsgContainer: string
  public varMsgUm: string
  public varMsgLocalStation: string
  public varMsgInternationalStation: string
  public varKitEdit: string
  //UM
  public umSelect: any[];
  public ums: any[];
  public valueUm: string;
  public idSelectedUm: string;
  public idSelectedUmEnglish: string;
  //LocalStation
  public localStationSelect: any[];
  public localStations: any[];
  public valueLocalStation: string;
  public idSelectedLocalStation: string;
  public idSelectedLocalStationEnglish: string;
  //InternationalStation
  public internationalStationSelect: any[];
  public internationalStations: any[];
  public cycles: any[];
  //public valueUMSelect : string
  public idSelectedInternationalStation: string;
  public idSelectedInternationalStationEnglish: string;
  file: File;
  public varNoFileSelectedEdit: string
  public lot: string
  public description: string
  public descriptionEnglish: string
  public container: string
  public base64: any;
  public dataSend
  public send: any
  public gralArray: any
  public master: boolean
  //Edit
  public lotEdit: string
  public picture: string
  public IDMaster: string
  public descriptionEdit: string
  public descriptionEnglishEdit: string
  public containerEdit: string
  public base64Edit: any;
  public idSelectedUmEdit: string;
  public idSelectedInternationalStationEdit: string
  public idSelectedCycleEdit: string
  public commentaryEdit: string
  public idSelectedLocalStationEdit: string
  public valueSelectedInternationalStation: string
  public valueSelectedLocalStation: string
  public valueUM: string
  public umSelectEdit: any[];
  public localStationSelectEdit: any[];
  public internationalStationSelectEdit: any[];
  public cyclesSelectEdit: any[];
  public AddSupply: boolean = false
  padre = 'padre';
  public EditFormulario: boolean = false
  public EditMaster: boolean = false
  public varMaster: string
  public supplyingSelect: any[];
  public supplyings: any[];
  public valueSupplying: string;
  public idSelectedSupplying: number;
  public valueSuply: number
  public expanded: any;
  //TablaEditMaster
  public varMaterialsEdit: string
  public availableMaterialsEdit = [];
  public selectedMaterialsEdit: MaterialEdit[];
  public draggedMaterialEdit: MaterialEdit;
  public filteredBrandsEdit: any[];
  public varSupplyingEdit: string
  public MaterialsInfoEdit: any[]
  public AddMaterialEdit: any[]
  public editKit: any[]
  //Select supplying
  public supplyingSelectEdit: any[];
  public supplyingsEdit: any[];
  public valueSupplyingEdit: string;
  public idSelectedSupplyingEdit: number;
  public supplyingEdit: any[]
  public datasetEdit: any[]
  public imageEdit: any
  public varQuantityEdit: string
  public varPartNumberEdit: string
  public varShortDescriptionEdit: string
  public varDescriptionEnglishEdit: string
  public varSupplyTypeEdit: string
  public varFamilyDescriptionEdit: string
  public filterArrayEdit: any[];
  public selectedCar1Edit: ArrayTableEdit[] = [];
  public materialCardEdit: ArrayTableEdit[] = [];
  public valueIdEdit;
  public arrayTableEdit: any[]
  public quantityEdit: number = 1
  public ocultarEdit: boolean = true;
  public colsEdit: any[];
  public kitTableEdit: any;
  public arrayPartNumberTableEdit: any[]
  public arrayDataDropEdit
  public varLotEdit: string
  public varDescriptionEdit: string
  public varContainerEdit: string
  public varLocalStationEdit: string
  public varInternationalStationEdit: string
  public varPictureEdit: string
  public dataMasterEdit
  public varAddKitEdit: string
  public idSelectedSupplyingEditModal: number;
  public supplyingEditModal: any[]
  public idStatus: number;
  public idLoteDelete: string
  public varQuestionDeleteKit: string
  public varExport: string
  public dataDelete: any
  public varSelectSupply: string
  boardingTableExcel: string;
  excelHref: any;
  pdfSrc;
  public tablaKit: boolean = false
  public b64MAster: any
  public contIdEdit: any;
  public varUploadFile: any;
  public numberSuply: number
  public valueSuplyString: string
  public arrayTableSend: any[]
  public valuePicture: boolean = true
  public pictureMaterial: string;
  public response
  public commentaryedit: string
  public reusableEdit: string

  defaultImage = '../../assets/images/compress/aeromexico-frontPage.jpg';
  image2 = '../../assets/images/aeromexico-frontPage.jpg';
  //varLanguage
  public language;
  public languageGlobal;
  public languageModule;

  public selectRow: any
  public selectRowId: any
  public arrayAdd: any
  public arrayAdding: any

  public partNumberAdd: any
  public shortDescriptionAdd: any
  public descriptionEnglishAdd: any
  public imegeAdd: any
  public idCatMaterialsAdd: any
  public partNumberAdding: any
  public shortDescriptionAdding: any
  public descriptionEnglishAdding: any
  public imegeAdding: any
  public idCatMaterialsAdding: any
  public flagArrayTable: string
  public actualTable: any[]
  public idMaterials: string
  nameFile: any;
  varComentary: any;
  varReusable: any;
  public effectiveDate: Date;
  /**
   * Atributos para mostrar la imagen del Kit
   */
  public showViewPhoto = false;
  public imageKit = '';
  public loteImg = '';


  constructor(private toastr: ToastrService,
    private router: Router, private languagueService: LanguageService,
    private permissionsService: PermissionsService, private MasterService: MasterService,
    private materialService: MaterialsService,
    private loginService: LoginService) {

    this.language = languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.master;
    this.subscription.add(this.languagueService.changeLanguage.subscribe(
      isEnglish => {
        this.language = languagueService.getLanguage();
        this.languageGlobal = this.language.global;
        this.languageModule = this.language.modules.master;
        this.getVariables();
        this.generateColumns();
      }
    ));
    this.canCreate = permissionsService.CanSubMenu(2, 11, 'C');
    this.canRead = permissionsService.CanSubMenu(2, 11, 'R');
    this.canUpdate = permissionsService.CanSubMenu(2, 11, 'U');
    this.canDelete = permissionsService.CanSubMenu(2, 11, 'D');
  }

  public valorSelectSupplyng: any;
  ngOnInit() {
    // this.effectiveDate = new Date();
    this.varLocalStorage = localStorage.getItem('language')
    this.valueLanguge();
    let personal = this.loginService.getUser();
    this.idUser = parseInt(personal.idUser.toString())
    this.getVariables();
    this.generateColumns();
    this.getDataMaster();
    this.getCatalog();
    this.getCycles();
  }

  generateColumns() {
    this.cols = [
      { field: 'lote', header: this.varLot },
      { field: 'UM', header: 'UM' },
      { field: 'description', header: this.varDescription },
      { field: 'descriptionEnglish', header: this.varDescriptionEnglish },
      { field: 'container', header: this.varContainer },
      { field: 'localStation', header: this.varLocalStation },
      { field: 'internationalStation', header: this.varInternationalStation },
      { field: 'cycle', header: this.languageModule.cycle },
      { field: 'commentary', header: this.languageModule.commentary },
      { field: 'dateModified', header: this.languageModule.dateModified }
    ];
  }

  valueLanguge() {
    if (localStorage.getItem('language') === 'true') {
      this.varLanguage = 'Ingles'
    } else if (localStorage.getItem('language') === 'false') {
      this.varLanguage = 'Español'
    }
  }

  clearEdit() {
    $('#EditModal').modal('hide');
  }

  closeDeleteModal() {
    $('#deleteModal').modal('hide');
  }

  getVariables() {
    this.varSessionExpired = this.languageGlobal.sessionExpired;
    this.varSuccessful = this.languageGlobal.successful;
    this.varWarning = this.languageGlobal.warning;
    this.varTryAgain = this.languageGlobal.tryAgain;
    this.varConnectionError = this.languageGlobal.connectionError;
    this.repeatPartNumberEdit = this.languageModule.repeatPartNumberEdit;
    this.varLoading = this.languageGlobal.loading;
    this.varStatus = this.languageGlobal.status;
    this.varAdd = this.languageGlobal.add;
    this.varDelete = this.languageGlobal.delete;
    this.varEdit = this.languageGlobal.edit;
    this.varYes = this.languageGlobal.yes;
    this.varClose = this.languageGlobal.close;
    this.varGlobalFilter = this.languageGlobal.globalFilter;
    this.varSave = this.languageGlobal.save;
    this.varTitleMaster = this.languageModule.master;
    this.varLot = this.languageModule.lote;
    this.varDescription = this.languageModule.description;
    this.varContainer = this.languageModule.container;
    this.varLocalStation = this.languageModule.localStation;
    this.varInternationalStation = this.languageModule.internationalStation;
    this.varPicture = this.languageGlobal.picture;
    this.varEffectiveDate = this.languageGlobal.effectiveDate;
    this.varDescriptionEnglish = this.languageGlobal.descriptionEnglish;
    this.varIdMaterials = this.languageModule.idMaterials;
    this.varQuantity = this.languageGlobal.quantity;
    this.varShortDescription = this.languageGlobal.shortDescription;
    this.varSupplyng = this.languageModule.supplyType
    this.varPartNumber = this.languageModule.partNumber;
    this.varCancel = this.languageGlobal.cancel;
    this.varAddMaterials = this.languageModule.addMaterials;
    this.varAddKit = this.languageModule.addKit;
    this.varMsgLot = this.languageModule.msgLot;
    this.varMsgDescription = this.languageModule.msgDescription;
    this.varMsgContainer = this.languageModule.msgContainer;
    this.varMsgUm = this.languageModule.msgUm;
    this.varMsgLocalStation = this.languageModule.msgLocalStation;
    this.varMsgInternationalStation = this.languageModule.msgInternationalStation;
    this.varMsgPicture = this.languageModule.msgPicture;
    this.varKitEdit = this.languageModule.kitEdit;
    this.varMaster = this.languageModule.master;
    this.varSupplyingEdit = this.languageModule.supplying;
    this.varMaterialsEdit = this.languageModule.materials;
    this.varQuantity = this.languageGlobal.quantity;
    this.varPartNumber = this.languageModule.partNumber;
    this.varShortDescription = this.languageGlobal.shortDescription;
    this.varDescriptionEnglish = this.languageGlobal.descriptionEnglish;
    this.varLot = this.languageModule.lote;
    this.varDescription = this.languageModule.description;
    this.varContainer = this.languageModule.container;
    this.varLocalStation = this.languageModule.localStation;
    this.varInternationalStation = this.languageModule.internationalStation;
    this.varPicture = this.languageGlobal.picture;
    this.varAddKit = this.languageModule.addKit;
    this.varEditedKit = this.languageModule.kitEdit;
    this.varDeletedKit = this.languageModule.kitDeleted;
    this.varQuestionDeleteKit = this.languageModule.questionDeleteKit;
    this.varExport = this.languageGlobal.export;
    this.varSelectSupply = this.languageModule.selectSupply;
    this.varComentary = this.languageModule.commentary;
    this.varReusable = this.languageModule.reusable;

  }

  getDataMaster() {
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.MasterService.getData().
      subscribe(
        (data: any) => {
          this.blockUI.stop();
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();
            this.allData = data
            this.availableMaterials = []

            let materialsLength = data.Master.length
            for (let i = 0; i < materialsLength; i++) {
              let node =
              {
                idMaster: data.Master[i].idCatMaster,
                lote: data.Master[i].lote,
                description: data.Master[i].description,
                descriptionEnglish: data.Master[i].descriptionEnglish,
                container: data.Master[i].container,
                UM: data.Master[i].UM,
                localStations: data.Master[i].localStations,
                internationalStaions: data.Master[i].internationalStaions,
                cycle: data.Master[i].cycle,
                commentary: data.Master[i].commentary,
                dateModified: data.Master[i].inicioVigencia,
                picture: data.Master[i].picture,
                nameFile: data.Master[i].nameFile,
                esFechaActualizada: data.Master[i].esFechaActualizada
              }
              this.availableMaterials.push(node);
            }
          }
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ))
  }

  deleteModal(data: any) {
    this.arrayTableEdit = []
    this.idLoteDelete = data.lote
    this.dataDelete = data
    $('#deleteModal').modal('show');
    $("body").removeAttr("style");
    this.idMaster = data.idMaster
    // this.blockUI.start(this.varLoading);
    this.MasterService.getKit(this.idMaster).
      subscribe(
        (data: any) => {
          this.blockUI.stop();
          if (data.classinfo.Status === 200) {
            this.arrayKit = data.materialsByKit
            let arrayKitLength = this.arrayKit.length
            for (let i = 0; i < arrayKitLength; i++) {
              this.arrayTableEdit.push({
                idCatMaterials: data.materialsByKit[i].idCatMaterials,
                quantity: data.materialsByKit[i].quantity
              })
            }
            this.arrayKit = this.arrayTableEdit
          }
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      );
  }
  updateService(type: number) {
    this.gralArray = []
    // this.blockUI.start();
    let statusKitMaster = this.idStatus
    if (type == 2) {
      statusKitMaster = 3
    }

    this.picture = this.dataDelete.picture
    let splitPicture = this.picture.split('/')
    let nameFile = splitPicture[5]


    let testLocalDelete = this.localStations.find(s => s.supplyng === this.dataDelete.localStations);
    let valueLocalDelete = testLocalDelete.idSupplyng

    let testInternationalDelete = this.localStations.find(s => s.supplyng === this.dataDelete.internationalStaions);
    let valueInternationalDelete = testInternationalDelete.idSupplyng

    let testUmDelete = this.ums.find(s => s.code === this.dataDelete.UM);
    let valueUmDelete = testUmDelete.idMeasurementUnit

    let send = {
      idCatMaster: this.dataDelete.idMaster,
      lote: this.dataDelete.lote,
      description: this.dataDelete.description,
      descriptionEnglish: this.dataDelete.descriptionEnglish,
      container: this.dataDelete.container,
      idUM: valueUmDelete,
      idLocalStations: valueLocalDelete,
      idInternationalStaions: valueInternationalDelete,
      idCycle: this.idSelectedCycleEdit,
      commentary: this.commentaryEdit,
      picture: null,
      nameFile: nameFile,
      status: 3,
      materials: this.arrayKit
    }
    this.gralArray.push(send)
    this.gralArray.push(this.arrayKit)
    this.MasterService.UpdateKit(send).subscribe((data: any) => {
      this.blockUI.stop();
      let status = 0;
      status = data.Status
      if (status == 200) {
        if (type == 2) {
          this.toastr.success(this.varDeletedKit, this.varSuccessful, { positionClass: 'toast-bottom-full-width', closeButton: true });
        }

        this.getDataMaster()
      }
    }, error => {
      this.blockUI.stop();
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    });
  }
  updateStatus(idStatus: any) {
    this.idStatus = idStatus
  }
  AddModal() {
    $('#addModal').modal('show');
    $("body").removeAttr("style");

  }
  getCatalog() {
    this.subscription.add(this.MasterService.getCatMaster().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();

            //Select UM
            this.umSelectEdit = []
            this.ums = data.catMeasurementUnits;
            let umLength = this.ums.length
            for (let i = 0; i < umLength; i++) {
              this.umSelectEdit.push({ label: this.ums[i].code, value: this.ums[i].idMeasurementUnit })
            }
            //Select internationalStation
            this.internationalStationSelectEdit = []
            this.supplyingEdit = []
            this.internationalStations = data.CatSupplyng;
            let interLength = this.internationalStations.length
            for (let i = 0; i < interLength; i++) {
              this.internationalStationSelectEdit.push({ label: this.internationalStations[i].supplyng, value: this.internationalStations[i].idSupplyng })
            }


            this.cyclesSelectEdit = []
            this.cycles = this.response.cycles;
            let cycleLength = this.cycles.length
            for (let i = 0; i < cycleLength; i++) {
              this.cyclesSelectEdit.push({ label: this.cycles[i].id, value: this.cycles[i].value })
            }

            //LocalStation
            this.localStationSelectEdit = []
            this.localStations = data.CatSupplyng;
            let localLength = this.localStations.length
            for (let i = 0; i < localLength; i++) {
              this.localStationSelectEdit.push({ label: this.localStations[i].supplyng, value: this.localStations[i].idSupplyng })
            }

          }

        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }

  getCycles() {
    this.response = {
      "cycles": [
        {
          "id": "1",
          "value": "1"
        },
        {
          "id": "2",
          "value": "2"
        },
        {
          "id": "3",
          "value": "3"
        },
        {
          "id": "4",
          "value": "4"
        },
        {
          "id": "U",
          "value": "U"
        },
        {
          "id": "NA",
          "value": "NA"
        },
      ]
    }
  }
  EditModal(data: any) {
    this.idMaster = data.idMaster
    this.blockUI.start(this.varLoading);
    this.kitGet()
    $('#EditModal').modal('show');
    $("body").removeAttr("style");
    this.tablaKit = false
    this.EditFormulario = true;
    this.IDMaster = data.idMaster;
    this.lotEdit = data.lote;
    this.nameFile = data.nameFile;
    this.descriptionEdit = data.description;
    this.descriptionEnglishEdit = data.descriptionEnglish;
    this.idSelectedUmEdit = data.UM;
    this.effectiveDate = new Date(data.dateModified);
    this.idSelectedLocalStationEdit = data.localStations;
    this.idSelectedInternationalStationEdit = data.internationalStaions;
    //this.idSelectedCycleEdit = data.cycle
    if (data.cycle) {
      let lastCaracterCycleEdit = data.cycle[data.cycle.length - 1];
      let arraySelectCycleEdit = data.cycle.length >= 2 && (lastCaracterCycleEdit === ',') ? data.cycle.slice(0, -1) : data.cycle;
      this.idSelectedCycleEdit = arraySelectCycleEdit.split(',')
    }

    this.commentaryEdit = data.commentary
    this.containerEdit = data.container
    this.picture = data.picture
    let splitPicture = this.picture.split('/')
    this.varNoFileSelectedEdit = this.nameFile;
    const dataUpdate = data
    this.getCatalog()

  }
  closeModalEdit() {
    this.getDataMaster()
    $('#EditModal').modal('hide');
    $("body").change(function () {
      $(this).val('style');
    });
  }

  closeModalAdd() {
    this.getDataMaster()
    $('#addModal').modal('hide');
    $("body").change(function () {
      $(this).val('style');
    });
  }


  kitGet() {
    this.arrayKit = []
    this.arrayTableEdit = []
    this.subscription.add(this.MasterService.getKit(this.idMaster).
      subscribe(
        (data: any) => {
          if (data.classinfo.Status === 200) {
            this.blockUI.stop();
            this.arrayKit = data.materialsByKit
          }
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }
  getDataKit(rowData) {
    this.idMaster = rowData.idMaster
    this.blockUI.start(this.varLoading);
    this.kitGet()
  }

  abrirEdit(id) {
    var file = document.getElementById(id);
    file.dispatchEvent(new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    }));
  }

  incomingfileEdit(event: any) {
    this.file = event.target.files[0];
    this.varNoFileSelectedEdit = this.file.name
  }

  changeMaster() {
    this.tablaKit = true
    if (this.file === undefined || this.file === null) {
      this.valuePicture = false
      this.file = null

      let language: number;
      if (this.varLocalStorage === 'true') {
        language = 2
      } else {
        language = 1
      }
      this.send = {
        idMaster: this.IDMaster,
        lote: this.lotEdit,
        description: this.descriptionEdit,
        descriptionEnglish: this.descriptionEnglishEdit,
        container: this.containerEdit,
        idUM: this.idSelectedUmEdit,
        idLocalStations: this.idSelectedLocalStationEdit,
        idInternationalStaions: this.idSelectedInternationalStationEdit,
        idCycle: this.idSelectedCycleEdit,
        commentary: this.commentaryEdit,
        picture: null,
        nameFile: null,
        language: language,
        idUser: this.idUser
      }
      this.llenadoDeTablaEdit()
      this.master = true;
      this.dataMasterEdit = this.send;
    } else {
      this.valuePicture = true
      let fileReader = new FileReader();
      let ext = null
      if (this.file.name != null) {
        let name = this.file.name;
        var arrayExt = name.split('.');
        ext = arrayExt[1]
      }

      fileReader.readAsDataURL(this.file);
      fileReader.onload = (e) => {
        this.base64 = fileReader.result as string;
        let language: number;
        if (this.varLocalStorage === 'true') {
          language = 2
        } else {
          language = 1
        }
        this.send = {
          idMaster: this.IDMaster,
          lote: this.lotEdit,
          description: this.descriptionEdit,
          descriptionEnglish: this.descriptionEnglishEdit,
          container: this.containerEdit,
          idUM: this.idSelectedUmEdit,
          idLocalStations: this.idSelectedLocalStationEdit,
          idInternationalStaions: this.idSelectedInternationalStationEdit,
          idCycle: this.idSelectedCycleEdit,
          commentary: this.commentaryEdit,
          picture: this.base64,
          nameFile: this.file.name,
          language: language,
          idUser: this.idUser
        }
        this.llenadoDeTablaEdit()
        this.master = true;
        this.dataMasterEdit = this.send;
      }
    }

    this.tablaKit = true
    this.getDataMaterialsEdit();
    this.selectedMaterialsEdit = [];
    this.tableAvaliableMaterialsEdit()
    this.getSupplyingEdit()
    $('#editKit').modal('show');
    $("body").removeAttr("style");

  }

  closeModal(id) {
    $(id).modal('hide')
  }

  openModal(id) {
    $(id).modal('show')
  }
  getDataMaterialsEdit() {
    this.blockUI.start(this.varLoading);
    var cont = 0;
    this.availableMaterialsEdit = []
    this.subscription.add(this.materialService.getData().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();
            this.imageEdit = []
            let materialIdAvailable
            let materialIdKit
            let materialsLength = data.materials.length
            for (let i = 0; i < materialsLength; i++) {
              let imagenEdit = data.materials[i].imege;
              this.imageEdit.push(data.materials[i].imege)
              this.availableMaterialsEdit = data.materials
              this.materialsSave = data.materials
            }



          }
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }

  tableAvaliableMaterialsEdit() {
    this.getDataMaterialsEdit()
  }

  llenadoDeTablaEdit() {
    this.blockUI.stop();
    var cont = 0;

    this.arrayTableEdit = []
    this.arrayKit = []
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.MasterService.getKit(this.idMaster).
      subscribe(
        (data: any) => {
          if (data.classinfo.Status === 200) {
            this.blockUI.stop();
            this.arrayKit = data.materialsByKit
            let arrayKitLength = this.arrayKit.length
            let arrayTableEdit2 = []
            for (let i = 0; i < arrayKitLength; i++) {
              cont++

              this.arrayTableEdit.push({
                id: cont,
                quantity: data.materialsByKit[i].quantity,
                partNumber: data.materialsByKit[i].partNumber,
                shortDescription: data.materialsByKit[i].shortDescription,
                descriptionEnglish: data.materialsByKit[i].descriptionEnglish,
                imege: data.materialsByKit[i].imege,
                idCatMaterials: data.materialsByKit[i].idCatMaterials,
                commentaryedit: data.materialsByKit[i].comentary,
                reusable: data.materialsByKit[i].reuilizable,
              })
            }
            this.deleteRepeatEditData()
          }

        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));

  }
  deleteRepeatEditData() {
    let arrayTableEdit2 = []
    let len = this.arrayTableEdit.length
    for (let i = 0; i < len; i++) {

      for (let k = 0; k < arrayTableEdit2.length; k++) {

        if (arrayTableEdit2[k] == this.arrayTableEdit[i].partNumber) {
          let partNumber = this.arrayTableEdit[i].partNumber
          let pos = this.arrayTableEdit.indexOf(partNumber)
          this.arrayTableEdit.splice(i, 1)
          len - 1;
        }
      }
      arrayTableEdit2.push(this.arrayTableEdit[i].partNumber)
    }
  }
  drop(algo: any) {
    this.AddMaterialEdit = []
    var cont = 0;
    this.quantityEdit = 1
    if (this.draggedMaterialEdit) {
      let draggedMaterialIndexEdit = this.findIndex(this.draggedMaterialEdit);
      this.selectedMaterialsEdit = [...this.selectedMaterialsEdit, this.draggedMaterialEdit];
      this.availableMaterialsEdit = this.availableMaterialsEdit.filter((val, i) => i != draggedMaterialIndexEdit);
      let contador = this.selectedMaterialsEdit.length
      let contArray = this.arrayTableEdit.length
      cont = contArray
      this.kitTableEdit = []
      // for (let i = 0; i < contador; i++) {
      //   cont++;
      let i = contador - 1;
      let j = this.arrayTableEdit[contArray - 1].id + 1;
      this.arrayTableEdit.push({
        id: j,
        quantity: this.quantityEdit,
        partNumber: this.selectedMaterialsEdit[i].partNumber,
        shortDescription: this.selectedMaterialsEdit[i].shortDescription,
        descriptionEnglish: this.selectedMaterialsEdit[i].descriptionEnglish,
        imege: this.selectedMaterialsEdit[i].imege,
        idCatMaterials: this.selectedMaterialsEdit[i].idCatMaterials,
      })
      // }

      this.cols = [
        { field: 'id', header: 'No.' },
        { field: 'quantity', header: this.varQuantityEdit },
        { field: 'partNumber', header: this.varPartNumberEdit },
        { field: 'shortDescription', header: this.varShortDescriptionEdit },
        { field: 'descriptionEnglish', header: this.varDescriptionEnglishEdit },

      ];

    }
    let arrayTableLength = this.arrayTableEdit.length
    for (let j = 0; j < arrayTableLength; j++) {
      this.AddMaterialEdit.push({ id: cont, idMaterials: this.arrayTableEdit[j].idCatMaterials, quantity: this.quantityEdit, reusable: this.reusableEdit, commentary: this.commentaryedit })

    }
  }

  dragEnd(algo: any) {
    this.draggedMaterialEdit = null;
  }
  dragStart(event, materialEdit: MaterialEdit) {
    let alert = false;
    for (let j = 0; j < this.arrayTableEdit.length; j++) {
      if (materialEdit.partNumber == this.arrayTableEdit[j].partNumber) {
        alert = true
      }
    }
    if (alert) {
      this.draggedMaterialEdit = null;
      this.toastr.warning(this.repeatPartNumberEdit, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
    } else {
      this.draggedMaterialEdit = materialEdit;
    }
  }

  deleteMaterialEdit() {
    if (this.selectedCar1Edit.length > 0) {
      this.selectedCar1Edit.forEach(element => {
        var index: number = this.arrayTableEdit.indexOf(this.selectedCar1Edit.find(x => x.id == element.id));
        this.arrayTableEdit.splice(index, 1);


      });

      let selectedCar1Length = this.selectedCar1Edit.length
      this.arrayPartNumberTableEdit = []
      for (let i = 0; i < selectedCar1Length; i++) {
        this.arrayPartNumberTableEdit.push(this.selectedCar1Edit[i].partNumber)

      }
      let arrayTableLength = this.selectedMaterialsEdit.length
      let arrayPartNumberTableLength = this.arrayPartNumberTableEdit.length
      for (let t = 0; t < arrayTableLength; t++) {
        let numPartMaterial = this.selectedMaterialsEdit[t].partNumber
        for (let l = 0; l < arrayPartNumberTableLength; l++) {
          let numPartTable = this.arrayPartNumberTableEdit[l]
          if (numPartMaterial.trim() === numPartTable.trim()) {
            this.availableMaterialsEdit.push(this.selectedMaterialsEdit[t])
          }
        }
      }
    }
    this.selectedCar1Edit = []
  }
  findIndex(materialEdit: MaterialEdit) {
    let index = -1;
    for (let i = 0; i < this.availableMaterialsEdit.length; i++) {
      if (materialEdit.partNumber === this.availableMaterialsEdit[i].partNumber) {
        index = i;
        break;
      }
    }
    return index;
  }

  getSupplyingEdit() {
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.MasterService.getCatMaster().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();
            //Select supplyng
            this.supplyingSelect = []
            this.supplyings = data.CatSupplyng;
            let supplyngLength = this.supplyings.length
            for (let i = 0; i < supplyngLength; i++) {
              this.supplyingSelect.push({ label: this.supplyings[i].supplyng, value: this.supplyings[i].idSupplyng })
            }
          }
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }
  check() {
    if ($('input[name="check"]').is(':checked')) {
      this.reusableEdit = '1' //Si
    } else {
      this.reusableEdit = '0' //No
    }
  }

  idKit(row: any) {
    this.arrayAdd = []
    this.actualTable = []
    this.selectRow = []
    this.arrayAdd.push(row)
    this.actualTable = this.arrayTableEdit
    this.selectRowId = row
    this.selectRow.push(row)
  }


  dragEndKit(algo: any) {
    this.draggedMaterialEdit = null;
  }
  dragStartKit(event, materialEdit: MaterialEdit) {
    this.selectedCar1Edit = []
    this.draggedMaterialEdit = materialEdit;
    this.dropKit(this.draggedMaterialEdit)
    this.selectedCar1Edit.push(materialEdit)

    this.deletematerialKit();


  }
  deletematerialKit() {
    let varArrayTable
    varArrayTable = this.arrayTableEdit
    this.arrayTableEdit = []
    this.arrayTableEdit = varArrayTable
    if (this.selectedCar1Edit.length > 0) {
      this.selectedCar1Edit.forEach(element => {
        var index: number = this.arrayTableEdit.indexOf(this.selectedCar1Edit.find(x => x.id == element.id));
        let idAgregar = this.selectedCar1Edit.find(x => x.id == element.id)
        this.arrayTableEdit.splice(index, 1);
      });
      let selectedCar1Length = this.selectedCar1Edit.length
      this.arrayPartNumberTableEdit = []
      for (let i = 0; i < selectedCar1Length; i++) {
        this.arrayPartNumberTableEdit.push(this.selectedCar1Edit[i].partNumber)

      }
      let arrayTableLength = this.selectedMaterialsEdit.length
      let arrayPartNumberTableLength = this.arrayPartNumberTableEdit.length
      for (let t = 0; t < arrayTableLength; t++) {
        let numPartMaterial = this.selectedMaterialsEdit[t].partNumber
        for (let l = 0; l < arrayPartNumberTableLength; l++) {
          let numPartTable = this.arrayPartNumberTableEdit[l]
          if (numPartMaterial.trim() === numPartTable.trim()) {
            this.availableMaterials.push(this.selectedMaterialsEdit[t])
          }
        }
      }
    }
    let arrayCont = this.arrayTableEdit

    this.arrayTableEdit = []

    let actualTableLength = arrayCont.length

    var cont = 0
    for (let i = 0; i < actualTableLength; i++) {
      cont++;
      this.arrayTableEdit.push({
        id: cont,
        quantity: arrayCont[i].quantity,
        partNumber: arrayCont[i].partNumber,
        shortDescription: arrayCont[i].shortDescription,
        descriptionEnglish: arrayCont[i].descriptionEnglish,
        imege: arrayCont[i].imege,
        idCatMaterials: arrayCont[i].idCatMaterials,
      })
    }

  }

  dropKit(arrayAdd: any) {
    var cont = 0
    this.arrayAdding = []
    this.arrayAdd = []
    this.arrayAdd.push(arrayAdd)
    this.selectRow.forEach(element => {
      var index: number = this.arrayTableEdit.indexOf(this.selectRow.find(x => x.id == element.id));
      this.arrayAdding.push(this.selectRow.find(x => x.id == element.id))
      this.arrayTableEdit.splice(index, 1);
    });

    let arrayAddLength = this.arrayAdd.length
    let arrayAddingLength = this.arrayAdding.length
    for (let i = 0; i < arrayAddLength; i++) {
      this.partNumberAdd = this.arrayAdd[i].partNumber
      this.shortDescriptionAdd = this.arrayAdd[i].shortDescription
      this.descriptionEnglishAdd = this.arrayAdd[i].descriptionEnglish
      this.imegeAdd = this.arrayAdd[i].imege
      this.idCatMaterialsAdd = this.arrayAdd[i].idCatMaterials
      for (let j = 0; j < arrayAddingLength; j++) {
        this.partNumberAdding = this.arrayAdding[j].partNumber
        this.shortDescriptionAdding = this.arrayAdding[j].shortDescription
        this.descriptionEnglishAdding = this.arrayAdding[j].descriptionEnglish
        this.imegeAdding = this.arrayAdding[j].imege
        this.idCatMaterialsAdding = this.arrayAdding[j].idCatMaterials
      }

      cont++;
      this.AddMaterialEdit = []
      this.arrayTableEdit.push({
        id: cont,
        quantity: 1,
        partNumber: this.partNumberAdd + ', ' + this.partNumberAdding,
        shortDescription: this.shortDescriptionAdd + ', ' + this.shortDescriptionAdding,
        descriptionEnglish: this.descriptionEnglishAdd + ', ' + this.descriptionEnglishAdding,
        imege: this.imegeAdd + ', ' + this.imegeAdding,
        idCatMaterials: this.idCatMaterialsAdd + ', ' + this.idCatMaterialsAdding,
      })
    }
  }

  sendCompleteKitEdit(tableMaterial) {
    this.arrayTableSend = []


    let materialsLength = tableMaterial.length
    for (let i = 0; i < materialsLength; i++) {
      let valueReusable: boolean;

      if (tableMaterial[i].reusable === true || tableMaterial[i].reusable === 1) {
        valueReusable = true
      } else {
        valueReusable = false
      }

      this.arrayTableSend.push({
        reusable: valueReusable,
        commentary: tableMaterial[i].commentaryedit,
        quantity: tableMaterial[i].quantity,
        idMaterials: tableMaterial[i].idCatMaterials.toString()
      })
    }

    let testLocal = this.localStations.find(s => s.supplyng === this.idSelectedLocalStationEdit);
    let valueLocal = testLocal.idSupplyng

    let testInternational = this.localStations.find(s => s.supplyng === this.idSelectedInternationalStationEdit);
    let valueInternational = testInternational.idSupplyng

    let testUm = this.ums.find(s => s.code === this.idSelectedUmEdit);
    let valueUm = testUm.idMeasurementUnit

    this.MaterialsInfoEdit = []
    let send = {
      idCatMaster: this.IDMaster,
      lote: this.dataMasterEdit.lote,
      description: this.dataMasterEdit.description,
      descriptionEnglish: this.dataMasterEdit.descriptionEnglish,
      container: this.dataMasterEdit.container,
      idUM: valueUm,
      idLocalStations: valueLocal,
      idInternationalStaions: valueInternational,
      idCycle: this.idSelectedCycleEdit,
      commentary: this.commentaryEdit,
      picture: this.dataMasterEdit.picture,
      nameFile: this.dataMasterEdit.nameFile,
      status: 1,
      inicioVigencia: this.effectiveDate,
      materials: this.arrayTableSend
    }
    this.subscription.add(this.MasterService.UpdateKit(send).subscribe((data: any) => {

      this.blockUI.stop();
      this.toastr.success(this.varEditedKit, this.varSuccessful, { positionClass: 'toast-bottom-full-width', closeButton: true });
      $('#EditModal').modal('hide');
      $("body").change(function () {
        $(this).val('style');
      });
      this.getDataMaster();
      this.router.navigate(['/catMaster']).then(() => {
        window.location.reload();
      });
    }, error => {
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }));

  }


  downloadExcel() {
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.MasterService.getFile().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();
            this.excelHref = data.Master;

            var element = document.createElement('a');
            element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(this.excelHref));
            element.setAttribute('download', "Master");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
          }
          this.blockUI.stop();
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }

  filterMaterials(input) {
    let materialsTemp = this.materialsSave.filter(m => m.partNumber.includes(input.toUpperCase()));

    this.availableMaterialsEdit = [];
    this.availableMaterialsEdit = materialsTemp;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  TextReusable(reusable) {
    return reusable == 0 ? 'No' : this.varYes;
  }

  viewImage(c) {
    this.showViewPhoto = true;
    this.blockUI.start();
    this.subscription.add(this.MasterService.getImgFile(c.picture).
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            $('#viewImage').modal("show");
            this.loteImg = c.lote;
            var typeFile = c.picture.split(".")[1];
            if (typeFile == "jpg" || typeFile == "JPG" || typeFile == "jpeg" || typeFile == "JPEG") {
              this.imageKit = 'data:image/jpeg;base64,' + data.fileBase64.base64;
            } else if (typeFile == "png" || typeFile == "PNG") {
              this.imageKit = 'data:image/png;base64,' + data.fileBase64.base64;
            }
          }

        }
      )
    );
    this.blockUI.stop();
  }
}
export interface Master {
  idMaster?;
  lote?;
  UM?;
  description?;
  descriptionEnglish?;
  container?;
  localStations?;
  internationalStaions?;
  picture?;
  cycle?;
  commentary?;
}

export interface Kit {
  id?;
  idCatMaterials?;
  partNumber?;
  shortDescription?;
  descriptionEnglish?;
  supplyng?;
  quantity?;
}

export interface MaterialEdit {
  id?;
  partNumber?;
  shortDescription?;
  descriptionEnglish?;
  typeofSupply?;
  familydescription?;
  imege?;
  idCatMaterials?;
}

export interface ArrayTableEdit {
  id?;
  quantity?;
  partNumber?;
  shortDescription?;
  descriptionEnglish?;
  typeofSupply?;
  familydescription?;
  imege?;
  idCatMaterials?;
}
