import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CurrentDataService } from './current-data.service';

const apiUrl = environment.apiUrl;
var testUpdate
@Injectable({
  providedIn: 'root' 
})
export class ProfileService {

  constructor(private http: HttpClient,
    private currentData: CurrentDataService) { }
  public  getDataUpdate = new Subject<any>();
  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Authorization':  localStorage.getItem('token')
    });
    return headers;
  }

  getDataMenu(){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/Profiles/GetMenu',  {headers});
  }
  addProfile(send : any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.put(apiUrl + '/Profiles/InsertProfile', send, {headers:headers});
  }
  getDataProfiles(){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/Profiles/GetProfiles',  {headers});
  }
  deleteProfile(idProfile : string ,idUser : string){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params  = new HttpParams ({fromObject:{idProfile,idUser}});
    return this.http.get(apiUrl + '/Profiles/DeleteProfile',  {headers, params});
  }
  sendDataUpdate(data: any){
    this.currentData.isValidateSession();
    this.getDataUpdate.next(data);
  }
  getUpdateProfile(updateProfile : any){
    testUpdate = updateProfile
    this.sendUpdate()

  }
  sendUpdate(){
    testUpdate = testUpdate
return testUpdate

  }
}
