import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, UrlTree, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService } from './Login.service';
import { Observable } from 'rxjs';
import { PermissionsService } from './permissions.service';
import { CurrentDataService } from './current-data.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  responsible: any;
  folio: any;
  idForward: any; 
  constructor(private permission: PermissionsService, private router: Router, private currentData: CurrentDataService, private toastr: ToastrService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let logued;


    if(localStorage.getItem('logued') ) {
      logued = localStorage.getItem('logued') === 'false' ? false: true; 
    } else {
      logued = false;
    }
   

    if( !logued ){
      let cont = 0; 
      this.responsible = route.queryParams.responsible 
      this.folio = route.queryParams.folio
      this.idForward = route.queryParams.idForward;
      if( this.responsible && this.folio && cont == 0) {
        this.currentData.setQueryParamsIrregularities( { responsible: this.responsible, folio : this.folio, idForward : this.idForward, isReload: true });
        this.toastr.warning( localStorage.getItem('language') === 'true' ?  'To access the irregularity you must log in first' : 'Para acceder a la irregularidad debes iniciar sesión primero', 'Error' , { positionClass: 'toast-bottom-full-width', closeButton: true });
        cont++;
      }
      this.router.navigateByUrl('login');
      return false;
    } else if( this.canShow(route.data) ) {
      return this.canShow(route.data);
    } else {
      if( this.responsible && this.folio ) {
        this.router.navigate(['/reportIrregularities'], { queryParams: { responsible: this.currentData.queryParamsiIrregularities.responsible, folio: this.currentData.queryParamsiIrregularities.folio, idForward: this.currentData.queryParamsiIrregularities.idForward } }); 
      }else {
        this.router.navigateByUrl('main');
      }
    }
  }

  canShow(data) {
    let logued = localStorage.getItem('logued') === 'false' ? false: true;
    if ( data.main && logued) {
      return true;
    }else  if(  data.idMenuOptions ){
      return this.permission.canView( data.idMenu, data.idSubMenu, data.idMenuOptions, 'R');      
    }else if( data.idSubMenu ) {
      return this.permission.CanSubMenu( data.idMenu, data.idSubMenu, 'R');
    }else if( data.idMenu ) {
      return this.permission.canMenu(data.idMenu);
    }
    return false;    
  }
}
