<div>
  <!--<div class="marge mt-3" *ngIf="isTotal">
    <input type="checkbox" name="{{allMenu}}" value="t1" id="t1" (change)="selectMenu($event)"> {{allMenu}}<br>
  </div>
  <div style="margin-left:5% ;" *ngFor="let m of menus; let i = index">
    <input type="checkbox" name="{{m.description}}" value="{{m.val}}" id="{{m.val}}" [checked]="m.checkMenu"
      (change)="selectMenu($event)"> {{m.description}} <br>
    <div style="margin-left:5% ;" *ngFor="let s of m.submenus; let i = index">
      <input type="checkbox" name="{{s.description}}" value="{{s.val}}" id="{{s.val}}" [checked]="s.check"
        (change)="selectMenu($event)"> {{s.description}}
      <div style="margin-left:5% ;" *ngFor="let o of s.menuOptions; let i = index">
        <label> <input type="checkbox" name="{{o.description}}" id="{{o.val}}" value="{{o.val}}" [checked]="o.check"
            (change)="selectMenu($event)"> {{o.description}} </label><br>
      </div>
    </div>
  </div>-->

  <div class="marge" style="margin-top: 4%;">
    <span class="p-float-label">
      <input class="fontSizeLabelModal" id="nameProfile" type="text" size="30" pInputText
        style="width: 50%; margin-bottom: 10px;" pattern="[A-Za-z0-9]+" (keyup)="validateInput($event)"
        name="nameProfile" [(ngModel)]="nameProfile" autocomplete="off" required disabled="true">
      <label class="fontSizeLabelModal" for="float-input" [ngClass]="{ 'labelName':nameProfile!==''}"
        style="margin-left: 20px;">{{nameProfileVar}}: </label>
      <label style="text-align: right;" [ngClass]="{ 'labelName':nameProfile!==''}" class="obligation">**</label>
    </span>
  </div>

  <div class="marge">
    <p-table [columns]="cols" [value]="tableInsert" [rows]="40" #dtt dataKey="menuId" [paginator]="true"
      [resizableColumns]="true" [reorderableColumns]="true" sortMode="multiple">
      <ng-template pTemplate="caption">
        <!--<div style="text-align: left;">
          <input class="containerFiltro uppercaseSearch" type="text" pInputText size="50"
            placeholder={{varGlobalFilter}} (input)="dtt.filterGlobal($event.target.value, 'contains')"
            style="width:auto">
        </div>-->
        <br>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of cols" class="headertable" pResizableColumn pReorderableColumn>
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <!--<th class="headertable" *ngIf="canDelete">{{varDELETE}}</th>-->
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field" class="ui-fluid styleTable">
            <input class="uppercaseSearch" pInputText type="text"
              (input)="dtt.filter($event.target.value, col.field, col.filterMatchMode)"
              [value]="$any(dtt.filters[col.field])?.value">
          </th>
          <!--<th class="styleTable" *ngIf="canDelete"></th>-->
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-c let-columns="columns">
        <tr class="uppercase center">
          <td class="styleTable">{{c.menu}}</td>
          <td class="styleTable">{{c.submenu}}</td>
          <td class="styleTable">{{c.menuOption}}</td>

          <td class="styleTable">
            <div class="form-check row checkTable">
              <input *ngIf="c.read !== null" class="form-check-input" type="checkbox" (change)="onChecked(c, 'read')" [checked]="c.read === '1' || c.read === 'r'" disabled>
              <input *ngIf="c.read === null" class="form-check-input" type="checkbox" (change)="onChecked(c, 'read')" [checked]="false" disabled>
            </div>
          </td>

          <td class="styleTable">
            <div class="form-check row checkTable">
              <input *ngIf="c.add !== null" class="form-check-input" type="checkbox" (change)="onChecked(c, 'add')" [checked]="c.add === '1' || c.add === 'a'" disabled>
              <input *ngIf="c.add === null" class="form-check-input" type="checkbox" (change)="onChecked(c, 'add')" [checked]="false" disabled>
            </div>
          </td>

          <td class="styleTable">
            <div class="form-check row checkTable">
              <input *ngIf="c.update !== null" class="form-check-input" type="checkbox" (change)="onChecked(c,'update')" [checked]="c.update === '1' || c.update === 'u'" disabled>
              <input *ngIf="c.update === null" class="form-check-input" type="checkbox" (change)="onChecked(c,'update')" [checked]="false" disabled>
            </div>
          </td>
          <td class="styleTable">
            <div class="form-check row checkTable">
              <input *ngIf="c.deletes !== null" class="form-check-input" type="checkbox" (change)="onChecked(c,'deletes')" [checked]="c.deletes === '1' || c.deletes === 'd'" disabled>
              <input *ngIf="c.deletes === null" class="form-check-input" type="checkbox" (change)="onChecked(c,'deletes')" [checked]="false" disabled>
            </div>
          </td>
          <!--<td class="styleTable" *ngIf="canDelete">
            <button type="button" class="btn-options styleButtonTable " (click)="deleteModal(c)">
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>-->

        </tr>
      </ng-template>
    </p-table>
    <div class="relative" style="margin-top: 2%; margin-bottom: 6%;">
      <button type="button" class="btn-options styleButtonTable" style="float: left; margin-left: 8%;"
        (click)="cancelProfile()">&nbsp; {{cancel}}
      </button>
      <!--<button type="button" (click)="updateProfile()" class="btn-options styleButtonTable"
        style="float: right; margin-right: 8%;">
        <i class="fas fa-plus-circle"></i>&nbsp; {{add}}
      </button>-->
    </div>
  </div>
  <br>
</div>

<!-- Modal Para Dar permisos CRUD-->
<div class="modal fade" id="saveMenu" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{instruccionAddProfile}}</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModalSave()" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body" style="margin-left: 10px; margin-top: 20px;">
        <div style="margin-left:5% ;" *ngFor="let p of permitionSelect; let i = index">
          <input type="checkbox" name="{{p.value}}" value="{{p.value}}" id="{{p.value}}"> {{p.label}}<br>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-options styleButtonTable" data-toggle="modal" data-target="#addModal"
          (click)="madeTable(true)">
          <i class="fas fa-plus-circle"></i>&nbsp; {{varSave}}
        </button>
      </div>
    </div>
  </div>
</div>
