import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CurrentDataService } from './current-data.service';

const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class MaterialsService {
  constructor(private http: HttpClient,
    private currentData: CurrentDataService) { }

  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Authorization':  localStorage.getItem('token')
    });
    return headers;
  }

  getData(){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/Materials/GetMaterialsByPath' , {headers});
  }

  getDataall(){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/Materials/GetMaterials' , {headers});
  }

  addMaterial(send : any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.post(apiUrl + '/Materials/AddMaterials', send, {headers:headers});
  }
  editMaterial(send : any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.put(apiUrl + '/Materials/UpdateMaterials', send, {headers:headers});
  }
  geMaterialBYTSU(send : any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/Materials/GetMaterialById?idTSU=' +send, {headers:headers});
  }
  download(lang){
    let headers = this.getHeaders();
    return this.http.get(apiUrl + `/Materials/GetExcelMaterials?lang=${lang}`, {headers:headers});
  }

  buildExcel(lang){
    let headers = this.getHeaders();
    return this.http.get(apiUrl + `/Materials/BuildExcelFile?lang=${lang}`, {headers:headers});
  }
  downloadExcel(filename: string){
    let headers = this.getHeaders();
    return this.http.get(apiUrl + `/Materials/GetExcelFile?filename=${filename}`, {headers:headers});
  }
  deleteExcelFile(filename: string){
    let headers = this.getHeaders();
    return this.http.delete(apiUrl + `/Materials/DeleteExcelFile?filename=${filename}`, {headers:headers});
  }
}
