import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { CurrentDataService } from './current-data.service';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class TsuService {
  tsuEdit: Subject<any> = new Subject<any>();
  tsuAdd: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient,
    private currentData: CurrentDataService) { } 

  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Authorization':  localStorage.getItem('token') 
    }); 
    return headers;
  }
  
  getTsus() {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + `/TSU/GetTSU` , {headers});
  }
  getTsusALL() {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + `/TSU/GetTSUAll` , {headers});
  }
  
  delete(idTsu, idUser){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params  = new HttpParams ({fromObject: {idTsu, idUser}});

    return this.http.get(apiUrl + `/TSU/DeleteTSU` , {headers, params});
  }
   
  add(send : any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.put(apiUrl + '/TSU/AddTSU', send, {headers:headers});
  }

  getPdf(idTSU, language) {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params  = new HttpParams ({fromObject: {idTSU, language}});
    return this.http.get(apiUrl + '/TSU/GetPDF',  {headers, params});
  }

  getExcel(idTSU, language) {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params  = new HttpParams ({fromObject: {idTSU, language}});
    return this.http.get(apiUrl + '/TSU/GetExcelTSU',  {headers, params});
  }

  //Metodos de comunicacion entre componentes de TSU
  sendEditTsuComponent(data) {
    this.currentData.isValidateSession();
    this.tsuEdit.next(data);
  }

  sendAddTsuComponent(data) {
    this.currentData.isValidateSession();
    this.tsuAdd.next(data);
  }

  update(send : any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.put(apiUrl + '/TSU/UpdateTSU', send, {headers:headers});
  }
}
