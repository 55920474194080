<block-ui>
<div class="container-fluid contentHEight">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-12">
      <div id="carouselPab" class="carousel carousel-dark slide" data-bs-ride="carousel" style="position: fixed; width: 100%;">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselPab" data-bs-slide-to="0" class="active" aria-current="true" aria-label="1"></button>
          <button type="button" data-bs-target="#carouselPab" data-bs-slide-to="1" aria-label="2"></button>
          <button type="button" data-bs-target="#carouselPab" data-bs-slide-to="2" aria-label="3"></button>
          <button type="button" data-bs-target="#carouselPab" data-bs-slide-to="3" aria-label="4"></button>
          <button type="button" data-bs-target="#carouselPab" data-bs-slide-to="4" aria-label="5"></button>
          <button type="button" data-bs-target="#carouselPab" data-bs-slide-to="5" aria-label="6"></button>
        </div>
        <div class="carousel-inner carouselContainer">
          <div class="carousel-item active">
            <img class="carouselImage1" [defaultImage]="carouselDefault1" [lazyLoad]="image1" alt="First slide">
          </div>
          <div class="carousel-item">
            <img class="carouselImage2" [defaultImage]="carouselDefault2" [lazyLoad]="image2" alt="Second slide">
          </div>
          <div class="carousel-item">
            <img class="carouselImage3" [defaultImage]="carouselDefault3" [lazyLoad]="image3" alt="Third slide">
          </div>
          <div class="carousel-item ">
            <img class="carouselImage4" [defaultImage]="carouselDefault4" [lazyLoad]="image4" alt="Fourth slide">
          </div>
          <div class="carousel-item">
            <img class="carouselImage5" [defaultImage]="carouselDefault5" [lazyLoad]="image5" alt="Fifth slide">
          </div>
          <div class="carousel-item ">
            <img class="carouselImage6" [defaultImage]="carouselDefault6" [lazyLoad]="image6" alt="Sixth slide">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselPab" data-bs-slide="prev">
          <i class="fa fa-chevron-left" style="width: 10%; height: 10%;"></i>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselPab" data-bs-slide="next">
          <i class="fa fa-chevron-right" style="width: 10%; height: 10%;"></i>
        </button>
      </div>

    </div>
  </div>

  <div class="marginNotice" >
    <div class="col-12 col-sm-6 col-md-8" style="margin-top: 75px;  position: fixed;   margin-bottom: -20px;">
      <ul class="notices">
          &nbsp;<h1 class="h1" style="margin-left: -20px;">{{varNotices}}</h1>
            <li class="uppercase"  *ngFor="let subject of stringList">{{subject.label}} </li>
        </ul>
        <div >
        </div>
          <div class="col-12 col-sm-12 col-md-12">
              <button type="button" class="btn btn-info" style="right:inherit; margin-left: 70%;" [routerLink]="['/consultNotice']">{{varViewAll}}...</button>
            </div>
        </div>
  </div>
  <br>

</div>
</block-ui>



<!--     <block-ui>
      <div lass="container-fluid" style="height: 330px;">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-12">
            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" style="position: absolute; width: 100%;" >

              <ol class="carousel-indicators" class="carousel-indicators" style="margin-bottom: 60px;" >
                <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
                <li data-target="#myCarousel" data-slide-to="2"></li>
                <li data-target="#myCarousel" data-slide-to="3"></li>
                <li data-target="#myCarousel" data-slide-to="4"></li>
                <li data-target="#myCarousel" data-slide-to="5"></li>
              </ol>
              <div class="carousel-inner carouselContainer" >
                <div class="carousel-item active">
                  <img class="carouselImage1" [defaultImage]="carouselDefault1" [lazyLoad]="image1" alt="First slide">
                </div>
                <div class="carousel-item">
                  <img class="carouselImage2" [defaultImage]="carouselDefault2" [lazyLoad]="image2" alt="Second slide">
                </div>
                <div class="carousel-item">
                  <img class="carouselImage3" [defaultImage]="carouselDefault3" [lazyLoad]="image3" alt="Third slide">
                </div>
                <div class="carousel-item ">
                  <img class="carouselImage4" [defaultImage]="carouselDefault4" [lazyLoad]="image4" alt="Fourth slide">
                </div>
                <div class="carousel-item">
                  <img class="carouselImage5" [defaultImage]="carouselDefault5" [lazyLoad]="image5" alt="Fifth slide">
                </div>
                <div class="carousel-item ">
                  <img class="carouselImage6" [defaultImage]="carouselDefault6" [lazyLoad]="image6" alt="Sixth slide">
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
          <div class="containerMain">
            <div  >
              <div class="col-12 col-sm-6 col-md-8" style="margin-top: 65px;  position: fixed;   margin-bottom: -20px;">
                <ul class="notices">
                    &nbsp;<h1 class="h1" style="margin-left: -20px;">{{varNotices}}</h1>
                      <li class="uppercase"  *ngFor="let subject of stringList">{{subject.label}} </li>
                  </ul>
                  <div >
                  </div>
                    <div class="col-12 col-sm-12 col-md-12">
                        <button type="button" class="btn btn-info" style="right:inherit; margin-left: 70%;" [routerLink]="['/consultNotice']">{{varViewAll}}...</button>
                      </div>
                  </div>
            </div>
          </div>
      </div>
  </block-ui> -->
