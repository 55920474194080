import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { CurrentDataService } from './current-data.service';

const apiUrl = environment.apiUrl;

@Directive()
@Injectable({
  providedIn: 'root'
})
export class MasterService {
  message: Subject<any> = new Subject<any>();
  //@share
  @Output() change: EventEmitter<boolean> = new EventEmitter();


  constructor(private http: HttpClient, private currentData: CurrentDataService) { }

  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return headers;
  }

  getData() {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/Master/GetMaster', { headers });
  }

  getKit(idMaster: string) {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams({ fromObject: { idMaster } });
    return this.http.get(apiUrl + '/Master/GetKit', { headers, params });
  }

  getCatMaster() {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/Master/GetCatsMaster', { headers });
  }

  sendData(data: any) {
    this.currentData.isValidateSession();
    this.message.next(data);
  }

  sendDataEmit(data) {
    this.currentData.isValidateSession();
    this.change.emit(data);
  }

  addKit(send: any) {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.post(apiUrl + '/Master/AddMaster', send, { headers: headers });
  }

  UpdateKit(send: any) {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.put(apiUrl + '/Master/UpdateMaster', send, { headers: headers });
  }

  getExcel(idMaster) {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams({ fromObject: { idMaster } });
    return this.http.get(apiUrl + '', { headers, params });
  }

  getFile() {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/Master/GetFile', { headers });
  }

  /**
   * Servicio que obtiene el base 64 de la imagen del kit de master
  */
  getImgFile(path: any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams ({fromObject:{path}});
    return this.http.get(apiUrl + '/File/GetFileBase64', {headers, params});
  }
}
