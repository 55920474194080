import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CurrentDataService } from './current-data.service';

const apiUrl = environment.apiUrl;
@Injectable({
    providedIn: 'root'
})
export class RestockCapService {
    constructor(private http: HttpClient,
        private currentData: CurrentDataService) { }

    getHeaders(): HttpHeaders {
        const headers = new HttpHeaders({
            'Authorization': localStorage.getItem('token')
        });
        return headers;
    }

    //OBTENEMOS TODA LA INFORMACION DE TOPES DE REABASTO
    getRestockAllData(idUser) {
        this.currentData.isValidateSession();
        let headers = this.getHeaders();
        let params = new HttpParams({fromObject: {idUser}});
        return this.http.get(apiUrl + '/RestockCap/GetAllRestockCap', { headers, params });
    }

    add(send: any) {
        this.currentData.isValidateSession();
        let headers = this.getHeaders();
        return this.http.post(apiUrl + '/RestockCap/AddRestockCap', send, { headers: headers });
    }

    delete(send: any) {
        this.currentData.isValidateSession();
        let headers = this.getHeaders();
        return this.http.post(apiUrl + '/RestockCap/DeleteRestockCap', send, { headers });
    }

    getExcel(idRestockCap) {
        this.currentData.isValidateSession();
        let headers = this.getHeaders();
        let params = new HttpParams({ fromObject: { idRestockCap } });
        return this.http.get(apiUrl + '/RestockCap/GetRestockCapExcel', { headers, params });
    }

    getPdf(idRestockCap) {
        this.currentData.isValidateSession();
        let headers = this.getHeaders();
        let params = new HttpParams({ fromObject: { idRestockCap } });
        return this.http.get(apiUrl + '/RestockCap/GetRestockCapPdf', { headers, params });
    }
}