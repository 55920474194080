<block-ui style="margin-bottom: 2%;">
  <div class="container-fluid">
    <div class="row divTitle">
      <section style="background:url('../../assets/images/aeromexico-frontPage.jpg');" class="titleCat titleMaster">
        <h1 class="titleComponent">TSU</h1>
      </section>
    </div>
    <div class="containerTsu">
      <div class="divContainer">
        <p-splitter [style]="{'height': '100%','border-style': 'none', 'width': '100%'}" [panelSizes]="[30,70]">
          <ng-template pTemplate>
            <div>
              <p-table [columns]="cols" [value]="availableMaterials" dataKey="tableImagen" #dtt [scrollable]="true"
                scrollHeight="650px">
                <ng-template pTemplate="caption">
                  <div style="text-align: left;">
                    <input class="containerFiltro uppercaseSearch" type="text" pInputText size="50"
                      placeholder={{varGlobalFilter}} (input)="filterMaterials($event.target.value)" style="width:auto"
                      (onChange)="ngOnInit()">
                  </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th class="headertable">{{varMaterials}}</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-tableMaterial let-columns="columns">
                  <tr [pSelectableRow]="contId" data-toggle="modal" data-target="#AddSuply">
                    <td class="ui-helper-clearfix" pDraggable="materials" (onDragStart)="dragStart($event,tableMaterial)"
                      (onDragEnd)="dragEnd($event)">

                      <div style="text-align: center; font-weight: bold;">{{tableMaterial.partNumber}}
                      </div>
                      <div class="h-divider">
                        <div class="shadow"></div>
                      </div>

                      <div style="text-align: center">{{tableMaterial.shortDescription}} </div>
                      <div style="text-align: center; margin-bottom: 20px;">
                        {{tableMaterial.familydescription}}
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </ng-template>
          <ng-template pTemplate>
            <div style="text-align: center;" class=" drop-column" pDroppable="materials"
              (onDrop)="drop($event)" [ngClass]="{'ui-highlight-material':draggedMaterial}">
              <p-table class="tableMaster" [columns]="cols" [value]="arrayTable" [(selection)]="selectedCar1"
                dataKey="partNumber">
                <ng-template pTemplate="header" let-columns>
                  <tr style="text-align: center;">
                    <th style="width: 3em" class="headertable">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th class="headertable">No. </th>
                    <th class="headertable">{{varName}}</th>
                    <th class="headertable">{{varDescriptionEnglish}}</th>
                    <th class="headertable">{{varQuantity}}</th>
                    <th class="headertable">{{varPartNumber}}</th>
                    <th class="headertable"> {{varObservations}}</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-contId let-columns="columns">
                  <tr [pSelectableRow]="contId" style="text-align: center;" class="uppercase">
                    <td>
                      <p-tableCheckbox [value]="contId"></p-tableCheckbox>
                    </td>
                    <td>{{contId.id}} </td>
                    <td>{{contId.shortDescription}}</td>
                    <td>{{contId.descriptionEnglish}}</td>
                    <td pEditableColumn>
                      <p-cellEditor (change)="addQuantity(contId.quantity, contId.id)">
                        <ng-template pTemplate="input">
                          <input pInputText type="text" [(ngModel)]="contId.quantity">
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{contId.quantity}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>{{contId.partNumber}}</td>
                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input class="uppercase" pInputText type="text" [(ngModel)]="contId.observation">
                        </ng-template>
                        <ng-template pTemplate="output" class="uppercase">
                          {{contId.observation}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div style="text-align: right;">
                    <button type="button" class="btn-options styleButtonTable" (click)="deleteMaterial ();"><i
                        class="far fa-trash-alt iconDelete"></i>&nbsp;
                      {{varDelete}}</button>
                  </div>

                  <div style="text-align: right; margin-top: 15px;">
                    <button type="button" class="btn-options styleButtonTable" (click)="sendCompleteKit(arrayTable);"><i
                        class="far fa-save"></i>&nbsp;
                      {{varSave}}</button>
                  </div>
                </ng-template>
              </p-table>

              <!-- <div class="ui-g col-sm-12 col-md-12 col-12" style="margin-top: 20px;" *ngIf="dataMaster"> -->
              <div class="ui-g col-sm-12 col-md-12 col-12" style="margin-top: 20px;">
                <div class="col-sm-6 col-md-6 col-6 uppercase">
                  <label class="fontSizeLabelModal"><b>&nbsp; {{varName}}:</b> {{nameQuery}}</label>
                  <br>
                  <label class="fontSizeLabelModal"><b>&nbsp; {{varClass}}:</b> {{classeQuery}}
                  </label>
                  <br>
                  <label class="fontSizeLabelModal"><b>&nbsp; {{varCommentary}}:</b> {{commentaryQuery}}
                  </label>
                  <br>
                </div>
              </div>
            </div>
          </ng-template>
        </p-splitter>
      </div>
    </div>
  </div>
</block-ui>
