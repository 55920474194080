<app-master *ngIf="master" [hijo]="padre"></app-master>
<div class="container-fluid" *ngIf="!master">
  <div class="row divTitle">
    <section style="background:url('../../assets/images/aeromexico-frontPage.jpg'); " class="titleCat">
      <h1 class="titleComponent">{{varAddKit}}</h1>
    </section>
  </div>
  <div class="content containerSendNotice">
    <br>
    <div class="row">
      <div class="col-sm-3 col-md-3 col-7 marginColumns">
        <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp; {{varLot}}: </b>
        </label>
      </div>
      <div class="col-sm-6 col-md-6 col-5">
        <input type="text" [(ngModel)]="lot" pInputText style="width: 50%;">
      </div>
      <div class="col-sm-4 col-md-4 col-4"></div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-3 col-md-3 col-7 marginColumns">
        <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp; {{varDescription}}:
          </b> </label>
      </div>
      <div class="col-sm-6 col-md-6 col-5">
        <input type="text" [(ngModel)]="description" pInputText style="width: 50%;">
      </div>
      <div class="col-sm-4 col-md-4 col-4"></div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-3 col-md-3 col-7 marginColumns">
        <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
            {{varEnglishDescription}}:
          </b> </label>
      </div>
      <div class="col-sm-6 col-md-6 col-5">
        <input type="text" [(ngModel)]="englishDescription" pInputText style="width: 50%;">
      </div>
      <div class="col-sm-4 col-md-4 col-4"></div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-3 col-md-3 col-7 marginColumns">
        <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp; {{varContainer}}: </b>
        </label>
      </div>
      <div class="col-sm-6 col-md-6 col-5">
        <input type="text" [(ngModel)]="container" pInputText style="width: 50%;">
      </div>
      <div class="col-sm-4 col-md-4 col-4"></div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-3 col-md-3 col-7 marginColumns">
        <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp; UM </b> </label>
      </div>
      <div class="col-sm-6 col-md-6 col-5">
        <p-dropdown [options]="umSelect" [(ngModel)]="idSelectedUm" optionLabel="name"
          [style]="{'width':'50%'}"></p-dropdown>
      </div>
      <div class="col-sm-4 col-md-4 col-4"></div>
    </div>
    <br>
    <div class="row">
      <div class="col-3 marginColumns">
        <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp; {{varLocalStation}}:
          </b> </label>
      </div>
      <div class="col-6">
        <p-dropdown [options]="localStationSelect" [(ngModel)]="idSelectedLocalStation" optionLabel="name"
          [style]="{'width':'50%'}"></p-dropdown>
      </div>
      <div class="col-4"> </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-3 col-md-3 col-7 marginColumns">
        <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
            {{varInternationalStation}}:</b> </label>
      </div>
      <div class="col-sm-6 col-md-6 col-5">
        <p-dropdown [options]="internationalStationSelect" [(ngModel)]="idSelectedInternationalStation"
          optionLabel="name" [style]="{'width':'50%'}"></p-dropdown>
      </div>
      <div class="col-sm-4 col-md-4 col-4"></div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-3 col-md-3 col-7 marginColumns">
        <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
            {{languageModule.cycle}}:</b> </label>
      </div>
      <div class="col-sm-6 col-md-6 col-5">
        <p-multiSelect [options]="cycleSelect" class="fontSizeLabelModal uppercase" [(ngModel)]="idSelectedCycle"
          autoWidth="false" defaultLabel="   " [style]="{'width':'50%'}"></p-multiSelect>
      </div>
      <div class="col-sm-4 col-md-4 col-4"></div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-3 col-md-3 col-7 marginColumns">
        <label class="fontSizeLabelModal"><b>&nbsp;
            Fecha Vigencia: </b> </label>
      </div>
      <div class="col-sm-6 col-md-6 col-5">
        <span class="ui-float-label mb-4 mt-3">
          <p-calendar class="col-sm-6 col-md-6 col-6" [(ngModel)]="dateStart" name="dateStart" id="dateStart"
            [locale]="en" dateFormat="dd/mm/yy" class="fontSizeLabelModal" [style]="{'width':'50%'}" [showIcon]="true">
          </p-calendar>
        </span>
      </div>
      <div class="col-sm-4 col-md-4 col-4"></div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-3 col-md-3 col-7 marginColumns">
        <label class="fontSizeLabelModal"><b>&nbsp;
            {{languageModule.commentary}}: </b> </label>
      </div>
      <div class="col-sm-6 col-md-6 col-5">
        <span class="ui-float-label mb-4 mt-3">
          <textarea style="width: 50%;" pInputTextarea maxlength="1000" [(ngModel)]="commentary"
            pInputTextarea></textarea>
        </span>
      </div>
      <div class="col-sm-4 col-md-4 col-4"></div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-3 col-md-3 col-4 marginColumns">
        <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp; {{varPicture}}:</b>
        </label>
      </div>
      <div class="col-sm-4 col-md-4 col-7 marginColumns" style="margin-left: 60px;">
        <button type="button" class="styleButtonTable" (click)="abrirAdd('imagen')"><i
            class='fas fa-paperclip'></i>&nbsp; {{varUploadFile}}</button>
        <input class="inputSelectFileCycle inputResponsive" type="file" multiple id="imagen" name="imagen"
          accept="image/*" (change)="incomingfileAdd($event)" style="display: none">
        <span id="glosaimagen">&nbsp;&nbsp;{{varNoFileSelected}}</span>

      </div>
      <div class="col-sm-4 col-md-4 col-4"></div>

      <br>
      <br>
    </div>
    <br>
    <div class="relative" style="margin-top: 2%;">
      <button class="btn-options styleButtonTable" [routerLink]="['/catMaster']">
        {{varCancel}}
      </button>
      <button type="button" class="btn-options styleButtonTable" style="float: right; margin-right: 8%;" (click)="sendDataKit()">
        <i class="fas fa-plus-circle"></i>&nbsp; {{varAddMaterials}}
      </button>
    </div>
    <br>
  </div>
</div>
