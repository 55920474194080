<block-ui>
  <div class="login-container input-sm">
    <div class="card special-card">
      <div class="card-body-okta">
        <h2 class="text-center login_title">
          {{ languageModule.welcomeTitle }}
        </h2>
        <div class="login_icon">
          <div class="wrap">
            <div class="logo_circle">
              <img src="/assets/images/login_logo.png" width="90" height="90" alt="" />
            </div>
          </div>
        </div>
        <div class="login_subtitle">
          <div class="text-center">
            <h3>{{ languageModule.welcomeSubtitle }}</h3>
          </div>
        </div>
        <div class="top-buffer text-center" *ngIf="errorFromAuth0 == false">
          <button
            type="button"
            class="btn btn-primary btn-lg login_okta_button"
            (click)="handleLogin()"
          >
            {{ languageModule.inGateone }}
          </button>
        </div>
      </div>
      <div class="card-body">
        <form id="form-login" (ngSubmit)="validateUser(data)" #data="ngForm" novalidate="">
          <div *ngIf="errorFromAuth0 == true">
            <div class="login_usuariopassword text-center">
              <div class="input-group top-buffer">
                <!-- <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    style="height: 38px; border-radius: 0.1rem"
                    ><i class="fas fa-user"></i
                  ></span>
                </div> -->

                <!-- <input
                  type="mail"
                  class="form-control"
                  maxlength="50"
                  (keyup)="NumText($event)"
                  name="txtUserName"
                  #txtUserName="ngModel"
                  [(ngModel)]="credentials.Username"
                  autocomplete="off"
                  [placeholder]="languageModule.email"
                  title="Debe ingresar un correo valido"
                  pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}$"
                  required
                /> -->
                <mat-form-field appearance="outline">
                  <mat-label>{{languageModule.email}}</mat-label>
                  <input
                    type="email"
                    matInput
                    name="txtUserName"
                    [(ngModel)]="credentials.Username"
                    #txtUserName="ngModel"
                    (keyup)="NumText($event)"
                    required
                    maxlength="50"
                    autocomplete="off"
                    pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}$"
                  />
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
              </div>
              <div class="input-group top-buffer">
                <!-- <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    style="height: 38px; border-radius: 0.1rem"
                    ><i class="fas fa-key"></i
                  ></span>
                </div>
                <input
                  type="{{ showPsw === false ? 'password' : 'text' }}"
                  class="form-control"
                  name="txtPasword"
                  #txtPassword="ngModel"
                  [(ngModel)]="credentials.Password"
                  [placeholder]="languageModule.clave"
                  autocomplete="off"
                  title="Debe ingresar su password"
                  required
                /> -->
                <mat-form-field appearance="outline">
                  <mat-label>{{languageModule.clave}}</mat-label>
                  <input
                    type="{{ showPsw === false ? 'password' : 'text' }}"
                    matInput
                    name="txtPasword"
                    [(ngModel)]="credentials.Password"
                    #txtPassword="ngModel"
                    autocomplete="off"
                    required
                  />
                </mat-form-field>
                <mat-icon
                  matSuffix
                  style="
                    position: absolute;
                    right: 10px;
                    top: 15px;
                    z-index: 100;
                    color: white
                  "
                  (click)="toggleShowPsw()"
                >
                  {{ !showPsw ? "visibility_off" : "visibility" }}
                </mat-icon>
              </div>
              <div style="text-align: center; margin-top: 5px">
                <a
                  [routerLink]="['/forgotPassword']"
                  style="color: white; text-decoration: none"
                >
                  {{ languageModule.recoverClave }}</a
                >
              </div>

              <div class="top-buffer">
                <button
                  type="submit"
                  [disabled]="!data.form.valid"
                  class="btn btn-primary login_button"
                >
                  {{ languageModule.inGateone }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</block-ui>
