<block-ui>
  <div class="container-fluid">
    <div class="row">
      <section style="background:url('../../assets/images/aeromexico-frontPage.jpg');" class="titleCat titleMatrix">
        <h1 class="titleComponent ">{{varEditMatrixForm}}</h1>
      </section>
      <br>
      <div class="col-sm-12 col-md-12 col-12">
        <nav style="margin-top: 40px;" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/catMatriz']">{{languageModule.titleModule}}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{varEditMatrixForm}}</li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="content containerSendNotice">
      <br>
      <!--lote-->
      <div class="row">
        <div class="col-sm-3 col-md-3 col-6 marginColumns">
          <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp; {{varLot}} </b>
          </label>
        </div>
        <div class="col-sm-6 col-md-6 col-6">
          <input id="lotEdit" type="text" name="lotEdit" [(ngModel)]="lotEdit" pInputText required disabled
            style="width: 50%;">
        </div>
        <div class="col-sm-4 col-md-4 col-4"></div>
      </div>
      <br>
      <!--equipmentFamily-->
      <div class="row">
        <div class="col-sm-3 col-md-3 col-6 marginColumns">
          <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
              {{varEquipmentFamily}}:
            </b> </label>
        </div>
        <div class="col-sm-6 col-md-6 col-6">
          <p-dropdown [options]="equipmentFamilySelectEdit" placeholder="** {{varEquipmentFamily}}" optionDisabled="inactive"
            [(ngModel)]="idSelectedEquipmentFamilyEdit" optionLabel="name" [style]="{'width':'50%'}"></p-dropdown>
        </div>
        <div class="col-sm-4 col-md-4 col-4"> </div>
      </div>

      <br>
      <!--product-->
      <div class="row">
        <div class="col-sm-3 col-md-3 col-6 marginColumns">
          <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
              {{varProduct}}:</b> </label>
        </div>
        <div class="col-sm-3 col-md-3 col-6 marginColumns">
          <p-dropdown [options]="productSelectEdit" placeholder="** {{varProduct}}" optionDisabled="inactive" [(ngModel)]="idSelectedProductEdit"
            optionLabel="name"
            (click)="sendDataQuantity(lotEdit, idSelectedEquipmentFamilyEdit, idSelectedProductEdit  )"
            [style]="{'width':'104%'}"></p-dropdown>
        </div>
        <div class="col-sm-4 col-md-4 col-4"></div>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-3 col-md-3 col-6 marginColumns">
          <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
              {{varQuantityTourist}}: </b>
          </label>
        </div>
        <div class="col-sm-6 col-md-6 col-6">
          <input id="quantityTouristEdit" type="text" pInputText pattern="[0-9]+" name="quantityTouristEdit"
            [(ngModel)]="quantityTouristEdit" (keyup)="NumQuantityTouristEdit($event)" required style="width: 50%;">
        </div>
        <div class="col-sm-4 col-md-4 col-4"></div>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-3 col-md-3 col-6 marginColumns">
          <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
              {{varQuantityPremier}}: </b>
          </label>
        </div>
        <div class="col-sm-6 col-md-6 col-6">
          <input id="quantityPremierEdit" type="text" pInputText pattern="[0-9]+" name="quantityPremierEdit"
            [(ngModel)]="quantityPremierEdit" (keyup)="NumQuantityPremierEdit($event)" required style="width: 50%;">
        </div>
        <div class="col-sm-4 col-md-4 col-4"></div>
      </div>
      <br>
      <div class="row">
        <div class="col-sm-3 col-md-3 col-6 marginColumns">
          <label class="obligation">**</label> <label class="fontSizeLabelModal"><b>&nbsp;
              {{labelEffectiveDate}}: </b>
          </label>
        </div>
        <div class="col-sm-6 col-md-6 col-6">
          <p-calendar [(ngModel)]="dateStart" name="dateStart" id="dateStart" dateFormat="dd/mm/yy"
            class="fontSizeLabelModal" [showIcon]="true" [style]="{'width':'50%'}">
          </p-calendar>
        </div>
        <div class="col-sm-4 col-md-4 col-4"></div>
      </div>
      <div class="row">
        <div class="col-sm-7 col-md-7 col-3"></div>
        <div class="col-sm-3 col-md-3 col-5 sendNoticeButton">
          <button style="margin-right: 30px;" type="button" pButton (click)="sendDataEdit()"
            class="btn-options styleButtonTable"><i class="fa fa-paper-plane"></i>&nbsp;{{varSend}}</button>
        </div>
        <div class="col-sm-2 col-md-2 col-2"></div>
      </div>
    </div>
  </div>
</block-ui>
