import { Pipe, PipeTransform } from '@angular/core';
import {formatDate, PlatformLocation} from '@angular/common';


@Pipe({
  name: 'dates'
})
export class DatesPipe implements PipeTransform {
  fecha: Date;
  transform(value: any, format : string): any {
    let date: any;
    if( value ) {
    if( format === 'navbar' ){
      let fecha: Date = value;
      this.getMonthLanguague(fecha.getMonth());
      date = `${this.getMonthLanguague(fecha.getMonth())} ${fecha.getDate()}, ${fecha.getFullYear()}`; 
      return date;
    } if( format === 'horas') {
      if( this.isProduction) {
        let day = value.split(' ')[0].split('/')[1].length === 1 ? "0" + value.split(' ')[0].split('/')[1] : value.split(' ')[0].split('/')[1];
        let month = value.split(' ')[0].split('/')[0].length === 1 ? "0" + value.split(' ')[0].split('/')[0]: value.split(' ')[0].split('/')[0];
        date = `${day}/${month}/${value.split(' ')[0].split('/')[2]}`;
      }else {
        date = value.split(' ')[0];
      }
      return date;
    } else {
      date = formatDate(value, 'dd/MM/yyyy', 'en');
      return date;
    }
  }
  }
  isProduction: boolean;

  constructor(private platformLocation: PlatformLocation) {
    //this.isProduction = (platformLocation as any).location.hostname === 'localhost' ? false : true;
    this.isProduction = false;
  }

  getMonthLanguague(month) {
    let date;
    switch (month) {
      case 0:
        date = localStorage.getItem('language') === 'true' ? 'Jan' : 'En'
        break;
      case 1:
        date = localStorage.getItem('language') === 'true' ? 'Feb' : 'Feb'
        break;
      case 2:
        date = localStorage.getItem('language') === 'true' ? 'Mar' : 'Mar'
        break;
      case 3:
        date = localStorage.getItem('language') === 'true' ? 'Apr' : 'Abr'
        break;
      case 4:
        date = localStorage.getItem('language') === 'true' ? 'May' : 'May'
        break;
      case 5:
        date = localStorage.getItem('language') === 'true' ? 'Jun' : 'Jun'
        break;
      case 6:
        date = localStorage.getItem('language') === 'true' ? 'Jul' : 'Jul'
        break;
      case 7:
        date = localStorage.getItem('language') === 'true' ? 'Aug' : 'Agto'
        break;
      case 8:
        date = localStorage.getItem('language') === 'true' ? 'Sep' : 'Sept'
        break;
      case 9:
        date = localStorage.getItem('language') === 'true' ? 'Oct' : 'Oct'
        break;
      case 10:
        date = localStorage.getItem('language') === 'true' ? 'Nov' : 'Nov'
        break;
      case 11:
        date = localStorage.getItem('language') === 'true' ? 'Dec' : 'Dic'
        break;
    
      default:
        break;
    }

    return date;
  }



}
