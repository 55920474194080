<block-ui>
  <div class="container-fluid">
    <div class="row divTitle">
      <section style="background:url('../../assets/images/aeromexico-frontPage.jpg');" class="titleCat titleMatrix">
        <h1 class="titleComponent ">{{languageModule.titleDetail}}</h1>
      </section>
      <div class="col-sm-12 col-md-12 col-12">
        <nav style="margin-top: 40px;" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/catMatriz']">{{languageModule.titleModule}}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{languageModule.titleDetail}}</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="containerMatrix">
      <p-table [value]="equipments" [paginator]="true" [rows]="5" [resizableColumns]="true" [reorderableColumns]="true">
        <ng-template pTemplate="header" class="bg-table">
          <tr>
            <th colspan="4 ">
              <span class="vertical-align">
                {{headers.materialDescS}}
              </span>
            </th>
            <th [attr.colspan]="(products.length - 1) * 3">{{headers.titleDetalMatrixS}}</th>
          </tr>
          <tr>
            <th colspan="4">
              <span class="vertical-align">
                {{headers.materialDescE}}
              </span>
            </th>
            <th [attr.colspan]="(products.length - 1) * 3">{{headers.titleDetalMatrixE}}</th>
          </tr>

          <tr>
            <th rowspan="2" colspan="1" class="sticky">AC</th>
            <th *ngFor="let p of products" colspan="3">{{p}}</th>

          </tr>
          <tr>
            <ng-container *ngFor="let p of products">
              <th colspan="1" *ngFor="let sh of subheaders">{{sh}}</th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr>
            <td colspan="1" class="sticky">{{rowData.name}}</td>
            <ng-container *ngFor="let p of rowData.products">
              <td colspan="3" *ngIf="!p.apply">NA</td>
              <td colspan="1" *ngIf="p.apply">{{p.data.pj}}</td>
              <td colspan="1" *ngIf="p.apply">{{p.data.py}}</td>
              <td colspan="1" *ngIf="p.apply">{{p.data.totaljy}}</td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>

      <!-- TABLA INTERMEDIA SELECCION -->
      <div class="mt-5 w-40  ">
        <p-table [value]="materialDetail" [resizableColumns]="true" [reorderableColumns]="true">
          <ng-template pTemplate="header" class="bg-table">
            <tr>
              <th colspan="4">{{headers.titleDetalMatrixS}}</th>
            </tr>
            <tr>
              <th colspan="4">{{headers.titleDetalMatrixE}}</th>
            </tr>
            <tr>
              <th colspan="1">AC</th>
              <th colspan="1">CJ(BC)</th>
              <th colspan="1">CY(EC)</th>
              <th colspan="1">Grupo</th>
            </tr>
            <tr>
              <th colspan="1">
                <form [formGroup]="formGroupEq">
                  <select formControlName="selectedEq" class="form-select fontSizeLabelModal"
                    style="margin-bottom: 15px; margin-top: 15px;" (change)="fillCjandCy()">
                    <option [ngValue]="e" *ngFor="let e of equipments">{{e.name}}</option>
                  </select>
                </form>
              </th>
              <th colspan="1">{{dataTable2?.cj}}</th>
              <th colspan="1">{{dataTable2?.cy}}</th>
              <th colspan="1">
                <form [formGroup]="formGroupPr">
                  <select formControlName="selectedPro" class="form-select fontSizeLabelModal"
                    style="margin-bottom: 15px; margin-top: 15px;" (change)="fillCjandCy()">
                    <option [ngValue]="p" *ngFor="let p of selectProducts">{{p.name}}</option>
                  </select>
                </form>

              </th>
            </tr>
          </ng-template>
        </p-table>
      </div>



      <br />

      <!-- Segunda Tabla DISTRIBUCIÓN TOTAL DE MATERIALES POR EMPAQUE O PIEZA Y POR CABINA -->
      <div class="row">
        <div class="col-8">
          <p-table [value]="materials" [resizableColumns]="true" [reorderableColumns]="true">
            <ng-template pTemplate="header" class="bg-table">
              <tr>
                <th colspan="6">DISTRIBUCIÓN TOTAL DE MATERIALES POR EMPAQUE O PIEZA Y POR CABINA
                </th>
              </tr>
              <tr>
                <th colspan="6">ITEM DISTRIBUTION PER CABIN</th>
              </tr>
              <tr>
                <th colspan="1">LOTE</th>
                <th colspan="1">DESCRIPCIÓN</th>
                <th colspan="1">CONTENEDOR</th>
                <th colspan="1">UM</th>
                <th colspan="2">Cantidades</th>
              </tr>
              <tr>
                <th colspan="1">Part Number</th>
                <th colspan="1">DESCRIPTION</th>
                <th colspan="1">CONTAINER</th>
                <th colspan="1">MU</th>
                <th colspan="2">Totales</th>
              </tr>
              <tr>
                <th colspan="1" rowspan="2">{{totalDistribution?.lote}}</th>
                <th colspan="1">{{totalDistribution?.kitDescriptionS}}</th>
                <th colspan="1">CANTIDAD</th>
                <th colspan="1" rowspan="2"></th>
                <th colspan="1" rowspan="2">CJ</th>
                <th colspan="1" rowspan="2">CY</th>
              </tr>
              <tr>
                <th colspan="1">{{totalDistribution?.kitDescriptionE}}</th>
                <th colspan="1">QTY</th>
              </tr>

            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <tr>
                <td colspan="1" rowspan="2">{{rowData.numParte}}</td>
                <td colspan="1">{{rowData.container}}</td>
                <td colspan="1" rowspan="2">{{rowData.quantityMM}}</td>
                <td colspan="1" rowspan="2">{{rowData.um}}</td>
                <td colspan="1" rowspan="2">{{rowData.pj === 0 ? 'NA': rowData.pj }}</td>
                <td colspan="1" rowspan="2">{{rowData.py === 0 ? 'NA': rowData.py }}</td>
              </tr>
              <tr>
                <td colspan="1">{{rowData.containerEnglish}}</td>

              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="col-1"></div>
        <div class="col-3">
          <p-table [value]="pictures" [resizableColumns]="true" [reorderableColumns]="true">
            <ng-template pTemplate="header" class="bg-table">
              <tr>
                <th [ngClass]="{'red': languageActual !== 'true'}">Foto</th>
              </tr>
              <tr>
                <th [ngClass]="{'red': languageActual === 'true'}">Picture</th>
              </tr>

            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <div class="mb-2 center">
                <img [src]="headers.kitPhoto" alt="jarra" style="height: 300px;">
              </div>
            </ng-template>
          </p-table>
        </div>
      </div>

    </div>
  </div>
</block-ui>
