import { TsuService } from 'src/app/Services/tsu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { MasterService } from 'src/app/Services/master.service';
import { MaterialsService } from 'src/app/Services/materials.service';
import { CatMasterComponent } from '../../cat-master/cat-master.component';
import { LoginService } from 'src/app/Services/Login.service';
import { LanguageService } from 'src/app/Services/language.service';
import { interval, Subscription } from 'rxjs';


declare var $;
@Component({
  selector: 'app-edit-tsu',
  templateUrl: './edit-tsu.component.html',
  styleUrls: ['./edit-tsu.component.css']
})
export class EditTsuComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  materials: any;
  descriptionEnglish: any;

  constructor(private tsuService: TsuService, private toastr: ToastrService,
    private route: ActivatedRoute, private router: Router,
    private languagueService: LanguageService,
    private permissionsService: PermissionsService, private MasterService: MasterService,
    private materialService: MaterialsService, private CatMasterComponent: CatMasterComponent,
    private loginService: LoginService
  ) {
    this.language = languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.tsu;
    this.subscription.add(this.languagueService.changeLanguage.subscribe(
      isEnglish => {
        this.language = languagueService.getLanguage();
        this.languageGlobal = this.language.global;
        this.languageModule = this.language.modules.tsu;
        this.getVariables();
        this.generateColumns();

      }
    ));

    this.subscription.add(this.route.queryParams.subscribe(data => {
      this.materials = JSON.parse(data.materials);
      this.nameQuery = data.name
      this.commentaryQuery = data.commentary
      this.classeQuery = data.classe;
      this.idUserQuery = Number(data.idUser);
      this.path64Query = (data.path === undefined) ? null : data.path
      this.path64SchemeQuery = (data.pathScheme === undefined) ? null : data.pathScheme
      this.idTSU = Number(data.idTSU);
      //this.descriptionEnglish = data.descriptionEnglish;
    }));


  }

  @BlockUI() blockUI: NgBlockUI;
  // Variables permisos --------------
  public canCreate;
  public canRead;
  public canUpdate;
  public canDelete;
  //----------------------------------

  public idUser: number;

  //Variables Idioma
  public varName: string
  public varObservations: string
  public varLanguage: string
  public varSessionExpired: string
  public varSuccessful: string
  public varWarning: string
  public varKitCreated: string
  public varTryAgain: string
  public varConnectionError: string
  public varLoading: string
  public varLocalStorage: string
  public varStatus: string
  public varAdd: string
  public varEdit: string
  public varDelete: string
  public varYes: string
  public varClose: string
  public varGlobalFilter: string
  public varSave: string
  public varClass: string
  public varCommentary: string
  public varTitleMaster: string
  public varMaterials: string
  public filteredBrands: any[];
  public dataset: any[]
  public image: any
  public varQuantity: string
  public varPartNumber: string
  public varShortDescription: string
  public varDescriptionEnglish: string
  public filterArray: any[];
  public valueId;
  public arrayTable: any[]
  public quantity: number = 1
  public observation: string = ''
  public ocultar: boolean = true;
  public cols: any[];
  public kitTable: any;
  public arrayPartNumberTable: any[]
  public arrayDataDrop
  public varPhoto: string
  public varPicture: string
  public dataMaster
  public varAddKit: string
  public contId: any
  public contIdEdit: any
  public varUploadFile: any
  public expanded: any
  public MaterialsInfo: any[]
  public AddMaterial: any[]
  public nameQuery: string
  public commentaryQuery: string
  public classeQuery: string
  public idUserQuery: number;
  public path64Query: string
  public path64SchemeQuery: string
  public datasetEdit: any[]
  public imageEdit: any
  public varQuantityEdit: string
  public varPartNumberEdit: string
  public varShortDescriptionEdit: string
  public varDescriptionEnglishEdit: string
  public varNameEdit: string
  public varObservationEdit: string
  public varSupplyTypeEdit: string
  public varFamilyDescriptionEdit: string
  public filterArrayEdit: any[];
  public selectedCar1Edit: ArrayTableEdit[] = [];
  public materialCardEdit: ArrayTableEdit[] = [];
  public valueIdEdit;
  public arrayTableEdit: any[]
  public quantityEdit: number
  public ocultarEdit: boolean = true;
  public colsEdit: any[];
  public kitTableEdit: any;
  public arrayPartNumberTableEdit: any[]
  public arrayDataDropEdit
  public varLotEdit: string
  public varDescriptionEdit: string
  public varContainerEdit: string
  public varLocalStationEdit: string
  public varInternationalStationEdit: string
  public varPictureEdit: string
  public dataMasterEdit
  public varAddKitEdit: string

  //TablaEditMaster

  public varMaterialsEdit: string
  public availableMaterialsEdit: MaterialEdit[];
  public selectedMaterialsEdit: MaterialEdit[];
  public draggedMaterialEdit: MaterialEdit;
  public filteredBrandsEdit: any[];
  public varSupplyingEdit: string
  public MaterialsInfoEdit: any[]
  public AddMaterialEdit: any[]
  public editKit: any[]
  public idMaster: string
  public arrayKit: Kit[]
  public idTSU: number

  // Varibales lenguaje
  public language;
  public languageGlobal;
  public languageModule;
  materialsSave: any;

  //Observable para intervalo de tiempo
  intervalSubscription: Subscription;
  source = interval(1000);

  generateColumns() {
    this.cols = [
      { field: 'id', header: 'No.' },
      { field: 'name', header: this.varNameEdit },
      { field: 'descriptionEnglish', header: this.varDescriptionEnglishEdit },
      { field: 'quantity', header: this.varQuantityEdit },
      { field: 'partNumber', header: this.varPartNumberEdit },
      { field: 'observation', header: this.varObservationEdit }

    ];
  }
  ngOnInit() {
    this.varLocalStorage = localStorage.getItem('language')
    this.valueLanguge();
    let personal = this.loginService.getUser();
    this.idUser = parseInt(personal.idUser.toString())
    this.getVariables()
    this.getDataMaterialsEdit();
    this.selectedMaterialsEdit = [];
  }

  valueLanguge() {
    if (localStorage.getItem('language') === 'true') {
      this.varLanguage = 'Ingles'
    } else if (localStorage.getItem('language') === 'false') {
      this.varLanguage = 'Español'
    }
  }

  getVariables() {
    this.varSessionExpired = this.languageGlobal.sessionExpired;
    this.varSuccessful = this.languageGlobal.successful;
    this.varWarning = this.languageGlobal.warning;
    this.varKitCreated = this.languageModule.kitCreated;
    this.varTryAgain = this.languageGlobal.tryAgain;
    this.varConnectionError = this.languageGlobal.connectionError;
    this.varLoading = this.languageGlobal.loading;
    this.varStatus = this.languageGlobal.status;
    this.varAdd = this.languageGlobal.add;
    this.varDelete = this.languageGlobal.delete;
    this.varEdit = this.languageGlobal.edit;
    this.varYes = this.languageGlobal.yes;
    this.varClose = this.languageGlobal.close;
    this.varGlobalFilter = this.languageGlobal.globalFilter;
    this.varSave = this.languageGlobal.save;
    this.varQuantity = this.languageModule.quantity;
    this.varPartNumber = this.languageModule.partNumber;
    this.varShortDescription = this.languageGlobal.shortDescription;
    this.varDescriptionEnglish = this.languageGlobal.descriptionEnglish;
    this.varMaterials = this.languageModule.materials;
    this.varName = this.languageGlobal.name;
    this.varObservations = this.languageModule.observations;
    this.varPicture = this.languageGlobal.picture;
    this.varAddKit = this.languageModule.addKit;
    this.varClass = this.languageGlobal.class;
    this.varCommentary = this.languageModule.commentary;
    this.varPhoto = this.languageModule.photo;
    this.varMaterialsEdit = this.languageModule.materials;
  }


  getDataMaterialsEdit() {
    this.arrayTableEdit = []
    var cont = 0;
    this.blockUI.start(this.varLoading);
    this.availableMaterialsEdit = []

    let arrayMaterialsLength = this.materials.length
    for (let i = 0; i < arrayMaterialsLength; i++) {
      cont++
      this.arrayTableEdit.push({
        id: cont,
        name: this.materials[i].shortDescription,
        descriptionEnglish: this.materials[i].descriptionEnglish,
        quantity: this.materials[i].quantity,
        partNumber: this.materials[i].partNumber,
        observation: this.materials[i].observation,
        idCatMaterials: this.materials[i].idCatMaterials,
        shortDescription: this.materials[i].shortDescription
      })
    }




    this.subscription.add(this.materialService.getDataall().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();
            this.imageEdit = []
            let materialsLength = data.materials.length
            this.availableMaterialsEdit = data.materials
            this.materialsSave = data.materials
            // for (let i = 0; i < materialsLength; i++) {
            //   this.imageEdit.push(data.materials[i].imege)
            //   this.availableMaterialsEdit = data.materials
            //   this.materialsSave = data.materials
            // }
          }
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }


  addQuantity(valueQuantity: any, idQuantity: any) {
    this.quantity = valueQuantity
  }
  closeModal(id) {
    $(id).modal('hide')
  }

  openModal(id) {
    $(id).modal('show')
  }


  drop(algo: any) {

    var cont = 0;
    this.quantityEdit = 1
    if (this.draggedMaterialEdit) {
      let draggedMaterialIndexEdit = this.findIndex(this.draggedMaterialEdit);
      this.selectedMaterialsEdit = [...this.selectedMaterialsEdit, this.draggedMaterialEdit];
      this.availableMaterialsEdit = this.availableMaterialsEdit.filter((val, i) => i != draggedMaterialIndexEdit);

      let contador = this.selectedMaterialsEdit.length

      let contArray = this.arrayTableEdit.length
      cont = contArray
      this.kitTableEdit = []
      for (let i = 0; i < contador; i++) {
        cont++;

        this.arrayTableEdit.push({
          id: cont,
          idCatMaterials: this.selectedMaterialsEdit[i].idCatMaterials,
          partNumber: this.selectedMaterialsEdit[i].partNumber,
          name: this.selectedMaterialsEdit[i].shortDescription,
          descriptionEnglish: this.selectedMaterialsEdit[i].descriptionEnglish,
          observation: this.observation,
          quantity: this.quantityEdit,
          shortDescription: this.selectedMaterialsEdit[i].shortDescription
        })
      }

      this.cols = [
        { field: 'id', header: 'No.' },
        { field: 'name', header: this.varNameEdit },
        { field: 'descriptionEnglish', header: this.varDescriptionEnglishEdit },
        { field: 'quantity', header: this.varQuantityEdit },
        { field: 'partNumber', header: this.varPartNumberEdit },
        { field: 'observation', header: this.varObservationEdit }

      ];

    }
    let arrayTableLength = this.arrayTableEdit.length
    for (let j = 0; j < arrayTableLength; j++) {
      this.AddMaterialEdit = []
      this.AddMaterialEdit.push({
        idMaterials: this.arrayTableEdit[j].idMaterials,
        partNumber: this.arrayTableEdit[j].partNumber,
        shortDescription: this.arrayTableEdit[j].name,
        descriptionEnglish: this.arrayTableEdit[j].descriptionEnglish,
        observation: this.arrayTableEdit[j].observation,
        quantity: this.quantityEdit
      })

    }
    this.selectedMaterialsEdit = []
  }


  dragEnd(algo: any) {
    this.draggedMaterialEdit = null;
  }
  dragStart(event, materialEdit: MaterialEdit) {
    this.draggedMaterialEdit = materialEdit;
  }

  deleteMaterialEdit() {
    if (this.selectedCar1Edit.length > 0) {
      this.selectedCar1Edit.forEach(element => {
        var index: number = this.arrayTableEdit.indexOf(this.selectedCar1Edit.find(x => x.id == element.id));
        this.arrayTableEdit.splice(index, 1);
      });

      let selectedCar1Length = this.selectedCar1Edit.length
      this.arrayPartNumberTableEdit = []
      for (let i = 0; i < selectedCar1Length; i++) {
        this.arrayPartNumberTableEdit.push(this.selectedCar1Edit[i].partNumber)
      }
      let arrayTableLength = this.selectedMaterialsEdit.length
      let arrayPartNumberTableLength = this.arrayPartNumberTableEdit.length
      for (let t = 0; t < arrayTableLength; t++) {
        let numPartMaterial = this.selectedMaterialsEdit[t].partNumber
        for (let l = 0; l < arrayPartNumberTableLength; l++) {
          let numPartTable = this.arrayPartNumberTableEdit[l]
          if (numPartMaterial.trim() === numPartTable.trim()) {
            this.availableMaterialsEdit.push(this.selectedMaterialsEdit[t])
          }
        }
      }
    }
    this.selectedCar1Edit = []
  }
  findIndex(materialEdit: MaterialEdit) {
    let index = -1;
    for (let i = 0; i < this.availableMaterialsEdit.length; i++) {
      if (materialEdit.partNumber === this.availableMaterialsEdit[i].partNumber) {
        index = i;
        break;
      }
    }
    return index;
  }



  sendCompleteKitEdit(tableMaterial) {
    this.blockUI.start(this.varLoading);
    this.MaterialsInfo = []


    let send = {
      name: this.nameQuery,
      commentary: this.commentaryQuery,
      clase: this.classeQuery,
      path: this.path64Query,
      path64Scheme: this.path64SchemeQuery,
      idUser: this.idUser,
      status: 1,
      idTSU: this.idTSU,
      materials: tableMaterial,
    }

    this.subscription.add(this.tsuService.update(send).subscribe((data: any) => {
       //Se pone un intervalo de 10s para redirigir a la vista principal
       this.intervalSubscription = this.source.subscribe(val => {
        this.blockUI.stop();
        this.router.navigate(['/catTsu']);
        window.location.reload();
      });

      /*this.router.navigate(['/catTsu'], { queryParams: {message: this.nameQuery, type: 'edit'}}).then(() => {
        window.location.reload();
      });*/
    }, error => {
      this.blockUI.stop();
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }));

  }
  filterMaterials(input) {

    let materialsTemp = this.materialsSave.filter(m => m.partNumber.includes(input.toUpperCase()));
    this.availableMaterialsEdit = [];
    this.availableMaterialsEdit = materialsTemp;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.intervalSubscription.unsubscribe();
  }
}
export interface MaterialEdit {
  id?;
  quantity?;
  partNumber?;
  shortDescription?;
  descriptionEnglish?;
  // imege?;
  idCatMaterials?;
  observation

}

export interface ArrayTableEdit {
  id?;
  quantity?;
  partNumber?;
  shortDescription?;
  descriptionEnglish?;
  imege?;
  idCatMaterials?;
  observation
}

export interface Kit {
  id?;
  idCatMaterials?;
  partNumber?;
  shortDescription?;
  descriptionEnglish?;
  quantity?;
}

export interface Material {
  quantity?;
  partNumber?;
  shortDescription?;
  descriptionEnglish?;
  typeofSupply?;
  familydescription?;
  imege?;
  idCatMaterials?;

}
