<div mat-dialog-content class="div-mat-dialog" *ngIf="isLogued()">
  <div class="d-flex align-items-start div-content">
    <div class="nav flex-column nav-pills me-3 div-section" id="v-pills-tab" role="tablist" aria-orientation="vertical">
      <h1 class="module-title">{{languageModule.moduleTitle}}</h1>
      <button class="nav-link active button-settings" id="general-tab" data-bs-toggle="pill"
        data-bs-target="#general-content" type="button" role="tab" aria-controls="general-content" aria-selected="true">
        <mat-icon class="icon-tab">settings</mat-icon>{{languageModule.sectionTitleUsr}}
      </button>
    </div>
    <div class="tab-content div-tab-content" id="v-pills-tabContent">
      <!--Panel de la informacion del usuario-->
      <div class="tab-pane fade show active tab-pane-config" id="general-content" role="tabpanel" aria-labelledby="general-tab">
        <div>
          <h2 class="title-tab">
            <span>{{languageModule.sectionTitleUsr}}</span>
            <span mat-dialog-close class="icon-close">
              <mat-icon>close</mat-icon>
            </span>
          </h2>
        </div>
        <hr class="hr-section"/>
        <form class="example-form form-section">
          <div class="col-md-6">
            <mat-form-field appearance="outline" style="width: 95%;">
              <mat-label>{{languageModule.name}}</mat-label>
              <input matInput disabled value="{{user.name}}">
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>{{languageModule.surnames}}</mat-label>
              <input matInput disabled value="{{user.lastName}}">
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>{{languageModule.email}}</mat-label>
              <input matInput disabled value="{{user.email}}">
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" style="width: 95%;">
              <mat-label>{{languageModule.telephone}}</mat-label>
              <input matInput disabled value="{{isTelephoneValid?user.telephone: ''}}">
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>{{languageModule.extension}}</mat-label>
              <input matInput disabled value="{{isExtValid?user.extension:''}}">
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" style="width: 95%;">
              <mat-label>{{languageModule.profile}}</mat-label>
              <input matInput disabled value="{{user.nameProfile}}">
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>{{languageModule.area}}</mat-label>
              <input matInput disabled value="{{isEnglish=='true'?user.nameAreaEnglish:user.nameArea}}">
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>{{languageModule.charge}}</mat-label>
              <input matInput disabled value="{{isEnglish=='true'?user.jobEnglish:user.jobSpanish}}">
            </mat-form-field>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
