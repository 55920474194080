import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Component, OnInit, ViewChild } from '@angular/core';

import { AccountSettingsComponent } from '../account-settings/account-settings.component';
import { AuthenticationService } from 'src/app/security/auth0/authentication.service';
import { LanguageService } from 'src/app/Services/language.service';
import { LoginService } from 'src/app/Services/Login.service';
import { MatDialog } from '@angular/material/dialog';
import { NoticeService } from '../../../Services/notice.service';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  providers: [AccountSettingsComponent],
  selector: 'app-navbar-logued',
  templateUrl: './navbar-logued.component.html',
  styleUrls: ['./navbar-logued.component.css']
})

export class NavbarLoguedComponent implements OnInit {
  isChecked = localStorage.getItem('language') === 'true' ? true : false;
  version = environment.version;

  isInfo = false;
  isSide = false;
  @BlockUI() blockUI: NgBlockUI;
  public badgeCount: number;
  dateNow: Date = new Date();
  public storedNames: any;
  public name: string;
  public logued: boolean = false;
  public flagMenu: string;

  public varLocalStorage: string
  public varDesign: string
  public varCatalogs: string
  public varCycles: string
  public varServiceCode: string
  public varPotential: string
  public varBoardingTable: string
  public varGeneralConsultation: string
  public varReports: string
  public varPreselect: string
  public varDigitalMain: string
  public varMain: string
  public varDigitalMenu: string

  public varByStation: string
  public varPlanning: string
  public varFleetRelationship: string
  public varMaster: string
  public varMatrix: string
  public varTSU: string
  public varGraphics: string
  public varAccommodations: string
  public varBarList: string
  public varServiceGuide: string
  public varServiceGuideSisea: string
  public varMaterials: string
  public varIrregularities: string
  public varStatistics: string
  public varNotices: string
  public varTracing: string
  public varShipping: string
  public varDirectory: string
  public varEmployees: string
  public varProvider: string
  public varConfiguration: string
  public varStations: string
  public varIrregularityCriteria: string
  public varRegions: string
  public varUsers: string
  public varWelcome: string
  public varStart: string
  public varSignOff: string
  public varCancel: string
  public varConfirm: string
  public varQuestioncloseSession: string
  public varMenu: string
  public cont: number = 0
  public idUser: number;
  public varLanguage: string
  public varSessionExpired: string
  public varSuccessful: string
  public varWarning: string
  public varTryAgain: string
  public varConnectionError: string
  public varLoading: string
  public stringList = []
  public varFoods: string
  public varManuals: string
  public varProducts: string
  public varProfile: string
  public varPreselectEmails: string
  public varFlyings: string
  public varStationsFlying: string
  public varPreselectionCodes: string
  public varSpecialMealCodes: string

  //varLanguage
  public language;
  public languageGlobal;
  public languageModule;
  public varEquipment: string;
  public varRestockCap: string;

  // VARIABLES PERMISOS

  canDesign: boolean;
  canCatalogsDesign: boolean;
  canCatalogPreselectDesign: boolean;
  canCycles: boolean;
  canServiceCode: boolean;
  canPotential: boolean;
  canBoardingTable: boolean;
  canGeneralConsultation: boolean;
  canReports: boolean;
  canByStation: boolean;
  canPlanning: boolean;
  canFleetRelationship: boolean;
  canMaster: boolean;
  canMatrix: boolean;
  canTSU: boolean;
  canGraphics: boolean;
  canAccommodations: boolean;
  canBarList: boolean;
  canServiceGuide: boolean;
  canServiceGuideSisea: boolean;
  canManuals: boolean;
  canProducts: boolean
  canEquipment: boolean;
  canRestockCap: boolean;
  canIrregularities: boolean;
  canStatistics: boolean;
  canReportsIrregularities: boolean
  canNotices: boolean;
  canTracing: boolean;
  canShipping: boolean;
  canDirectory: boolean;
  canEmployees: boolean;
  canProvider: boolean;
  canConfiguration: boolean;
  canCatalogsConfiguration: boolean;
  CanRegions: boolean;
  CanStations: boolean;
  CanUsers: boolean;
  canProfiles: boolean;
  canFoods: boolean;
  canMaterials: boolean;
  CanIrregularityCriteria: boolean;
  canPreselectEmail: boolean;
  canFlyings: boolean;
  canStattionsFlying: boolean;
  canPreselectCodes: boolean;
  canSpecialMealCodes: boolean;
  canDigitalmain: boolean;
  canPreselectMain: boolean;
  canDigitalMenu: boolean;

  image = '../../../../assets//images//compress/AmxBrand.png'
  defaultImage = '../../../../assets//images//compress/AmxBrand.png'
  defaultNumerouno = '/assets/images/compress/numerouno.png'
  numerouno = '/assets/images/numerouno.png'

  constructor(private router: Router,
    private noticeService: NoticeService, private toastr: ToastrService,
    private permissionService: PermissionsService,
    private loginService: LoginService,
    private languagueService: LanguageService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {

    this.language = languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.navBar;

    this.languagueService.changeLanguage.subscribe(
      isEnglish => {
        this.language = languagueService.getLanguage();
        this.languageGlobal = this.language.global;
        this.languageModule = this.language.modules.navBar;
        this.getVariables();
      }
    )

    // Get Permissions

    this.canDesign = permissionService.canMenu(1);
    this.canCatalogsDesign = permissionService.CanSubMenu(1, 2, 'R');
    this.canCatalogPreselectDesign = permissionService.CanSubMenu(1, 2, 'R');
    this.canCycles = permissionService.canView(1, 2, 1, 'R');
    this.canServiceCode = permissionService.canView(1, 2, 2, 'R');
    this.canPotential = permissionService.canView(1, 2, 3, 'R');
    this.canBoardingTable = permissionService.canView(1, 2, 4, 'R');
    this.canReports = permissionService.CanSubMenu(1, 7, 'R');
    this.canByStation = permissionService.canView(1, 7, 9, 'R');
    this.canGeneralConsultation = permissionService.CanSubMenu(1, 29, 'R');
    this.canPlanning = permissionService.canMenu(2);
    this.canFleetRelationship = permissionService.CanSubMenu(2, 9, 'R');
    this.canMaster = permissionService.CanSubMenu(2, 11, 'R');
    this.canMatrix = permissionService.CanSubMenu(2, 24, 'R');
    this.canTSU = permissionService.CanSubMenu(2, 12, 'R');
    this.canGraphics = permissionService.CanSubMenu(2, 13, 'R');
    this.canAccommodations = permissionService.CanSubMenu(2, 14, 'R');
    this.canBarList = permissionService.CanSubMenu(2, 15, 'R');
    this.canServiceGuide = permissionService.CanSubMenu(2, 17, 'R');
    this.canServiceGuideSisea = permissionService.CanSubMenu(2, 17, 'R');
    this.canMaterials = permissionService.CanSubMenu(2, 30, 'R');
    this.canIrregularities = permissionService.canMenu(3);
    this.canStatistics = permissionService.CanSubMenu(3, 18, 'R');
    this.canReportsIrregularities = permissionService.CanSubMenu(3, 19, 'R');
    this.canNotices = permissionService.canMenu(4);
    this.canTracing = permissionService.CanSubMenu(4, 20, 'R');
    this.canShipping = permissionService.CanSubMenu(4, 25, 'R');
    this.canDirectory = permissionService.canMenu(5);
    this.canEmployees = permissionService.CanSubMenu(5, 21, 'R');
    this.canProvider = permissionService.CanSubMenu(5, 23, 'R');
    this.canConfiguration = permissionService.canMenu(6);
    this.canCatalogsConfiguration = permissionService.CanSubMenu(6, 26, 'R');
    this.CanRegions = permissionService.canView(6, 26, 11, 'R');
    this.CanStations = permissionService.canView(6, 26, 12, 'R');
    this.CanUsers = permissionService.CanSubMenu(6, 27, 'R');
    this.canProfiles = permissionService.CanSubMenu(6, 28, 'R');
    this.canManuals = permissionService.CanSubMenu(2, 31, 'R');

    this.canFoods = permissionService.CanSubMenu(1, 32, 'R');
    this.canDigitalMenu = permissionService.CanSubMenu(1, 32, 'R');
    this.canProducts = permissionService.CanSubMenu(2, 33, 'R');
    this.canEquipment = permissionService.CanSubMenu(2, 34, 'R');
    this.canRestockCap = permissionService.CanSubMenu(2, 35, 'R');
    this.CanIrregularityCriteria = permissionService.canView(6, 26, 12, 'R');

    this.canPreselectMain = permissionService.CanSubMenu(1, 36, 'R');
    this.canPreselectEmail = permissionService.canView(1, 36, 14, 'R');
    this.canFlyings = permissionService.canView(1, 36, 15, 'R');
    this.canStattionsFlying = permissionService.canView(1, 36, 16, 'R');
    this.canPreselectCodes = permissionService.canView(1, 36, 17, 'R');
    this.canSpecialMealCodes = permissionService.canView(1, 36, 18, 'R');
    this.canDigitalmain = permissionService.CanSubMenu(1, 37, 'R');
  }


  ngOnInit() {
    setTimeout(() => {
      this.isInfo = false;
      this.isSide = false;
    });
    this.varLocalStorage = localStorage.getItem('language')
    this.getVariables()
    if (localStorage.getItem('logued') == 'true') {
      let uno = localStorage.getItem("token")
      let personal = this.loginService.getUser();
      this.idUser = parseInt(personal.idUser.toString())
      this.notifications();
      this.storedNames = this.loginService.getUser();
      this.name = this.storedNames.name;
      setTimeout(() => {
        this.logued = true
      }, 0);

    } else {
      setTimeout(() => {
        this.logued = false
      }, 0);

    }
    localStorage.setItem('chooseLanguage', 'false');
  }

  isLogued() {
    if (localStorage.getItem('logued') === 'true') {
      return true;
    } else {
      return false
    }
  }

  notifications() {

    this.noticeService.getUnreadNotice(this.idUser).subscribe((data: any) => {
      let status = 0;
      status = data.ClassInfo.Status
      if (status == 200) {
        this.blockUI.stop();
        this.badgeCount = data.unreadNumber;
      }

    }, error => {
      this.blockUI.stop();
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    });
  }

  getVariables() {
    this.varDesign = this.languageModule.design;
    this.varCatalogs = this.languageModule.catalogs;
    this.varCycles = this.languageModule.cycles;
    this.varServiceCode = this.languageModule.serviceCode;
    this.varPotential = this.languageModule.potential;
    this.varBoardingTable = this.languageModule.boardingTable;
    this.varGeneralConsultation = this.languageModule.generalConsultation;
    this.varReports = this.languageModule.reports;
    this.varByStation = this.languageModule.byStation;
    this.varPlanning = this.languageModule.planning;
    this.varFleetRelationship = this.languageModule.fleetRelationship;
    this.varMaster = this.languageModule.master;
    this.varMatrix = this.languageModule.matrix;
    this.varTSU = this.languageModule.TSU;
    this.varGraphics = this.languageModule.graphics;
    this.varAccommodations = this.languageModule.accommodations;
    this.varBarList = this.languageModule.barList;
    this.varServiceGuide = this.languageModule.serviceGuide;
    this.varServiceGuideSisea = this.languageModule.serviceGuideSisea;
    this.varMaterials = this.languageModule.materials;
    this.varIrregularities = this.languageModule.irregularities;
    this.varStatistics = this.languageModule.statistics;
    this.varNotices = this.languageModule.notice;
    this.varTracing = this.languageModule.tracing;
    this.varShipping = this.languageModule.shipping;
    this.varDirectory = this.languageModule.directory;
    this.varEmployees = this.languageModule.employees;
    this.varProvider = this.languageModule.provider;
    this.varConfiguration = this.languageModule.configuration;
    this.varRegions = this.languageModule.regions;
    this.varStations = this.languageModule.stations;
    this.varIrregularityCriteria = this.languageModule.irregularityCriteria
    this.varUsers = this.languageModule.users;
    this.varWelcome = this.languageModule.welcome;
    this.varStart = this.languageModule.start;
    this.varSignOff = this.languageModule.signOff;
    this.varConfirm = this.languageGlobal.confirm;
    this.varQuestioncloseSession = this.languageModule.questioncloseSession;
    this.varCancel = this.languageGlobal.cancel;
    this.varSessionExpired = this.languageGlobal.sessionExpired;
    this.varSuccessful = this.languageGlobal.successful;
    this.varWarning = this.languageGlobal.warning;
    this.varTryAgain = this.languageGlobal.tryAgain;
    this.varConnectionError = this.languageGlobal.connectionError;
    this.varLoading = this.languageGlobal.loading;
    this.varNotices = this.languageModule.notices;
    this.varFoods = this.languageModule.foods;
    this.varDigitalMenu = this.languageModule.digitalMenu;
    this.varManuals = this.languageModule.manuals;
    this.varProducts = this.languageModule.products;
    this.varProfile = this.language.modules.profile.profile;
    this.varEquipment = this.languageModule.equipment;
    this.varRestockCap = this.languageModule.restockCap;

    this.varMain = this.languageModule.main;
    this.varPreselect = this.languageModule.preselect;
    this.varPreselectEmails = this.languageModule.preselectemails;
    this.varFlyings = this.languageModule.flyings;
    this.varStationsFlying = this.languageModule.stationsflying;
    this.varPreselectionCodes = this.languageModule.preselectioncodes;
    this.varSpecialMealCodes = this.languageModule.specialmealcodes;
    this.varDigitalMain = this.languageModule.digitalmain;
  }

  comunicMenu: boolean = true;
  catalogsMenu: boolean = true;
  reports: boolean = true;
  catalogsMenuRegion: boolean = true;

  stateMenuDesign(flagMenu) {
    if (flagMenu === 'comunicMenu') {
      this.catalogsMenu = false;
      this.reports = false;
      this.comunicMenu = true;
    } else if (flagMenu === 'catalogsMenu') {
      this.comunicMenu = false;
      this.reports = false;
      this.catalogsMenu = true;
    } else if (flagMenu === 'reports') {
      this.comunicMenu = false;
      this.reports = true;
      this.catalogsMenu = false;
    } else if (flagMenu === 'catalogsMenuRegion') {
      this.catalogsMenuRegion = true;
    }
  }

  clearMenu() {
    this.catalogsMenu = false;
    this.reports = false;
    this.catalogsMenuRegion = false;
  }

  chooseLanguage() {
    Swal.fire({
      title: 'Idioma/language',
      text: "Elija un idioma/choose a language",
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#0D0956',
      cancelButtonColor: '#A83B24',
      cancelButtonText: 'Español/Spanish',
      confirmButtonText: 'Inglés/English',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        localStorage.setItem('language', 'true');
      } else {
        localStorage.setItem('language', 'false');
        localStorage.setItem('chooseLanguage', 'true');
      }
      window.location.reload();
    });
  }

  logout() {
    Swal.fire({
      title: this.varConfirm,
      text: this.varQuestioncloseSession,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.varCancel,
      confirmButtonText: this.varConfirm,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        sessionStorage.removeItem('responsible')
        sessionStorage.removeItem('folio')
        this.router.navigate(['/login']);
      }
    });
    $('#menuSide').toggleClass('display-block')
  }

  showInfo() {
    this.isInfo = !this.isInfo;
  }

  showSide() {
    $('#menuSide').toggleClass('display-block')
  }

  checkValue(event: any) {
    if (!event) {
      this.language = this.languagueService.getLanguage();
      this.languageGlobal = this.language.global;
      this.languageModule = this.language.modules.navBar;
      localStorage.setItem('language', 'true');
      this.languagueService.changeLanguageFunction(true);
    } else {
      localStorage.setItem('language', 'false');
      localStorage.setItem('chooseLanguage', 'true');
      this.languagueService.changeLanguageFunction(false);
    }
  }

  openDialog() {
    this.dialog.open(AccountSettingsComponent, {
      height: '90%',
      width: '70%'
    });
  }
}
