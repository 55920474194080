import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/Services/Login.service';
import { CatUsersService } from 'src/app/Services/cat-users.service';
import { LanguageService } from 'src/app/Services/language.service';

declare var $;

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {

  private subscription: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;
  public user: any;
  public isTelephoneValid = false;
  public isExtValid = false;
  public isEnglish: string;
  public languageModule: any;
  public languageGlobal: any;
  public language: any;

  public showPsw = false;
  public showConfirmPsw = false;

  formChangePass: FormGroup = new FormGroup({
    email: new FormControl(''),
    pasword: new FormControl(''),
    confirmPassword: new FormControl(''),
  });

  constructor(private loginService: LoginService, private formBuilder: FormBuilder, private languagueService: LanguageService,
    private catUsersService: CatUsersService, private toastr: ToastrService, private router: Router, public dialog: MatDialog) {
      this.language = this.languagueService.getLanguage();
      this.languageGlobal = this.language.global;
      this.languageModule = this.language.modules.usrConfig;
      this.languagueService.changeLanguage.subscribe(
        English => {
          this.language = this.languagueService.getLanguage();
          this.languageGlobal = this.language.global;
          this.languageModule = this.language.modules.usrConfig;
        }
      )
  }

  ngOnInit(): void {
    this.user = this.loginService.getUser();
    this.validateTelephone();
    this.validateExt();
    this.isEnglish = localStorage.getItem('language');
    this.formChangePass = this.formBuilder.group(
      {
        email: [{ value: this.user.email, disabled: true }, [Validators.required, Validators.email]],
        pasword: [
          '',
          [
            Validators.required,
            Validators.minLength(14),
            Validators.maxLength(20)
          ]
        ],
        confirmPassword: ['', Validators.required]
      },
      {
        validators: [this.match('pasword', 'confirmPassword')]
      }
    );
  }

  isLogued() {
    if (localStorage.getItem('logued') === 'true') {
      return true;
    } else {
      this.dialog.closeAll();
      return false
    }
  }

  validateTelephone() {
    let regex = /^((0{1,15})|(-{1,15}))$/;
    if (!regex.test(this.user.telephone)) {
      this.isTelephoneValid = true;
    }
  }

  validateExt() {
    let ext = this.user.extension.trim();
    let regex = /^((0{1,10})|(-{1,10}))$/;
    if (!regex.test(ext)) {
      this.isExtValid = true;
    }
  }

  getErrorMessage(key: string) {
    if (this.formChangePass.controls[key].errors != null) {
      if (this.formChangePass.controls[key].errors['required']) {
        return this.languageModule.msgRequiredField;
      } else if (this.formChangePass.controls[key].errors['minlength']) {
        return this.languageModule.msgMinLength;
      } else if (this.formChangePass.controls[key].errors['maxlength']) {
        return this.languageModule.msgMaxLength;
      } else if (this.formChangePass.controls[key].errors['matching']) {
        return this.languageModule.msgMatching;
      } else if (this.formChangePass.controls[key].errors['pattern']) {
        return this.languageModule.msgPattern;
      }
    }
  }

  sendInfo(): void {
    this.blockUI.start(this.languageGlobal.loading);
    let send = {
      email: this.formChangePass.controls['email'].value,
      pasword: this.formChangePass.controls['pasword'].value
    }
    this.subscription.add(this.catUsersService.changePasswordFirst(send).subscribe((data: any) => {
      if (data.Status = 200) {
        this.blockUI.stop();
        this.toastr.success(this.languageModule.msgUpdatePass, this.languageGlobal.successful, { positionClass: 'toast-bottom-full-width', closeButton: true });
        this.onReset();
        this.dialog.closeAll();
      }
    }, error => {
      this.blockUI.stop();
      if (error.status === 406) {
        this.blockUI.stop();
        this.toastr.error(this.languageGlobal.claveInvalid, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.languageGlobal.sessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        this.router.navigate(['/login'])
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.languageGlobal.tryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.languageGlobal.connectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }

    }));
  }

  onReset(): void {
    this.formChangePass.reset();
  }

  match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);

      if (checkControl?.errors && !checkControl.errors['matching']) {
        return null;
      }

      if (control?.value !== checkControl?.value) {
        controls.get(checkControlName)?.setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }

  toggleShowPsw() {
    this.showPsw = !this.showPsw;
  }

  toggleShowConfirmPsw() {
    this.showConfirmPsw = !this.showConfirmPsw;
  }
}
