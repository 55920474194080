import { Injectable } from '@angular/core';
import { CurrentDataService } from './current-data.service';
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  jsonPermissions: Array<any>;

  constructor( private currentData: CurrentDataService ) { 
    this.jsonPermissions = this.getPermissionsStorage();
  } 

  private getPermissionsStorage() {
  /* if( localStorage.getItem('menus') ) {
      return JSON.parse( localStorage.getItem('menus') );
    }
    return  
 */
     if( this.currentData.getProfile() ) {
      return this.currentData.getProfile();
    }
    return 
  }

  canView(idMenu, idSubMenu, idSubMenuOption, permission ) {
    let menus ;
    let subMenus ;
    let menuOptions;




    if( !this.jsonPermissions ) {
      return false;
    }else {
      menus = this.jsonPermissions.find ( menu => menu.idMenu === idMenu );
    }
    
    if( menus ) {
      subMenus = menus.submenus.find( submenu => submenu.idSubMenu === idSubMenu );
    }else {
      return false;
    }

    if(subMenus) {
      menuOptions = subMenus.menuOptions.find( menuOptions => menuOptions.idSubMenuOptions === idSubMenuOption );
    }else {
      return false;
    }

    if ( menuOptions ) {
      return  menuOptions.permission.includes( permission );
    }else {
      return false;
    }
    
  }
  CanSubMenu(idMenu, idSubMenu, permission ){
    let menus ;
    let subMenus;

    this.jsonPermissions = this.getPermissionsStorage();
    if( !this.jsonPermissions ) {
      return false;
    }else {
      menus = this.jsonPermissions.find ( menu => menu.idMenu === idMenu );
    }

    if( menus ) {
      subMenus = menus.submenus.find( submenu => submenu.idSubMenu === idSubMenu );
    }else {
      return false;
    }

    if( subMenus ) {
      return  subMenus.permission.includes(permission);
    }else {
      return false;
    }
  }
  canMenu(idMenu){
    let menus ;
    if( !this.jsonPermissions ) {
      return false;
    }else {
      menus = this.jsonPermissions.find ( menu => menu.idMenu === idMenu );

      if(menus != undefined){
        if(menus.idMenu === 5){
          if(menus.submenus[0].permission === 'R'){
          return false;
          }
        }else if(menus.idMenu === 6){
          if(menus.submenus[0].permission === 'R'){
          return false;
          }
        }
      }


      
  /*     if(menus.idMenu === 5 && menus.subMenus[0].permission === 'R'){
console.log('directDeleteR')
      } */
    }

    if( !menus ) {
      return false;
    }
    return  true;
  }
}
