import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer:DomSanitizer){}
  transform(value: any, type: string): any {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
        break;
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
        break;
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
        break;
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
        break;
      default:
        break;
    }
  }

}
