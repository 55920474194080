import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { DatesPipe } from './dates.pipe';
import { FilterPipe } from '../Components/master/filter.pipe';



@NgModule({
  declarations: [DatesPipe, SafePipe],
  imports: [
    CommonModule,
  ],
  exports: [DatesPipe, SafePipe],

})
export class PipesModule { }
