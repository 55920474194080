import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Component, OnInit } from "@angular/core";

import { AuthenticationService } from "src/app/security/auth0/authentication.service";
import { LanguageService } from "src/app/Services/language.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-okta",
  templateUrl: "./login-okta.component.html",
  styleUrls: ["./login-okta.component.css"],
})
export class LoginOktaComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  language: any;
  languageGlobal: any;
  languageModule: any;

  constructor(
    private authenticationService: AuthenticationService,
    private languagueService: LanguageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.configureLanguage();

    this.blockUI.start(this.languageModule.login);

    this.authenticationService.isAuthenticated$.subscribe({
      next: (isAuthenticated) => {
        if (isAuthenticated) {
          this.router.navigate(["/login"]);
        } else {
          this.authenticationService.login();
        }
      }
    });
  }

  configureLanguage() {
    this.language = this.languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.login;

    this.languagueService.changeLanguage.subscribe((isEnglish) => {
      this.language = this.languagueService.getLanguage();
      this.languageGlobal = this.language.global;
      this.languageModule = this.language.modules.login;
    });
  }
}
