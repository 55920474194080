import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CurrentDataService } from './current-data.service';

const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class MatrizService {
  getExcel() {
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/Matriz/GetExcelMatriz', {headers});
  }

  constructor(private http: HttpClient,
    private currentData: CurrentDataService) { } 

  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Authorization':  localStorage.getItem('token') 
    }); 
    return headers;
  }
  getData(){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/Matriz/getMatriz', {headers});
  }

  add(send : any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.put(apiUrl + '/Matriz/AddMatriz', send, {headers:headers});
  }
  update(send : any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.put(apiUrl + '/Matriz/UpdateMatriz', send, {headers:headers});
  }

  getQuantity(send : any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.put(apiUrl + '/Matriz/GetQuantity', send, {headers:headers});
  }

  getMatrices(){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/Matriz/getMatriz' , {headers});
  } 
  getDetail(lote){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + `/Matriz/getMatrizDetalle?lote=${lote}` , {headers});
  }  
} 
