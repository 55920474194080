import { BehaviorSubject, Observable } from 'rxjs';

import { AuthService } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {

  public isAuthenticated$ = this.auth.isAuthenticated$;
  public error$ = this.auth.error$;
  public isLoading$ = this.auth.isLoading$;
  private isLoggedFromAuth0: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoginFromAuth0$: Observable<boolean> = this.isLoggedFromAuth0.asObservable();

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  login() {
    this.auth.loginWithRedirect({
      appState: {
        target: `/login`,
      },
    });
  }

  logout() {
    this.auth.logout();
  }

  getToken() {
    return this.http.get(environment.apiUrl + '/auth0identity').pipe(
      tap((data) => {
        console.log(data);
      }),
    );
  }

  isLogged() {
    this.isLoggedFromAuth0.next(true);
  }
}
