export const english = {
  modules: {
    cat_materials: {
      title: 'Materials Catalogues',
      modals: {
        edit: {
          title: 'Edit Material',

        },
        add: {
          title: 'Add Material',

        },
        delete: {
          title: 'Are you sure to remove the material?',

        },
        labels: {
          codeSisea: 'Is it SISEA code?',
          partNumber: 'Enter the Part Number',
          shortDescription: 'Enter the Short Description',
          descriptionEnglish: 'Enter the Description English',
          typeofSupply: 'Select a Type of Supply',
          familydescription: 'Select a Family Description',
          status: 'Mark Status',
          image: 'Select a Photo',
          weight: 'Weight',
        },
        messages: {
          imageErrorMsg: 'Invalid photo size! , (maximum size of 3MB)',
          materialAddMsg: 'Material successfully added',
          materialAddMsgAgain: 'Not added correctly, try again',
          materialEditMsg: 'The material was edited correctly ',
          materialEditMsgAgain: 'Not edited correctly, try again',
          materialDeleteQuestion: 'Are you sure to delete the material?',
          materialDeleteMsg: 'It was successfully deleted',
          materialDeleteMsgAgain: 'Not deleted successfully, try again',
        }
      },
      table: {
        labels: {
          partNumber: 'Part Number',
          shortDescription: 'Short Description',
          descriptionEnglish: 'Description English',
          typeofSupply: 'Type of Supply',
          familydescription: 'Family Description',
          status: 'Status',
          image: 'Photo',
          weight: 'Weight',
        }
      }

    },
    reportIrregularities: {
      //Titles
      titleModule: 'Irregularities Report',
      titleTab1: 'Flights',
      titleTab2: 'Irregularities Report',
      titleEdit: 'Edit Irregularities Report',
      titleAdd: 'Send Irregularities Report',
      titleDelete: 'Are you sure to remove the Irregularities Report?',
      titleResponse: 'Respond irregularity',
      titleHistoryResponse: 'History Answers',
      //Messages
      excelErrorMsg: 'Invalid excel size! , (maximum size of 3MB)',
      AddMsg: 'Irregularities Report successfully sending',
      AddMsgAgain: 'Not added correctly, try again',
      EditMsg: 'The Irregularities Report was edited correctly ',
      ResponseMsg: 'The irregularity report was answered correctly',
      EditMsgAgain: 'Not edited correctly, try again',
      ResponseMsgAgain: 'Not answered correctly, try again',
      DeleteQuestion: 'Are you sure to delete the Irregularities Report?',
      DeleteMsg: 'It was successfully deleted',
      DeleteMsgAgain: 'Not deleted successfully, try again',
      CloseQuestion: 'Are you sure to close the Irregularities Report?',
      CloseMsg: 'It was successfully closed',
      CloseMsgAgain: 'Not closed successfully, try again',
      closeQuestion: 'Are you sure to close the Irregularities Report?',
      WarnDateMsg: 'Select the ',
      ErrorDateValidateMsg: 'The start date cannot be minor than the date end',
      ErrorCampos: 'Warning: The following fields are required ',
      labelAccount: 'Attached files',
      closeIrregularity: 'close irregularity',
      //Labels
      DateStart: 'Date Start',
      DateEnd: 'Date End',
      labelId: 'No. Id',
      labelFechaIncidente: 'Incident Date',
      labelFuente: 'Receiver',
      labelReporto: 'Reported',
      labelAttachments: 'Communicate to',
      labelResponsable: 'Responsible',
      labelHistory: 'Record',
      labelResponsableSelect: 'Select a responsible',
      labelResponsableArea: 'You selected a complete area as responsible, the responsible must be only one',
      labelFiles: 'Uploaded Files',
      labelTipoReporte: 'Kind Of Report',
      labelFolio: 'Invoidce',
      labelEstacion: 'Arrival',
      labelVuelo: 'Number Fligth',
      labelTramo: 'Route',
      labelClase: 'Class',
      labelProductos: 'Groups',
      labelCriteria: 'Criteria',
      labelTipoIrregularidad: 'Type Of Irregularity',
      labelDescripcion: 'Description',
      labelDescripcionEnglish: 'Description in english',
      labelComentario: 'Comment',
      labelComentarioCierre: 'Closing comment on irregularity',
      labelResponseIrregularity: 'Do you accept the irregularity?',
      labelCompany: 'Company',
      labelPhoto: 'Photo',
      labelResponsableStation: 'Responsible station',
      labelStatus: 'Status',
      open: 'Open',
      close: 'Close',
      accept: 'Accept',
      reject: 'Reject',
      accepted: 'Accepted',
      rejected: 'Rejected',
      answer: 'Answer',
      fordward: 'Fordward',
      fordwardProvider: 'Supplier Response',
      //Table headers
      ActualBlockOffUTCHeader: 'Actual BlockOff UTC',
      ActualBlockOnUTCHeader: 'Actual BlockOn UTC',
      ActualTakeOffUTCHeader: 'Actual TakeOff UTC',
      ActualTouchDownUTCHeader: 'Actual TouchDown UTC',
      AircraftRegistrationHeader: 'Aircraft Registration',
      AircraftSubTypeHeader: 'Aircraft Subtype',
      CarrierHeader: 'Carrier',
      DepartureAirportHeader: 'Departure Airport',
      DepartureIndicatorHeader: 'Departure Indicator',
      FlightDateLocalHeader: 'Flight Date Local',
      FlightNumberHeader: 'Flight Number',
      LatestArrivalAirportUTCHeader: 'Latest Arrival Airport UTC',
      ScheduledArrivalTimeUTCHeader: 'Scheduled Arrival Time UTC',
      ScheduledDepartureTimeUTCHeader: 'Scheduled Departure Time UTC',
      //Table Irregularities Headers
      description: 'Description',
      flightDate: 'Fligth Date',
      flihtNumber: 'Fligth Number',
      folio: 'Folio',
      forwads: 'Forwads',
      forwad: 'Forwad',
      photo64: 'Photo 64',
      photoPath: 'Photo Path',
      reportDate: 'Report Date',
      sender: 'Sender',
      forwardDate: "Forward Date",
      lastName: 'Last Name',
      name: 'Name',
      noforward: 'Number Forward',
      labelDontClose: 'Irregularity is closed, cannot be edited or forwarded',
      dontMultiResponsable: 'You can only select one responsible',
      labelMaxForward: 'Forwarding number has been exceeded',
      labelStationProvider: 'The selected responsible provider does not belong to that station',
      labelStationProviderValidate: 'First select a manager before selecting the responsible station',
      labelDontIsResponsable: 'You cannot give forward since you are not responsible for the irregularity',
      labelDontPermissionResponse: 'You do not have permission to answer this irregularity',
      labelDontPermissionEdit: 'You do not have permission to edit this irregularity',
      labelResponsableValid: 'Select a valid manager',
      labelSelectDescriptionEnglish: 'You must modify the description in English to be able to edit',
      proveedor: 'Provider response',
      invalidFilter: 'Invalid filter, This field is for filtering, do not enter emails in this field, to select display the list of users found below',
      filter: 'Filter',
      titleEmailsNotification: 'It will be notified to',
      textEmailsNotification: 'An email notification will be sent to the following contacts:',
      textResponseEmailsNotification: '¿Esta deacuerdo?',
      contacts: 'Contacts',
      labelDate: 'Date',
      labelArea: 'Responsable',
      labelUserEdit: 'Made by            ',
      labelUserClose: 'Closed by',
      labelNotifiedTo: 'Notified list',
      titleEmailsNotificationInfo: 'The following contacts were notified',
      labelNotificateDirectBoss: 'Notify direct boss?',
      labelDontProductAutomaticResponsable: 'To have a person in charge in automatic you must also select the group, irregularity and criteria',
      labelDontStationAutomaticResponsable: 'In order to have a responsible person in automatic, you must also select the responsible station',
      labelDontMultipleResponsable: 'No more than one person can be selected',
      labelDontAreaResponsable: 'You cannot choose an area as responsible',
      labelDontResponsableAndDirectBoss: 'There is no manager or direct manager assigned for this station, assign one manually',
      labelDontStationAndCriteriaAutomaticResponsable: 'To have a person in charge automatically, the criteria and the station must also be selected',
      labelDontCriteriaAutomaticResponsable: 'To have an automatic manager, the criterion must also be selected',
      labelDontDirectBoss: 'There is no direct manager assigned',
      labelDirectBoss: 'Direct Boss',
    },
    statistics: {
      //Titles
      titleModule: 'Statistics',
      titleEdit: 'Edit Statistic',
      titleAdd: 'Add Statistic',
      titleDelete: 'Are you sure to remove the statistic?',
      filterTitle: 'Group Filters',

      //Messages
      excelErrorMsg: 'Invalid excel size! , (maximum size of 3MB)',
      AddMsg: 'Statistic successfully added',
      AddMsgAgain: 'Not added correctly, try again',
      EditMsg: 'The statistic was edited correctly ',
      EditMsgAgain: 'Not edited correctly, try again',
      DeleteQuestion: 'Are you sure to delete the statistic?',
      DeleteMsg: 'It was successfully deleted',
      DeleteMsgAgain: 'Not deleted successfully, try again',
      WarnDateMsg: 'Select the ',
      ErrorDateValidateMsg: 'The start date cannot be minor than the date end',

      //Labels
      DateStart: 'Date Start',
      DateEnd: 'Date End',
      //Table headers

      labelFolio: 'Invoice',
      labelFechaIncidente: 'Incident Date',
      labelFuente: 'Receiver',
      labelReporto: 'Reported',
      labelFlightNumber: 'Fligth Number',
      labelTramo: 'Route',
      labelResponsable: 'Responsible',
      labelEstacion: 'Arrival',
      labelTipoReporte: 'Kind of Report',
      labelProductos: 'Group',
      labelTipoIrregularidad: 'Type of Irregularity',
      labelClase: 'Class',
      labelDescripcion: 'Description',
      labelStation: 'Station',
      labelProvider: 'Provider',
      status: 'Status',
      labelCriteria: 'Criteria'

    },
    restockCap: {
      //Titles
      titleModule: 'Top up for beverages',
      //Table Headers
      idRestockCap: 'Identifier',
      nameRestock: 'Name',
      station: 'Station',
      region: 'Region',
      status: 'Status',

      //Modal Messages
      titleAdd: 'Top up for beverages Add',
      excelErrorMsg: 'Invalid excel file',
      excelFileNameError: 'Invalid excel file name',
      //Labels
      labelExcel: 'Select a excel',
      //Messages
      AddMsg: 'Top up for beverages successfully added file upload completed successfully, verify changes',
      AddMsgAgain: 'Not added correctly, try again',
      DeleteQuestion: 'Are you sure to delete the Top up for beverages?',
      DeleteMsg: 'It was successfully deleted',
      DeleteMsgAgain: 'Not deleted successfully, try again',
      NoFileMsg: 'Missing excel document of liquid replenishment caps'
    },
    boardingTable: {
      //Titles
      titleModule: 'Boarding Table',
      titleEdit: 'Edit Boarding Table',
      titleAdd: 'Add Boarding Table',
      titleDelete: 'Are you sure to remove the boarding table?',
      titleMessages: 'Messages',
      //Messages
      AddMsg: 'The file was read correctly, please verify the data',
      AddMsgAgain: 'Not added correctly, try again',
      EditMsg: 'The boarding table was edited correctly ',
      EditMsgAgain: 'Not edited correctly, try again',
      DeleteQuestion: 'Are you sure to delete the boarding table?',
      DeleteMsg: 'It was successfully deleted',
      DeleteMsgAgain: 'Not deleted successfully, try again',
      //Labels
      labelExcel: 'Select a excel',

      //Table headers
      airline: 'Arline',
      cabine: 'Cabine',
      origen: 'Origen',
      destine: 'Destine',
      equipment: 'Aircraft',
      codServ: 'Service Code',
      dateInit: 'Date Init',
      dateEnd: 'Date End',
      cycles: 'Cycles',
      section: 'Sections',
      // Table Modal
      errorMessage: 'Error message',
      isInsert: 'Do I keep it?',
      descServ: 'Service description',
      codServErr: 'Service Code or Data Table',
      orderReg: 'Order Registers',
      finish: 'Finish',
      uploadFile: 'UploadFile',
      msgUpload: 'Drag and drop your files here',
      msgUploadOr: 'or',
      btnBrowseFile: 'Browse File',
      nameTableFile: 'File Name',
      //Propiedades de breadcrumb
      addOnBoard: 'Create Boarding Table',
      addOnBoardStation: "Charging stations:",
      addOnBoardCheck: "Please check that the order of the data is correct."
    },
    preselectemails: {
      langTitleMenu: "Email Catalog",
      langCorrectEmailInsert: "The email has been added successfully.",
      langEmailExists: "The email already exists in the destinatary list.",
      langAdd: "Add",
      langReportType: "Report Type",
      langTitleInsertModal: "Add Email",
      langEmailLabel: "Email",
      langStationLabel: "Station",
      langSaveButton: "Save",
      langColumnEmail: "Email",
      langColumnStation: "Station",
      langColumnActive: "Status",
      langEdit: "Edit",
      langLoading: "Loading",
      langTitleEditModal: "Edit Email",
      langSlideStatus: "Email Status",
      langCorrectEmailUpdate: "The email has been updated successfully.",
      langRequiredEmail: "The email is required",
      langRequiredStation: "Choose one station for less",
      langIncorrectFormatEmail: "The email has not correct format",
      langInvalidRequest: "Invalid request to the web server.",
      langInternalServerError: "Internal server error while request was process.",
      langUnauthorizedRequest: "Unauthorized Access Server.",
      langErrorTitleMessage: "Error:",
      langSuccessTitleMessage: "Success:",
      langLabelItemsByPage: "Items per page:",
      langLabelOf: "of",
      langStationCheckAllLabel: 'Select all',
    },
    tsu: {
      //Titles
      titleModule: 'Tsu',
      titleEdit: 'Edit TSU',
      titleAdd: 'Add TSU',
      titleDelete: 'Are you sure to remove the TSU?',
      titleCommnets: 'Add Comment',
      //Messages
      AddMsg: 'TSU successfully added',
      AddMsgAgain: 'Not added correctly, try again',
      EditMsg: 'The TSU was edited correctly ',
      EditMsgAgain: 'Not edited correctly, try again',
      DeleteQuestion: 'Are you sure to delete the TSU?',
      DeleteMsg: 'It was successfully deleted',
      DeleteMsgAgain: 'Not deleted successfully, try again',
      //Labels
      labelExcel: 'Select a excel',

      //Table headers
      idMaterial: 'Id Material',
      description: 'Description',
      partNumber: 'Part number',
      observation: 'Observation',
      idTsu: 'Id tsu',
      name: 'Name',
      classe: 'Class',
      photo: 'Photo',
      quantity: 'Quantity',
      scheme: 'Scheme',
      imageErrorMsg: 'Invalid image size! , (maximum size of 3 MB)',


      // Table Modal
      errorPartNumber: 'The part number is required',
      errorObservation: 'The observation is required',
      errorName: 'The name is required',
      errorClasse: 'The class is required',
      errorPhoto: 'The photo is required',
      kitCreated: 'kit created',
      materials: 'Materials',
      observations: 'Observations',
      addKit: 'Add Kit',
      commentary: 'Commentary',
      errorCommentary: 'The commentary is required',
      errorScheme: 'The sheme is required',
      excel: 'Download Excel'


    },
    serviceGuides: {
      //Titles
      titleModule: 'Service Guides',
      titleEdit: 'Edit Service Guide',
      titleAdd: 'Add Service Guide',
      titleDelete: 'Are you sure to remove the service guide?',
      //Messages
      AddMsg: 'Service guide successfully added',
      AddMsgAgain: 'Not added correctly, try again',
      EditMsg: 'The service guide was edited correctly ',
      EditMsgAgain: 'Not edited correctly, try again',
      DeleteQuestion: 'Are you sure to delete the service guide?',
      DeleteMsg: 'It was successfully deleted',
      DeleteMsgAgain: 'Not deleted successfully, try again',
      //Labels
      labelExcel: 'Select a Excel',

      //Table headers
      nameFile: 'Name',
      airlineType: 'Airline Type',
      month: 'Month',
      // Table Modal
      incorrectNomenclature: 'File nomenclature is incorrect',
      fileNotSelected: 'File not selected',
    },
    fleetList: {
      //Titles
      titleModule: 'Fleet List',
      titleEdit: 'Edit Fleet List',
      titleAdd: 'Add Fleet List',
      titleDelete: 'Are you sure to remove the Fleet List?',
      titleCommnets: 'Add Commnet',

      //Messages
      AddMsg: 'Fleet list successfully added',
      AddMsgAgain: 'Not added correctly, try again',
      AddMsgCommnet: 'The comment was added correctly in the family',
      EditMsg: 'The fleet list was edited correctly ',
      EditMsgAgain: 'Not edited correctly, try again',
      DeleteQuestion: 'Are you sure to delete the fleet list?',
      DeleteMsg: 'It was successfully deleted fleet list',
      DeleteMsgAgain: 'Not deleted successfully, try again',
      errorForm: 'The field is required ',
      //Labels
      idPlane: 'Id',
      matPlane: 'Enrollment',
      shortMatPlane: 'Short Enrollment',
      model: 'Model',
      subModelType: 'Submodel',
      seats: 'Total Seats',
      seatsPremier: 'Premier Seats',
      seatsTourist: 'Tourist Seats',
      airline: 'Airline',
      family: 'Family',
      meals: 'Foods',
      ovens: 'Ovens',
      notes: 'Notes',
      yEntertainment: 'Tourist Entertainment Team',
      jEntertainment: 'PREMIER Entertainment Team',
      jHeadphones: 'Premier Headphones',
      yHeadphones: 'Tourist Headphones',
      jWC: 'Premier Bathrooms',
      Ywc: 'Tourist Bathrooms',
      jStationery: 'Premier Paperwork',
      yStationery: 'Tourist Paperwork',
      connectivity: 'Connectivity',
      commnet: 'Comment',
      addComment: 'Add Comment',
      plane: 'Select a Aircraft',
      row: 'Select a row',

      //Table headers

      // Table Modal

    },
    matrix: {
      //Titles
      titleModule: 'Matrix',
      titleDetail: 'Matrix Detail',
      titleEdit: 'Edit matrix',
      titleAdd: 'Add matrix',
      titleDelete: 'Are you sure to remove the matrix?',
      //Messages
      AddMsg: 'matrix successfully added',
      AddMsgAgain: 'Not added correctly, try again',
      EditMsg: 'The matrix was edited correctly ',
      EditMsgAgain: 'Not edited correctly, try again',
      DeleteQuestion: 'Are you sure to delete the matrix?',
      DeleteMsg: 'It was successfully deleted',
      DeleteMsgAgain: 'Not deleted successfully, try again',
      //Labels
      labelExcel: 'Select a Excel',

      //Table headers
      titleHeader: 'Commissariat Article Matrix',
      equipment: 'Aircraft',
      enrollment: 'ID register',
      totalPax: 'Total Pax',
      lot: 'Lot/NP',
      um: 'UM',
      description: 'Description',
      picture: 'Picture',
      dateModified: 'Effective Date',

      kitCreated: 'kit created',
      equipmentFamily: 'Aircraft',
      product: 'Group',
      touristQuantity: 'Turist Quantity',
      premierQuantity: 'Premier Quantity',
      lote: 'Lot',
      msgselectLot: 'You must select a lot',
      msgEquipmentSelect: 'You must select a aircraft',
      msgProduct: 'You must select a group',
      msgQuantityTourist: 'You must write a tourist quantity',
      msgQuantityPremier: 'You must write a premier quantity',
      matrixForm: 'Matrix Form',
      editMatrixForm: 'Edit matrix Form',
      selectEquipment: 'Select an aircraft',
      selectProduct: 'Select a group',
      effectiveDateLabel: 'Effective date'
    },
    generalConsult: {
      nameView: 'Food Specifications View',
      nameDownload: 'Food Specifications Download',
      titleConsultGeneral: 'General Consult',
      titleIrregularities: 'Irregularities',
      titleBoardingTable: 'Boarding Table',
      titleRestockCap: 'Top up for beverages',
      titleNotice: 'Notice',
      originSation: 'Origin',
      provider: 'Caterer',
      potentialName: 'Food Specifications',
      cycle: 'Cycle',
      startDate: 'Start Date',
      endDate: 'End Date',
      endValidity: 'Finish Date',
      titleServiceCode: 'Service Code',
      boardingTable: 'Boarding Table',
      stateSelectError: 'Error occurred while selecting the station',
      destinationSation: 'Destination',
      questionDeleteStation: 'Are you sure to remove the station?',
      generalConsultation: 'General Consult',
      providerEdit: 'Caterer Edit',
      addProviderCode: 'Add Caterer Code',
      providerName: 'Caterer Name',
      addProvider: 'Add Caterer',
      reference: 'Reference',
      dateSend: 'Date Send',
      modifiedby: 'Modified by',
      dateModified: 'Modification Date',
      category: 'Category',
      subcategory: 'Subcategory',
      subject: 'Subject',
      startValidity: 'Efective Date',
      msgStationRequired: 'You must select a station',
      msgRegionRequired: 'You must select a region',
      msgCodServRequired: 'You must enter a Service Code',
      cycleWarning: 'There are no registered cycles for the supplier',
      classWarning: 'There are no registered class for the supplier',
    },
    navBar: {
      equipment: 'aircraft',
      restockCap: 'Top up for beverages',
      irregularityCriteria: 'Irregularity Criteria',
      design: 'CATERING',
      catalogs: 'Catalogues',
      cycles: 'Cycles',
      serviceCode: 'Service Code',
      potential: 'Food Specifications',
      boardingTable: 'Boarding Table',
      generalConsultation: 'General Consult',
      reports: 'Reports',
      byStation: 'By Station',
      planning: 'PLANNING',
      fleetRelationship: 'Fleet List',
      master: 'Master',
      matrix: 'Matrix',
      TSU: 'TSU',
      graphics: 'Galley Diagrams',
      accommodations: 'Trolley Meal Dist',
      barList: 'Bar List',
      serviceGuide: 'Service Guide',
      serviceGuideSisea: 'Service Guide SISEA',
      materials: 'Materials',
      irregularities: 'IRREGULARITIES',
      statistics: 'Statistics',
      notices: 'NOTICES',
      tracing: 'View Notice',
      shipping: 'New Notice',
      directory: 'DIRECTORY',
      employees: 'Employees',
      provider: 'Caterer',
      configuration: 'ADMINISTRATION',
      regions: 'Regions',
      stations: 'Stations',
      users: 'Users',
      welcome: 'Welcome!',
      start: 'Start',
      signOff: 'Logout',
      questioncloseSession: 'Are you sure you want to leave?',
      notice: 'Notices',
      foods: 'Food Catalog',
      manuals: 'Manuals and Procedures',
      products: 'Groups',
      preselect: 'Preselect',
      preselectemails: 'Emails Catalog',
      flyings: 'Flyings Catalog',
      stationsflying: 'Stations Catalog',
      preselectioncodes: 'Preselect Catalog',
      specialmealcodes: 'Special Meal Catalog',
      digitalmain: 'Digital Main',
      main: 'MAIN',
      digitalMenu: 'Digital Menu'
    },
    main: {
      viewAll: 'View All',
      sessionExpired: 'Your session expired',
      titleLanguage: 'Language',
      chooseText: 'Choose a language',
      languageSpanish: 'Spanish',
      languageEnglish: 'English',
      skip: 'Skip',
      check: 'Check'
    },
    graphics: {
      uploadedFiles: 'Uploaded files',
      loadGraphics: 'Load Diagrams Galley',
      loadedGraphicMsg: 'No graphic has been loaded',
      loadedNoGraphicMsg: 'No graphic has been loaded',
      graphicsLoadFinishMsg: 'Graphics loading process is finished, check the files',
      graphics: 'Galley Diagrams',
      graphicsSpecial: 'Galley Diagrams Special',
      addGraphics: 'Add Diagrams Galley',
      addGraphicsSpecial: 'Add Diagrams Galley Special',
      questionDeleteGraphic1: 'Are you sure to delete the aircraft?',
      questionDeleteGraphic2: 'and the ',
      deleteGraphicMsg: 'The aircraft was successfully deleted',
      graphicEditMsg: 'Graphic edited correctly',
      editGraphic: 'Graphic Edit',

      viewFileSpanish: 'View English File',
      varViewFileEnglish: 'View English File',
      varViewFile: 'View File',
      accommodationLoad: 'Trolley Meal Dist Load',
      fileSpanish: 'File',
      fileEnglish: 'English File',
      createDate: 'Create Date',
      englishDownload: 'English Download',
      spanishDownload: 'English Download',
      stations: 'Stations',
      graphsDeleteQuestion: 'Are you sure to delete the graphic?',
      graphsDeleteMsg: 'It was successfully deleted',
      graphsDeleteMsgAgain: 'Not deleted successfully, try again',


    },
    profile: {
      allMenu: 'ALL',
      nameProfile: 'Profile Name',
      instruccionAddProfile: 'Select the permissions you want for the module and / or modules',
      permitionProfile: 'You must select a permit',
      namePRofileAlert: 'You must select the profile name',
      profile: 'Profiles',
      msgDeleteProfile: 'Profile deleted successfully'
    },
    codServ: {
      selectAirline: 'You must select an airline',
      airlineCode: 'Airline Code',
      serviceCode: 'Service Code',
      codeServiceDescription: 'Description',
      clasificationCode: 'Clasification Code',
      codeSISEA: 'SISEA Code',
      startDate: 'Start Date',
      startValidityDate: 'Start Validity Date',
      process: 'Process',
      addServiceMsg: 'The service was successfully added',
      validityDateMsg: 'You must enter the start validity date',
      classificationMsg: 'You must enter a classification type',
      classMsg: 'You must enter a cabin type',
      descriptionCodeServiceMsg: 'You must enter a description of the service code',
      codeServiceMsg: 'You must enter a service code',
      serviceUpdatedSuccMsg: 'Service was updated successfully',
      classificationCodeMsg: 'You must select a classification code',
      descriptionMsg: 'You must enter a description',
      serviceDeleteMsg: 'Service was successfully deleted',
      deleteServiceErrorMsg: 'Error occurred while removing the service',
      titleServiceCode: 'Service Code',
      addServiceCode: 'Add Service Code',
      editServiceCode: 'Edit Service Code',
      foodServiceCode: 'Food Service Code',
      descriptionName: 'Description Name',
      questionDeleteServiceCode: 'Are you sure to delete the service code?',
      typeService: 'Service Type',
      validitytypeServiceMsg: 'You must enter a service type'
    },
    cycles: {
      airlineCode: 'Airline Code',
      provider: 'Caterer',
      process: 'Process',
      stateSelectError: 'Error occurred while selecting the station',
      selectAirline: 'You must select an airline',
      originStation: 'Origin',
      destinationStation: 'Destination',
      startDateServ: 'Start Date',
      endtDateServ: 'End Date',
      cycle: 'Cycle',
      cycleRotationAddMsg: 'Cycle rotation successfully added',
      selectCycleMsg: 'You must select a cycle',
      endDateMsg: 'You must select an end date',
      startDateMsg: 'You must select a start date',
      selectProviderMsg: 'You must select a caterer',
      stationOriginMsg: 'You must select a station origin',
      enterInitialDateMsg: 'Enter an initial date',
      enterEndDateMsg: 'Enter an end date',
      cycleRotationEditMsg: 'Cycle rotation successfully edited',
      originDestinationLinkedMsg: 'The origin and destination cannot be linked',
      destination: 'Destination',
      titleCyleRotation: 'Cycle Rotation',
      cycleSearch: 'Cycle search',
      addCycleRotation: 'Add cycle rotation',
      origin: 'Origin',
      editCycleRotation: 'Edit cycle rotation',
      viewAll: 'View All',
      msgCalendar: 'The date To must be greater than date From',
      vardontDate: 'Dont find init date or end date',
      questionDeleteCycle: 'Are you sure to delete the cycle?',
      deleteCycleErrorMsg: 'Error occurred while removing the cycle',
      cycleDeleteMsg: 'Was successfully deleted cycle',
      addMsg: 'Aggregate',
      noAddMsg: 'Not Aggregate'
    },
    foods: {
      foods: 'Foods Catalogues',
      airline: 'Airline',
      article: 'Article',
      description: 'Description',
      service: 'Service',
      cycle: 'Cycle',
      Option: 'Option',
      addFood: 'Add Food',
      selectAirline: 'You must select an airline',
      msgArticle: 'You must write an article',
      descriptionMsg: 'You must enter a description',
      selectCycleMsg: 'You must select a cycle',
      selectOption: 'You must select an option',
      existingCode: 'Existing code',
      foodAdded: 'Food added correctly',
      editMsg: 'edited correctly',
      editFood: 'Edit Food',
      AddMsg: 'Finished processing the file, check the loaded food table',
      AddMsgAgain: 'Not added correctly, try again',
    },
    manuals: {
      fleetRelationship: 'Fleet List',
      manuals: 'Manuals and Procedures',
      questionManual: 'Are you sure to delete the manual',
      fileDelete: 'It was successfully deleted',
      fileEditMsg: 'File edited correctly',
      errorNomenclature: 'Invalid nomenclature, file name must start with I (English) or E (Spanish)'
    },
    master: {
      repeatPartNumberEdit: 'This part number has already been selected, it is in the table',
      reusable: 'Reusable',
      msgCycle: 'You must select a Cycle',
      msgCommentary: 'Must Write a Commentary',
      cycle: 'Cycle',
      commentary: 'Commentary',
      dateModified: 'Effective Date',
      master: 'Master',
      lote: 'Lot',
      description: 'Description',
      container: 'Container',
      localStation: 'Local Station',
      internationalStation: 'International Station',
      idMaterials: 'Id Materials',
      supplyType: 'Supply Type',
      partNumber: 'Part Number',
      addMaterials: 'Add Material',
      addKit: 'Add Kit',
      msgLot: 'You must write a lot',
      msgDescription: 'You must write a description',
      msgContainer: 'You must write a container',
      msgUm: 'You must select a unit of measure',
      msgLocalStation: 'You must select a local station',
      msgInternationalStation: 'You must select a international station',
      kitEdit: 'Kit Edit',
      supplying: 'Supplying',
      materials: 'Materials',
      kitDeleted: 'Deleted Kit',
      questionDeleteKit: 'Are you sure to delete the kit with lot',
      selectSupply: 'Select the type of supply',
      englishDescription: 'English Description',
      selectTypeSupply: 'Select the type of supply',
      alertTypeSupply: 'You need to add type of supply',
      typeSupplyKits: 'Verify that all kits contain type of supply',
      kitCreated: 'kit created',
      familyDescription: 'Family Description',
      msgDescriptionEnglish: 'You must write a english description'
    },
    providers: {
      provider: 'Caterer',
      supplierCode: 'Caterer Code',
      codeStationProvider: 'Supplier Caterer',
      editProviderSucc: 'Caterer successfully edited',
      addProviderSucc: 'Caterer successfully added',
      stationNameMsg: 'Enter IATA of the station',
      deleteRegionMsg: 'The station was successfully deleted',
      titleCatalogProviders: 'Caterers Catalogues',
      questionDeleteStation: 'Are you sure to remove the station?',
      providerEdit: 'Caterer Edit',
      addProviderCode: 'Add Caterer Code',
      providerName: 'Caterer Name',
      addProvider: 'Add Caterer',
      alertCodeProvider: 'You must write a Caterer code',
      alertNameProvider: 'You must write a Caterer name',
      alertselectSattion: 'You must select a station',
      deleteProviderCorrectly: 'Was successfully deleted the caterer',
      questionDeleteProvider: 'Are you sure to delete the caterer'
    },
    regions: {
      addRegion: 'Add Region',
      enterRegion: 'Enter a region',
      editRegionCorrectly: 'The region was edited correctly',
      editRegionAgain: 'Not edited correctly, try again',
      deleteRegionCorrectly: 'The region was successfully deleted',
      deleteRegionAgain: 'Not deleted successfully, try again',
      titleRegions: 'Regions Catalogues',
      regionName: 'Region Name',
      editRegion: 'Edit Region',
      questionDeleteRegion: 'You are sure to delete the region'
    },
    stations: {
      stationAddMsg: 'Station successfully added',
      stationEditMsgAgain: 'The station was edited correctly',
      stationNameMsg: 'Enter IATA of the station',
      selectRegion: 'Select a Region',
      deleteRegionMsg: 'The station was successfully deleted',
      titleStations: 'Station Catalogues',
      editSation: 'Edit Station',
      addStation: 'Add Station',
      questionDeleteStation: 'Are you sure to remove the station'
    },
    users: {
      labelAll: 'All',
      userCatalog: 'User Catalogues',
      userAdd: 'Add User',
      usrName: 'User Name',
      surnames: 'Last Name',
      extension: 'Extension',
      jobNameSpanish: 'Job Name in Spanish',
      jobNameEnglish: 'Job Name in English',
      permissions: 'Permissions',
      area: 'Area',
      provider: 'Caterer',
      job: 'Job',
      addSuccUser: 'User successfully added',
      stationSelect: 'Select a Station',
      profiles: 'Profiles',
      questionDeleteUSer: 'Are you sure to delete the User',
      userDeleteMsg: 'User was successfully deleted',
      deleteUserErrorMsg: 'Error occurred while removing the user',
      userEdit: 'User Edit',
      nameUser: 'You must enter a username',
      lastnameUser: 'You must enter your last name',
      claveUser: 'You must enter your password',
      emailUser: 'You must enter your email',
      phoneUser: 'You must enter your phone',
      extensionUser: 'You must enter your extension',
      jobNameUserSpanish: 'You must enter your job name in Spanish',
      jobNameUserEnglish: 'You must enter your job name in English',
      areaUser: 'You must enter your area',
      Userprofile: 'Profile',
      Provider: 'Caterer',
      selectProviderMsg: 'You must select a caterer',
      ProfileSelect: 'Select a profile',
      EditUserCorrect: 'User edited correctly',
      selectStations: 'Select the stations',
      labelRequired: 'Required fields are those marked with',
      titleTable: 'Station relation tables',
      newStation: 'To create a new station relation',
      addStations: 'Add new station list',
      msgDeleteStation: 'When saving changes, the previous station list will be removed',
      click: 'Click here',
      msgSelectStation: 'You must select a station',
      unasigned: 'Unasigned'

    },
    notice: {
      subject: 'Subject',
      category: 'Category',
      subcategory: 'Subcategory',
      destination: 'Destination',
      sentBy: 'Sent by',
      createDate: 'Create Date',
      attachedFile: 'Attached File',
      startValidity: 'Efective Date',
      endValidity: 'Finish Date',
      read: 'Read',
      unread: 'Unread',
      userProvider: 'User/Caterer',
      stationArea: 'Station/Area',
      noticeDelete: 'Notice delete',
      noticeUpdated: 'Notice updated',
      seeNotice: 'View Notice',
      readingStatus: 'Reading Status',
      titletConsultNotice: 'Notices',
      addNotice: 'Add Notice',
      editNotice: 'Edit Notice',
      questionDeleteNotice: 'Are you sure to delete the statement?',
      reference: 'Reference',
      dateSend: 'Date Send',
      modifiedby: 'Modified by',
      dateModified: 'Modification Date',
      titletNotice: 'Send Notice',
      addressed: 'Addressed to',
      effectiveness: 'Effectiveness',
      attach: 'Attachment',
      sendTo: 'Send to',
      firm: 'Signature',
      contactTo: 'Contact',
      labelAll: 'All',
      sentNotice: 'Sent Notice',
      selectUser: 'You must select a user to contact',
      selectClass: 'You must select a cabin',
      effectiveDate: 'You must enter the effective date',
      selectAirline: 'You must select an airline',
      selectSubject: 'You must indicate a subject',
      selectSubCategory: 'You must select a subcategory',
      selectCategory: 'You must select a category',
      msgCalendar: 'The date To must be greater than date From',
      labelDestination: 'Select who it will be sent to',
      msgEquipment: 'The equipment field is required'

    },
    potentials: {
      questionDeletePotential: 'Are you sure to delete the Food Specifications',
      questionDeleteSubPotential: 'Are you sure to delete the sub Food Specifications',
      provider: 'Caterer',
      cycle: 'Cycle',
      titleServiceCode: 'Service Code',
      revision: 'Revision',
      potentialConsultation: 'Food Specifications Consult',
      view: 'View',
      potentialLoad: 'Food Specifications Load',
      subPotentialLoad: 'Sub Food Specifications Load',
      potentialLoadFinishMsg: 'Food Specifications loading process is finished, check the files',
      subPotentialLoadFinishMsg: 'Sub Food Specifications loading process is finished, check the files',
      potentialsDeleteMsg: 'Was successfully deleted Food Specifications',
      subPotentialsDeleteMsg: 'Was successfully deleted Sub Food Specifications',
      loadPotentials: 'Load Food Specifications',
      loadSubPotentials: 'Load Sub Food Specifications',
      loadedPotentialMsg: 'No Food Specifications has been loaded',
      loadedSubPotentialMsg: 'No sub Food Specifications has been loaded',
      uploadedFiles: 'Uploaded files',
      invalidFile: 'Invalid File',
      validFile: 'Valid File',
      loadAttachments: 'Upload Files'

    },
    trolleyMealDist: {
      provider: 'Caterer',
      cycle: 'Cycle',
      titleServiceCode: 'Service Code',
      revision: 'Revision',
      accommodations: 'Trolley Meal Dist',
      acommodationsSpecials: 'Special Trolley Meal Dist',
      viewFileSpanish: 'View English File',
      varViewFileEnglish: 'View English File',
      accommodationLoad: 'Trolley Meal Dist Load',
      fileSpanish: 'File',
      fileEnglish: 'English File',
      createDate: 'Create Date',
      effectiveStart: 'Effective Start',
      englishDownload: 'English Download',
      spanishDownload: 'English Download',
      loadAccommodations: 'Load Trolley Meal Dist',
      loadAccommodationsSpecial: 'Load Trolley Meal Dist',
      accommodationLoadFinishMsg: 'Trolley Meal Dist loading process is finished, check the files',
      loadedAccommodationMsg: 'No Trolley Meal Dist has been loaded',
      trolleyDeleteQuestion: 'Are you sure to delete the Trolley Meal Dist?',
      trolleyDeleteMsg: 'It was successfully deleted',

      trolleyDeleteMsgAgain: 'Not deleted successfully, try again',

    },
    barList: {
      barList: 'Bar List',
      invalidFileGraphics: 'Invalid File',
      fileAdded: 'File added correctly'
    },
    guideServiceSisea: {
      guideServiceSisea: 'Guide Service SISEA',
      invalidFileGraphics: 'Invalid File',
      fileAdded: 'File added correctly'
    },
    updateProfile: {
      allMenu: 'ALL',
      create: 'Add',
      consult: 'Read',
      nameProfile: 'Profile Name',
      instruccionAddProfile: 'Select the permissions you want for the module and / or modules',
      option: 'OPTION',
      permitionProfile: 'You must select a permit',
      namePRofileAlert: 'You must select the profile name',
      profile: 'Profiles',
      msgDeletePermiso: 'The permission was removed correctlye',
      msgDeleteProfile: 'Profile deleted, review changes at the top'
    },
    login: {
      welcomeTitle: 'Welcome',
      welcomeSubtitle: 'On Board Product Portal',
      email: 'Enter your email',
      clave: 'Enter your password',
      recoverClave: 'Recover Password',
      in: 'Login',
      inGateone: 'Log In',
      msgUserOrPasswordError: 'Incorrect Username or Password',
      msgVerifyCredentials: 'Verify credentials',
      tryConnectAgain: 'Try to connect again',
      msgEmailvalid: 'Please enter a valid email',
      msgSendEmailLink: 'Please check your email to continue the process',
      emailInvalid: 'Invalid email account',
      changeClave: 'Change Password',
      newClave: 'New Password',
      welcome: 'Welcome!',
      accept: 'Ok',
      textWelcomeLogin: 'To enter the portal for the first time, it is necessary to change your password',
      linkExpired: 'Link expired',
      linkInvalid: 'Invalid Link',
      msgChangePassword: 'Password Changed',
      enterNewClave: 'Enter your new password',
      confirmClave: 'Confirm your password',
      claveDifferents: 'Passwords are different',
      msgPasswordNull: 'Please fill in all fields of the form',
      auth0: {
        auth0NotAvailable: 'Error logging in with GateOne.',
        auth0NotAvailableDetail: 'The provider is not available.<br> Please log in using your backup Username/Password.'
      }
    },
    formPassword: {
      msgTituloPasswordRespaldo: 'Set up your backup password',
      msgPasswordRespaldo1: 'Your backup password provides an alternative method to access the PAB Portal when the Okta service is unavailable.',
      msgPasswordRespaldo2: 'This additional security measure helps prevent situations where you may be unable to sign in through the Okta service and require immediate access.'
    },
    products: {
      specialProducts: 'Special Groups',
      products: 'Groups',
      product: 'Group',
      addProduct: 'Add Group',
      productAdded: 'Group added correctly',
      msgAddProduct: 'You must write a Group',
      nameProduct: 'Group Name',
      questionDeleteProduct: '¿Are you sure to delete the group',
      fileDelete: 'It was successfully deleted',
      addSpecialProduct: 'Add Special Group',
      nameSpecialProduct: 'Special Group Name',
      note: 'Underscore and space are not supported'









    },
    equipments: {
      equipments: 'Aircraft',
      matPlane: 'Matrícula',
      shortMatPlane: 'Short MatPlane',
      subModelType: 'Model',
      seatsPremier: 'Seats Premier',
      seatsTourist: 'Seats Tourist',
      subAirplaneType: 'Sub Airplane Type',
      family: 'Family',
      statusODS: 'Status ODS',
      statusPAB: 'status PAB',
      editEquipment: 'Aircraft Edit',
      editEquipmentCorrectly: 'The Aircraft was successfully edited',
      deleteEquipment: 'Aircraft removed successfully',
      questionDeleteEquipment: 'Are you sure to remove the Aircraft',


    },
    irregularityCriteria: {
      irregularityCriteria: 'Irregularity Criteria',
      irregularityCriteriaSpanish: 'Criteria Name in Spanish',
      irregularityCriteriaEnglish: 'Criteria Name in English',
      productTypeEnglish: 'Group Type in English',
      productTypeSpanish: 'Group Type in Spanish',
      typeIrregularityEnglish: 'Type of Irregularity in English',
      typeIrregularitySpanish: 'Type of Irregularity in Spanish',
      addirregularityCriteria: 'Add Irregularity Criteria',
      addirregularityCriteriaSucc: 'Irregularity Criteria added correctly',
      AlertTypeIrregularityEnglish: 'You must enter the Irregularity Type in English',
      AlertTypeIrregularitySpanish: 'You must enter the Irregularity Type in Spanish',
      AlertProductTypeEnglish: 'You must enter the Group Type in English',
      AlertProductTypeSpanish: 'You must enter the Group Type in Spanish',
      AlertIrregularityCriteriaEnglish: 'You must enter the Criteria Name in English',
      AlertIrregularityCriteriaSpanish: 'You must enter the Criteria Name in Spanish',
      questionDeleteCriteria: 'Are you sure to remove the Irregularity Criteria',
      deleteIrregularityCriteria: 'Irregularity Criteria removed successfully',
      editIrregularityCriteria: 'Edit Irregularity Criteria',
      editIrregularityCriteriaCorrectly: 'The Irregularity Criteria was successfully edited',
      addCriteria: 'Add Criteria',
      addArea: 'Add Area',
      nameAreaSpanish: 'Spanish Name Area',
      nameAreaEnglish: 'English Name Area',
      alertNameAreaSpanish: 'You must enter the name spanish area',
      alertNameAreaEnglish: 'You must enter the name english area',
      area: 'Area',
      editArea: 'Edit Area',
      editAreaSucces: 'The area was correctly edited',
      addAreaSucc: 'Area successfully added',
      deleteArea: 'Area successfully deleted',
      questionDeleteArea: 'Are you sure to remove the Area',
      nameIrregularityCriteria: 'Criteria Name',
      nameProductType: 'Group Type',
      nameIrregularityType: 'Irregularity Type',

      filter: 'Filter',
      labelResponsable: 'Responsable',
      labelDirectBoss: 'Direct Boss',
      labelSelectResponsableValid: 'Select a responsible or valid, an area cannot be selected as responsible',
      labelSelectDirectBossValid: 'Select a valid direct boss, an area cannot be selected as direct boss',
      invalidFilter: 'Invalid filter, This field is for filtering, do not enter emails in this field, to select display the list of users found below',
      labelAlertDirectBoss: 'You must select a direct boss',
      labelAlertResponsable: 'You must select a responsible person',
      labelStation: 'You must select a station',
      labelOnlyOneResponsable: 'You can only select one responsible',
      labelOnlyOneDirectBoss: 'You can only select one direct boss',
      stations: 'Stations',
      labelDontResponsableAndDirectBoss: 'There is no manager or direct manager assigned for this station, assign one manually'
    },
    usrConfig: {
      email:'Email',
      clave: 'New Password',
      newClave: 'Confirm Password',
      name: 'Name',
      surnames: 'Surnames',
      telephone: 'Telephone',
      extension: 'Extension',
      profile: 'Profile',
      area: 'Area',
      charge: 'Job',
      moduleTitle: 'Configuration',
      sectionTitleUsr: 'Overview',
      sectionTitlePass: 'Update Password',
      msgRequiredField: 'Field is required',
      msgMinLength: 'The password must be at least 14 characters long',
      msgMaxLength: 'The password must not exceed 20 characters',
      msgMatching: 'Password doesn&apos;t match',
      msgPattern: 'The password must contain a capital letter, a number, and a special character(.,*!?¿¡/#$%&)',
      btnUpdatePass: 'Update',
      msgUpdatePass: 'The password was updated successfully'
    },
    digitalMenu: {
      //Titles
      titleModule: 'Digital Menu',
      Name:'Name',
      CreadoPor:'Created By',
      FechaDeCarga:'Upload Date',
      Id:'id',
      Status: 'Status',
      uploadFile: 'Upload File',
      msgUpload: 'Drag and drop files here',
      msgUploadOr: 'o',
      btnBrowseFile: 'Select file',
      nameTableFile: 'File Name',
      FilesWarning:'4 food files and 4 beverage files must be loaded.',
      Active: "Active",
      Inactive: "Inactive",
      AddMsg: 'Digital Menu files were saved correctly.',
      ValidFiles:'Files validated, start loading process.',
      MmodalDTitle: "Download zip files",
      SelectDTitle: "Select the type of file you want to download",
      SelectDOption1: "Report CSV files",
      SelectDOption2: "Image resolution 1024X600",
      SelectDOption3: "Image resolution 1368X768",
      SelectDOption4: "Image resolution 1920X1080",
      SelectDError: "You must select a file type to download",
      UploadFile: "Upload Files"
    }

  },
  global: {
    editRegionAgain: 'Not edited correctly, try again',
    seeNotice: 'View Notice',
    english: 'English',
    spanish: 'Spanish',
    enter: 'Enter',
    phone: 'Phone',
    email: 'Email',
    clave: 'Password',
    editAgain: 'Not edited correctly, try again',
    export: 'Export',
    msgPicture: 'You must select a picture',
    shortDescription: 'Short Description',
    quantity: 'Quantity',
    descriptionEnglish: 'English Description',
    picture: 'Picture',
    effectiveDate: 'Effective Date',
    view: 'View',
    editFile: 'Edit File',
    addFile: 'Add File',
    fileAdded: 'File added correctly',
    author: 'Author',
    varDate: 'Date',
    name: 'Name',
    download: 'Download',
    search: 'Search',
    to: 'To',
    from: 'From',
    individualLoad: 'Individual Load',
    bulkLoad: 'Massive Load',
    fileUpload: 'File Upload',
    airline: 'Airline',
    message: 'Message',
    description: 'Description',
    correct: 'Correct',
    incorrect: 'Incorrect',
    class: 'Class',
    close: 'Close',
    station: 'Station',
    region: 'Region',
    option: 'OPTION',
    menu: 'Menu',
    submenu: 'SUBMENU',
    consult: 'Read',
    verifyFileMsg: 'Verify that the file is correct',
    notEditedCorrectly: 'Not edited correctly, try again',
    invalidFileMsg: 'Invalid File, check the required fields and file format',
    noFileSelected: 'No File Selected',
    uploadFile: 'Upload File',
    notDeleteSuccess: 'Not deleted successfully, try again',
    file: 'File',
    product: 'Group',
    products: 'Group',
    equipment: 'Aircraft',
    notAddedCorrectly: 'Not added correctly, try again',
    dragFilesUpload: 'Drag the files you want to upload here',
    deleteFilesMsg: 'Delete Selected Files',
    fileSelectMsg: 'No Files Selected',
    verifyFileNomenclatureMsg: 'Verify that all your files have the correct nomenclature',
    invalidFile: 'Invalid File',
    validFile: 'Valid File',
    fileName: 'File Name',
    add: 'Add',
    next: 'Next',
    back: 'Back',
    browse: 'Browse',
    edit: 'Edit',
    delete: 'Delete',
    send: 'Send',
    create: 'Create',
    detail: 'Detail',
    adding: 'Adding...',
    editing: 'Editing...',
    deleting: 'Deleting...',
    sending: 'Sending...',
    save: 'Save',
    clearImage: 'Clear Image',
    cancel: 'Cancel',
    confirm: 'Confirm',
    globalFilter: 'GLOBAL FILTER',
    sessionExpired: 'Your session expired',
    successful: 'Successful',
    warning: 'Warning',
    sentNotice: 'Sent notice',
    tryAgain: 'Please try again later',
    connectionError: 'Connection error',
    loading: 'Loading...',
    valueLanguge: 'Ingles',
    status: 'Status',
    yes: 'Yes',
    active: 'ACTIVE',
    inactive: 'INACTIVE',
    login: 'Logging in...',
    error: 'Error',
    filenotfound: 'The file do not exists or has not been upload or update yet.',
    date: {
      valueDayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      valueDayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      valueDayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      valueMonthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      valueMonthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      valueToday: 'Today',
      valueClear: 'Clear'
    },
    allMenu: 'all',
    titlePdf: 'Pdf',
    pdf: 'Visualize',
    downloadPdf: 'Download Pdf',
    excel: 'Download Excel',
    loadedAttached: 'No specifications file has been loaded',
    loadFile: 'File upload',
    attachedFiles: 'Attached files',
    uploadedFiles: 'Uploaded files',
    loadAttachments: 'Upload Files',
    formatContra: 'Incorrect password: Must contain a capital letter, a number, a special character, and a length of at least 14 characters',
    claveInvalid:'The password cannot be the same as the previous one.',
    fileNotFound: 'File not found or not exist, please let it know to your web admin.',
    //Propiedades de breadcrumb
    breadcrumbInicio: 'Start'
  },

};
