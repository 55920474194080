import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Component, OnDestroy, OnInit, ɵConsole } from "@angular/core";
import { map, tap } from "rxjs/operators";

import { AuthenticationService } from "src/app/security/auth0/authentication.service";
import { CurrentDataService } from "src/app/Services/current-data.service";
import { LanguageService } from "src/app/Services/language.service";
import { LoginService } from "../../Services/Login.service";
import { NavbarLoguedComponent } from "../share/navbar-logued/navbar-logued.component";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { sha256 } from "js-sha256";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @BlockUI() blockUI: NgBlockUI;
  credentials = {
    Username: "",
    Password: "",
  };

  public status: any;
  public showPsw = false;
  public auth0MessagesLanguage;
  language: any;
  languageGlobal: any;
  languageModule: any;
  isRedirectToIrregularity: boolean = false;

  errorFromAuth0: boolean = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private loginService: LoginService,
    private navLogued: NavbarLoguedComponent,
    private languagueService: LanguageService,
    private currentData: CurrentDataService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    if (localStorage.getItem("token")) {
      this.authenticationService.logout();
    }

    localStorage.clear();
    localStorage.setItem("logued", "false");

    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "false");
    }

    if (sessionStorage.getItem("responsible")) {
      let isUndefined = sessionStorage.getItem("responsible");
      if (isUndefined === "undefined") {
        sessionStorage.removeItem("responsible");
        sessionStorage.removeItem("folio");
      } else {
        this.toastr.warning(
          localStorage.getItem("language") === "true"
            ? `Your session expired.. To access the irregularity you must log in first`
            : `Su sesión expiró.. Para acceder a la irregularidad debes iniciar sesión primero`,
          "Error",
          { positionClass: "toast-bottom-full-width", closeButton: true }
        );
      }
    }
  }

  isAuthenicated$: any;

  ngOnInit() {
    localStorage.setItem("flagLogin", "true");

    this.blockUI.stop();
    document.body.classList.add("bg-img");
    this.status = 0;
    this.navLogued.ngOnInit();

    this.configureLanguage();

    this.isAuthenicated$ =
      this.authenticationService.isAuthenticated$.subscribe({
        next: (isAuthenticated) => {
          console.log('isAuthenticated', isAuthenticated);
          if (isAuthenticated) {
            this.blockUI.start(this.languageModule.login);
            this.authenticationService.getToken().subscribe({
              next: (user: any) => this.login(user),
              error: (err) => this.loginValidationError(err),
              complete: () => this.blockUI.stop(),
            });
          }
        },
        error: (err) => {
          this.loginValidationError(err);
        },
      });

    this.route.queryParamMap
      .pipe(map((params: ParamMap) => params.get("errorAuth0")))
      .subscribe((param: any) => {
        this.showErrorAuth0(JSON.parse(param));
      });

    this.authenticationService.error$.subscribe({
      next: () => this.showErrorAuth0(true),
    });
  }

  private showErrorAuth0(error: boolean) {
    if(error){
      this.errorFromAuth0 = true;
      Swal.fire({
        title: this.auth0MessagesLanguage.auth0NotAvailable,
        html: this.auth0MessagesLanguage.auth0NotAvailableDetail,
        type: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  }

  configureLanguage() {
    this.language = this.languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.login;
    this.auth0MessagesLanguage = this.language.modules.login.auth0;

    this.subscription.add(
      this.languagueService.changeLanguage.subscribe((isEnglish) => {
        this.language = this.languagueService.getLanguage();
        this.languageGlobal = this.language.global;
        this.languageModule = this.language.modules.login;
        this.auth0MessagesLanguage = this.language.modules.login.auth0;
      })
    );
  }

  login(user: any) {
    if (!this.loginService.isAuthenticated()) {
      this.loginValidation(user);
    }
  }

  NumText(e: any) {
    //solo letras y numeros
    let input = e.target.value;
    var out = "";
    //Se añaden caracteres validos
    var filtro =
      "abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ1234567890_-.@ "; //Caracteres validos

    for (var i = 0; i < input.length; i++)
      if (filtro.indexOf(input.charAt(i)) != -1) out += input.charAt(i);
    this.credentials.Username = out;
    return out;
  }

  validateUser(data: NgForm) {
    console.log(data, "metodo");
    let credentials = {
      Password: sha256(this.credentials.Password.trim()).toLocaleUpperCase(),
      Username: this.credentials.Username,
    };
    this.blockUI.start(this.languageModule.login);
    this.subscription.add(
      this.loginService.login(credentials).subscribe(
        (data: any) => {
          this.loginValidation(data);
        },
        (error) => {
          this.loginValidationError(error);
        }
      )
    );
  }

  loginValidation(data: any) {
    let status = data.ClassInfo.Status;
    if (status === 205) {
      let token = data.Token;
      localStorage.setItem("token", token);
      localStorage.setItem("logued", "true");
      localStorage.emailUser = data.Users.email;
      this.authenticationService.isLogged();
      this.router.navigate(["/changePassword"]);
      this.blockUI.stop();
    } else if (status === 200) {
      let token = data.Token;
      localStorage.setItem("logued", "true");
      this.blockUI.stop();
      localStorage.setItem("token", token);
      this.navLogued.ngOnInit();
      setTimeout(() => {
        try {
          if (this.currentData.queryParamsiIrregularities.folio) {
            this.router.navigate(["/reportIrregularities"], {
              queryParams: {
                responsible:
                  this.currentData.queryParamsiIrregularities.responsible,
                folio: this.currentData.queryParamsiIrregularities.folio,
                idForward:
                  this.currentData.queryParamsiIrregularities.idForward,
              },
            });
            this.isRedirectToIrregularity = true;
          } else if (sessionStorage.getItem("responsible")) {
            let responsible = sessionStorage.getItem("responsible");
            let folio = sessionStorage.getItem("folio");
            sessionStorage.removeItem("responsible");
            sessionStorage.removeItem("folio");
            this.router.navigate(["/reportIrregularities"], {
              queryParams: {
                responsible: responsible,
                folio: folio,
                idForward: 1,
              },
            });
            this.isRedirectToIrregularity = true;
          }
        } catch (error) {
          if (sessionStorage.getItem("responsible")) {
            let responsible = sessionStorage.getItem("responsible");
            let folio = sessionStorage.getItem("folio");
            sessionStorage.removeItem("responsible");
            sessionStorage.removeItem("folio");
            this.router.navigate(["/reportIrregularities"], {
              queryParams: {
                responsible: responsible,
                folio: folio,
                idForward: 1,
              },
            });
            this.isRedirectToIrregularity = true;
          } else {
            this.isRedirectToIrregularity = false;
            sessionStorage.removeItem("responsible");
            sessionStorage.removeItem("folio");
            this.router.navigate(["/main"]);
          }
        }
        if (!this.isRedirectToIrregularity) {
          sessionStorage.removeItem("responsible");
          sessionStorage.removeItem("folio");
          this.router.navigate(["/main"]);
        }
      }, 2000);

      this.blockUI.stop();
    } else if (status !== 200 || status !== 205) {
      this.blockUI.stop();
      this.toastr.error(
        "Usuario o Password incorrectos",
        "Verificar credenciales.",
        { positionClass: "toast-bottom-full-width", closeButton: true }
      );
    }
    this.blockUI.stop();
  }

  loginValidationError(error: any) {
    this.blockUI.stop();
    this.status = error.status;
    if (this.status == 0) {
      this.toastr.error(this.languageModule.tryConnectAgain, "Error:", {
        positionClass: "toast-bottom-full-width",
        closeButton: true,
      });
    } else {
      let errorStatus = error.error.ClassInfo.Status;
      if (errorStatus != null && errorStatus > 0) {
        if (errorStatus == 401 || errorStatus == 406) {
          this.toastr.error(
            this.languageModule.msgUserOrPasswordError,
            this.languageModule.msgVerifyCredentials,
            { positionClass: "toast-bottom-full-width", closeButton: true }
          );
        } else {
          this.toastr.error(this.languageModule.tryConnectAgain, "Error:", {
            positionClass: "toast-bottom-full-width",
            closeButton: true,
          });
        }
      }
    }
  }

  pabAccountConfigurationPasswordIsSuccessful(user: any) {
    return user.ClassInfo.Status === 201;
  }

  getSeeschweiler() {
    this.loginService.getSeeschweiler();
  }

  ngOnDestroy() {
    document.body.classList.remove("bg-img");
    this.subscription.unsubscribe();
  }

  toggleShowPsw() {
    this.showPsw = !this.showPsw;
  }

  handleLogin() {
    this.authenticationService.login();
  }
}
