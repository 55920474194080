import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import Swal from 'sweetalert2'
import { Router, NavigationStart } from '@angular/router';
import {NoticeService} from '../../Services/notice.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from 'src/app/Services/language.service';
import { LoginService } from 'src/app/Services/Login.service';
import { CurrentDataService } from 'src/app/Services/current-data.service';
import { Subscription, Subject } from 'rxjs';


declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy {
  
  private subscription: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;
  public varTitleNotification : string
  public varNotificationText : string
  public varSkip : string
  public varCheck : string
  public varViewAll : string
  public varLocalStorage : string
  public allData : any;
  public idUserEdit : number;
  public idUSer : number;

  public varLanguage: string
  public varSessionExpired: string
  public varSuccessful: string
  public varWarning: string
  public varTryAgain: string
  public varConnectionError: string
  public varLoading: string
  public stringList = []
  public varNotices : string
  public idUser : number;
  public badgeCount: number;
  public varTitleLanguage :string
  public varChooseText : string
  public varLanguageModalSpanish : string
  public varLanguageModalEnglish : string
  public varSkipModal : string
  public varCheckModal : string
 
  image1 = '/assets/images/c_1.jpg';
  carouselDefault1 = '/assets/images/compress/c_1.jpg';
    //varLanguage
    public language ;
    public languageGlobal;
    public languageModule;

  image2 = '/assets/images/c_2.JPG';
  carouselDefault2 = '/assets/images/compress/c_2.JPG';

  image3 = '/assets/images/c_3.JPG';
  carouselDefault3 = '/assets/images/compress/c_3.jpg';

  image4 = '/assets/images/c_4.jpg';
  carouselDefault4 = '/assets/images/compress/c_4.jpg';

  image5 = '/assets/images/c_5.png';
  carouselDefault5 = '/assets/images/compress/c_5.png';

  image6 = '/assets/images/c_6.jpg';
  carouselDefault6 = '/assets/images/compress/c_6.jpg';

  constructor(private router: Router,
    private noticeService : NoticeService, private toastr : ToastrService,
    private languagueService: LanguageService,
    private loginService: LoginService,
    private currentData: CurrentDataService){ 
     
  
      this.language = languagueService.getLanguage();
      this.languageGlobal = this.language.global; 
      this.languageModule = this.language.modules.main;
      this.languagueService.changeLanguage.subscribe(
        English => {
          this.language = languagueService.getLanguage();
          this.languageGlobal = this.language.global;
          this.languageModule = this.language.modules.main;
          this.getVariables()
    
        } 
      )
      
    }



  dateNow: Date= new Date();

  ngOnInit() {
  
    let personal = this.loginService.getUser();
    this.idUser = parseInt(personal.idUser.toString())
    this.varLocalStorage = localStorage.getItem('language')
    this.getVariables()
    this.idUSer = parseInt(personal.idUser.toString())
  
    
    this.initCarusel()
    this.getTop3Notice();
    this.NowDays()
    localStorage.setItem('logued', 'true');
    this.varLocalStorage = localStorage.getItem('language')
  
    if(localStorage.getItem('flagLogin') === 'true'){
        this.chooseLanguage()
    }
    this.notificationsFunction();

    
  }
  getVariables(){
    this.varSessionExpired = this.languageModule.sessionExpired;
    this.varSuccessful = this.languageGlobal.successful;
    this.varWarning = this.languageGlobal.warning;
    this.varTryAgain = this.languageGlobal.tryAgain;
    this.varConnectionError = this.languageGlobal.connectionError;
    this.varLoading = this.languageGlobal.loading;
    this. varNotices = this.language.modules.navBar.notices;
    this.varViewAll = this.languageModule.viewAll;
    this.varChooseText = this.languageModule.chooseText;
    this.varLanguageModalSpanish = this.languageModule.languageSpanish;
    this.varLanguageModalEnglish = this.languageModule.languageEnglish;
    this.varSkipModal = this.languageModule.skipModal;
    this.varCheckModal = this.languageModule.checkModal;
    this.varTitleLanguage = this.languageModule.titleLanguage;
  }

  getTop3Notice(){
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.noticeService.getTop3(this.idUSer).subscribe((data: any)=>{
      let status = 0;
      status = data.ClassInfo.Status

      if(status == 200){
        this.blockUI.stop();
        let lengthconsultNotice = data.subjects.length
        for (let i= 0; i<lengthconsultNotice; i++){
          this.stringList.push({ label: data.subjects[i] })
        }
      }
  
    }, error => {
      this.blockUI.stop();
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }));
  }

  notificationsFunction(){
    this.subscription.add(this.noticeService.getUnreadNotice(this.idUser).subscribe((data: any)=>{
    let status = 0;
    status = data.ClassInfo.Status
    if(status == 200){
      this.blockUI.stop();
    this.badgeCount = data.unreadNumber;
    }

  }, error => {
    
    this.blockUI.stop();
    if (error.status === 401 || error.status === 0) {
      this.blockUI.stop();
      this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
    } else if (error.status === 404) {
      this.blockUI.stop();
      this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
    } else if (error.status === 500 || error.status === 400) {
      this.blockUI.stop();
      this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
    }
  }));
}

  initCarusel(){
  $('.carousel').carousel()
  }

  NowDays(){
  }
  public chooseLanguage(){
    Swal.fire({
      title: this.varTitleLanguage,
      text: this.varChooseText,
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#0D0956',
      cancelButtonColor: '#A83B24',
      cancelButtonText: this.varLanguageModalSpanish,
      confirmButtonText: this.varLanguageModalEnglish,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.varTitleNotification = 'Notifications'
        if(this.badgeCount === 1){
          this.varNotificationText = 'You have'+' '+this.badgeCount+' '+'notification'
        }else{
          this.varNotificationText = 'You have'+' '+this.badgeCount+' '+'notifications'
        }
        this.varSkip = 'Skip'
        this. varCheck = 'Check'
        this.notifications();
        localStorage.setItem('language', 'true');
      }else{
        this.varTitleNotification = 'Notificaciones'
        if(this.badgeCount === 1){
          this.varNotificationText = 'Tiene'+' '+this.badgeCount+' '+'notificación'
        }else{
          this.varNotificationText = 'Tiene'+' '+this.badgeCount+' '+'notificaciones'
        }
        this.varSkip = 'Omitir'
        this. varCheck = 'Revisar'
        this.notifications();
        localStorage.setItem('language', 'false'); 
      }
    });
  }
  
  notifications(){
    Swal.fire({
      title: this.varTitleNotification,
      text: this.varNotificationText,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.varSkip,
      confirmButtonText: this.varCheck,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        localStorage.setItem('flagLogin', 'false');
         this.router.navigate(['/consultNotice'])
         .then(() => {
           window.location.reload();
         });
      }else{
        window.location.reload();
        localStorage.setItem('flagLogin', 'false');
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
