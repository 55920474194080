export const environment =
{
  production: false,
  auth0: {
    domain: 'lobbycloudgate.amx-digital.com',
    clientId: 'sWgbVDhh8AZyJfZ6gQQWP3tlGF9U0Eig',
    authorizationParams: {
      audience: 'https://portal-pab-dev',
      redirect_uri: 'https://dyn-pb-dq.amlab7.com/callback',
    },
    errorPath: '/callback',
  },
  apiUrl: 'https://dyn-pb-dq.amlab7.com/api',
  version : 'V2.1.0'
};
