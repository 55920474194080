import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { MasterService } from 'src/app/Services/master.service';
import { CurrentDataService } from 'src/app/Services/current-data.service';
import { LoginService } from 'src/app/Services/Login.service';
import { LanguageService } from 'src/app/Services/language.service';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { FormsModule } from '@angular/forms';


declare var $;

@Component({
  selector: 'app-formulario-master',
  templateUrl: './formulario-master.component.html',
  styleUrls: ['./formulario-master.component.css']
})
export class FormularioMasterComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @BlockUI() blockUI: NgBlockUI;
  valueDataMaster: any[];
public varUploadFile : any;
  // Variables permisos --------------
  public canCreate;
  public canRead;
  public canUpdate;
  public canDelete;
  public idUser: number;

  //Variables Idioma
  public varLanguage: string
  public varSessionExpired: string
  public varSuccessful: string
  public varWarning: string
  public varSentNotice: string
  public varTryAgain: string
  public varConnectionError: string
  public varLoading: string
  public varLocalStorage: string
  public varStatus: string
  public varAdd: string
  public varEdit: string
  public varDelete: string
  public varYes: string
  public varClose: string
  public varGlobalFilter: string
  public varSave: string
  public varClass: string
  public varTitleMaster: string
  public varLot: string
  public varDescription: string
  public varContainer: string
  public varLocalStation: string
  public varInternationalStation: string
  public varPicture: string
  public varIdMaterials: string
  public varDescriptionEnglish: string
  public varShortDescription: string
  public varSupplyng: string
  public varQuantity: string
  public varPartNumber: string
  public varCancel: string
  public varAddMaterials: string
  public varAddKit: string
  public varMsgPicture: string
  public varMsgLot: string
  public varMsgDescription: string
  public varMsgDescriptionEnglish : string
  public varMsgContainer: string
  public varMsgUm: string
  public varMsgLocalStation: string
  public varMsgInternationalStation: string
  public varMsgCycle: string
  public varMsgCommentary: string
  public varEnglishDescription : string
  //UM
  public umSelect: any[];
  public ums: any[];
  public valueUm: string;
  public idSelectedUm: any;
  public idSelectedUmEnglish: string;
  //LocalStation
  public localStationSelect: any[];
  public localStations: any[];
  public valueLocalStation: string;
  public idSelectedLocalStation: any;
  public idSelectedLocalStationEnglish: string;
  //InternationalStation
  public internationalStationSelect: any[];
  public cycleSelect: any[];
  public internationalStations: any[];
  public cycles : any[];
  public valueInternationalStation: string;
  public idSelectedInternationalStation: any;
  public idSelectedCycle: string;
  public idSelectedInternationalStationEnglish: string;
  file: File;
  public varNoFileSelected: string
  public lot: string
  public description: string
  public englishDescription : string
  public container: string
  public base64: any;
  public dataSend
  public send: any
  public master: boolean

  padre = 'padre';

  //Edit
  public lotEdit: string
  public descriptionEdit: string
  public englishDescriptionEdit : string
  public containerEdit: string
  public base64Edit: any;
  public idSelectedUmEdit: string;
  public umSelectEdit: any[];
  public localStationSelectEdit: any[];
  public internationalStationSelectEdit: any[];
  public response : any
  public commentary : string

    //varLanguage
    public language ;
    public languageGlobal;
    public languageModule;

    // Fechas
    value: Date;
    dateStart:Date;
    dateEnd:Date;
    maxDate = new Date();
    public valueDayNames : any[]
    public valueDayNamesShort : any[]
    public valueDayNamesMin : any[]
    public valueMonthNames : any[]
    public valueMonthNamesShort : any[]
    public valueToday : string
    public valueClear : string

    public en: any;


  constructor(private toastr: ToastrService,
    private router: Router, private languagueService: LanguageService,
    private permissionsService: PermissionsService, private MasterService: MasterService,
    private loginService: LoginService) {

      this.language = languagueService.getLanguage();
      this.languageGlobal = this.language.global;
      this.languageModule = this.language.modules.master;

      this.languagueService.changeLanguage.subscribe(
        isEnglish => {
          this.language = languagueService.getLanguage();
          this.languageGlobal = this.language.global;
          this.languageModule = this.language.modules.master;
          this.getVariables();
        }
      )
    this.canCreate = permissionsService.CanSubMenu(2, 11, 'C');
    this.canRead = permissionsService.CanSubMenu(2, 11, 'R');
    this.canUpdate = permissionsService.CanSubMenu(2, 11, 'U');
    this.canDelete = permissionsService.CanSubMenu(2, 11, 'D');
    this.formatCalendar();

  }

  ngOnInit() {
    this.varLocalStorage = localStorage.getItem('language')
    let personal = this.loginService.getUser();
    this.idUser = parseInt(personal.idUser.toString())
    this.getVariables()
    this.getCatMaster()
    this.getCycles()
    this.master = false
    this.formatCalendar();
  }

  getVariables() {
    this.varSessionExpired = this.languageGlobal.sessionExpired;
    this.varSuccessful = this.languageGlobal.successful;
    this.varWarning = this.languageGlobal.warning;
    this.varTryAgain = this.languageGlobal.tryAgain;
    this.varConnectionError = this.languageGlobal.connectionError;
    this.varLoading = this.languageGlobal.loading;
    this.varLanguage = this.languageGlobal.valueLanguge;
    this.varStatus = this.languageGlobal.status;
    this.varAdd = this.languageGlobal.add;
    this.varDelete = this.languageGlobal.delete;
    this.varEdit = this.languageGlobal.edit;
    this.varYes = this.languageGlobal.yes;
    this.varClose = this.languageGlobal.close;
    this.varGlobalFilter = this.languageGlobal.globalFilter;
    this.varSave = this.languageGlobal.save;
    this.varTitleMaster = this.languageModule.master;
    this.varLot = this.languageModule.lote;
    this.varDescription = this.languageModule.description;
    this.varContainer = this.languageModule.container;
    this.varLocalStation = this.languageModule.localStation;
    this.varInternationalStation = this.languageModule.internationalStation;
    this.varPicture = this.languageGlobal.picture;
    this.varDescriptionEnglish = this.languageGlobal.descriptionEnglish;
    this.varIdMaterials = this.languageModule.idMaterials;
    this.varQuantity = this.languageGlobal.quantity;
    this.varShortDescription = this.languageGlobal.shortDescription;
    this.varSupplyng = this.languageModule.supplyType
    this.varPartNumber = this.languageModule.partNumber;
    this.varCancel = this.languageGlobal.cancel;
    this.varAddMaterials = this.languageModule.addMaterials;
    this.varAddKit = this.languageModule.addKit;
    this.varNoFileSelected = this.languageGlobal.noFileSelected;
    this.varMsgLot = this.languageModule.msgLot;
    this.varMsgDescription = this.languageModule.msgDescription;
    this.varMsgContainer = this.languageModule.msgContainer;
    this.varMsgUm = this.languageModule.msgUm;
    this.varMsgLocalStation = this.languageModule.msgLocalStation;
    this.varMsgInternationalStation = this.languageModule.msgInternationalStation;
    this.varMsgPicture = this.languageGlobal.msgPicture;
    this.varEnglishDescription = this.languageModule.englishDescription;
    this.varMsgDescriptionEnglish = this.languageModule.msgDescriptionEnglish;
    this.varMsgCycle = this.languageModule.msgCycle
    this.varMsgCommentary = this.languageModule.msgCommentary
  }

  getCycles(){
    this.response = {
      "cycles": [
        {
          "id": "1",
        "value": "1"
        },
      {
          "id": "2",
        "value": "2"
        },
      {
          "id": "3",
        "value": "3"
        },
      {
          "id": "4",
        "value": "4"
        },
      {
          "id": "U",
        "value": "U"
        },
      {
          "id": "NA",
        "value": "NA"
        },
      ]
    }
  }

  getCatMaster() {
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.MasterService.getCatMaster().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();

            //Select UM

            this.umSelect = []
            this.ums = data.catMeasurementUnits;
            let umLength = this.ums.length
            for (let i = 0; i < umLength; i++) {
              this.umSelect.push({ name: this.ums[i].code, code: this.ums[i].idMeasurementUnit })
            }

            //Select LocalStation
            this.localStationSelect = []
            this.localStations = data.CatSupplyng;
            let catSupplyngLength = this.localStations.length
            for (let i = 0; i < catSupplyngLength; i++) {
              this.localStationSelect.push({ name: this.localStations[i].supplyng, code: this.localStations[i].idSupplyng })
            }
            //Select LocalStation
            this.internationalStationSelect = []
            this.internationalStations = data.CatSupplyng;
            let supplyngLength = this.internationalStations.length
            for (let i = 0; i < supplyngLength; i++) {
              this.internationalStationSelect.push({ name: this.internationalStations[i].supplyng, code: this.internationalStations[i].idSupplyng })
            }

            //Select Cycle
            this.cycleSelect = []
            this.cycles = this.response.cycles;
            let cycleLength = this.cycles.length
            for (let i = 0; i < cycleLength; i++) {
              this.cycleSelect.push({ label: this.cycles[i].id, value: this.cycles[i].value })
            }
          }

        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));

  }

  abrirAdd(id) {
    var file = document.getElementById(id);
    file.dispatchEvent(new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    }));
  }

  incomingfileAdd(event: any) {
    this.file = event.target.files[0];
    this.varNoFileSelected = this.file.name
  }

  formatCalendar() {
    if (this.varLanguage === 'Ingles') {
      this.valueDayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      this.valueDayNamesShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
      this.valueDayNamesMin = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
      this.valueMonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      this.valueMonthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      this.valueToday = 'Today'
      this.valueClear = 'Clear'
    } else if (this.varLanguage === 'Español') {
      this.valueDayNames = ["Domingo", "Lunes", "MArtes", "Miércoles", "Jueves", "Viernes", "Sábado"]
      this.valueDayNamesShort = ["Dom", "Lun", "Mar", "Mier", "Jue", "Vi", "Sa"]
      this.valueDayNamesMin = ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"]
      this.valueMonthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
      this.valueMonthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
      this.valueToday = 'Hoy'
      this.valueClear = 'Borrar'
    }
    this.en = {
      firstDayOfWeek: 0,
      dayNames: this.valueDayNames,
      dayNamesShort: this.valueDayNamesShort,
      dayNamesMin: this.valueDayNamesMin,
      monthNames: this.valueMonthNames,
      monthNamesShort: this.valueMonthNamesShort,
      today: this.valueToday,
      clear: this.valueClear,
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
    };
  }

  sendDataKit() {
    this.blockUI.start(this.varLoading);
    if (this.lot != null) {
      if (this.description != null) {
        if (this.englishDescription != null) {
        if (this.container != null) {
          if (this.idSelectedUm.code != null) {
            if (this.idSelectedLocalStation.code != null) {
              if (this.idSelectedInternationalStation.code != null) {
              if (this.idSelectedCycle != null) {
                if (this.file != null || this.file != undefined) {
                  let ext = null
                  if (this.file != null) {
                    let fileReader = new FileReader();

                    if (this.file.name != null) {
                      let name = this.file.name;
                      var arrayExt = name.split('.');
                      ext = arrayExt[1]
                    }

                    fileReader.readAsDataURL(this.file);
                    fileReader.onload = (e) => {
                      this.base64 = fileReader.result as string;
                      let language: number;
                      if (this.varLocalStorage === 'true') {
                        language = 2
                      } else {
                        language = 1
                      }
                      if(this.commentary === undefined){
                        this.commentary = ''

                      }else{
                        this.commentary = this.commentary
                      }

                      this.send = {
                        lote: this.lot,
                        description: this.description,
                        englishDescription: this.englishDescription,
                        container: this.container,
                        idUM: this.idSelectedUm.name,
                        idLocalStations: this.idSelectedLocalStation.name,
                        idInternationalStaions: this.idSelectedInternationalStation.name,
                        picture: this.base64,
                        nameFile: this.file.name,
                        language: language,
                        idUser : this.idUser,
                        cycle: this.idSelectedCycle,
                        commentary: this.commentary,
                        validityDate: this.dateStart
                      }
                      this.master = true;
                      this.padre = this.send;
                    }
                  } else {
                    let language: number;
                    if (this.varLocalStorage === 'true') {
                      language = 2
                    } else {
                      language = 1
                    }
                    this.send = {
                      lote: this.lot,
                      description: this.description,
                      englishDescription: this.englishDescription,
                      container: this.container,
                      idUM: this.idSelectedUm.code,
                      idLocalStations: this.idSelectedLocalStation.code,
                      idInternationalStaions: this.idSelectedInternationalStation.code,
                      idCycle: this.idSelectedCycle,
                      commentary: this.commentary,
                      Image: this.base64,
                      language: language,
                      idUser : this.idUser,
                      validityDate: this.dateStart


                    }
                  }
                } else {
                  this.blockUI.stop();
                  this.toastr.warning(this.varMsgPicture, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
                }

            } else {
              this.blockUI.stop();
              this.toastr.warning(this.varMsgCycle, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
            }
              } else {
                this.blockUI.stop();
                this.toastr.warning(this.varMsgInternationalStation, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
              }
            } else {
              this.blockUI.stop();
              this.toastr.warning(this.varMsgLocalStation, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
            }
          } else {
            this.blockUI.stop();
            this.toastr.warning(this.varMsgUm, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        } else {
          this.blockUI.stop();
          this.toastr.warning(this.varMsgContainer, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
        }
      } else {
        this.blockUI.stop();
        this.toastr.warning(this.varMsgDescriptionEnglish, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
      }

      } else {
        this.blockUI.stop();
        this.toastr.warning(this.varMsgDescription, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    } else {
      this.blockUI.stop();
      this.toastr.warning(this.varMsgLot, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
    }
  }

    sendUpdate(){
      if (this.lotEdit != null) {
        if (this.descriptionEdit != null) {
          if (this.englishDescriptionEdit != null) {
          if (this.containerEdit != null) {
            if (this.umSelectEdit != null) {
              if (this.localStationSelectEdit != null) {
                if (this.internationalStationSelectEdit != null) {
                  if (this.file != null || this.file != undefined) {
                    let ext = null
                    if (this.file != null) {
                      let fileReader = new FileReader();

                      if (this.file.name != null) {
                        let name = this.file.name;
                        var arrayExt = name.split('.');
                        ext = arrayExt[1]
                      }

                      fileReader.readAsDataURL(this.file);
                      fileReader.onload = (e) => {
                        this.base64 = fileReader.result as string;
                        let language: number;
                        if (this.varLocalStorage === 'true') {
                          language = 2
                        } else {
                          language = 1
                        }
                        this.send = {
                          lote: this.lot,
                          description: this.description,
                          container: this.container,
                          idUM: this.idSelectedUm.code,
                          idLocalStations: this.idSelectedLocalStation.code,
                          idInternationalStaions: this.idSelectedInternationalStation.code,
                          picture: this.base64,
                          nameFile: this.file.name,
                          language: language,
                          cycle: this.idSelectedCycle,
                          commentary: this.commentary,
                          validityDate: this.dateStart
                        }
                        this.master = true;
                        this.padre = this.send;
                      }
                    } else {
                      let language: number;
                      if (this.varLocalStorage === 'true') {
                        language = 2
                      } else {
                        language = 1
                      }
                      this.send = {
                        lote: this.lot,
                        description: this.description,
                        container: this.container,
                        idUM: this.idSelectedUm.code,
                        idLocalStations: this.idSelectedLocalStation.code,
                        idInternationalStaions: this.idSelectedInternationalStation.code,
                        Image: this.base64,
                        language: language,
                        cycle: this.idSelectedCycle,
                        commentary: this.commentary,
                        validityDate: this.dateStart
                      }
                    }
                  } else {
                    this.blockUI.stop();
                    this.toastr.warning(this.varMsgPicture, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
                  }
                } else {
                  this.blockUI.stop();
                  this.toastr.warning(this.varMsgInternationalStation, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
                }
              } else {
                this.blockUI.stop();
                this.toastr.warning(this.varMsgLocalStation, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
              }
            } else {
              this.blockUI.stop();
              this.toastr.warning(this.varMsgUm, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
            }
          } else {
            this.blockUI.stop();
            this.toastr.warning(this.varMsgContainer, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        } else {
          this.blockUI.stop();
          this.toastr.warning(this.varMsgDescriptionEnglish, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
        }
      } else {
        this.blockUI.stop();
        this.toastr.warning(this.varMsgDescription, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
      } else {
        this.blockUI.stop();
        this.toastr.warning(this.varMsgLot, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
  }

  closeCalendar($event) {

    if (this.dateStart && this.dateEnd) {
      let dateStartTemp = formatDate(this.dateStart, 'yyyy/MM/dd', 'en');
      let dateEndTemp = formatDate(this.dateEnd, 'yyyy/MM/dd', 'en');

      if (this.dateStart > this.dateEnd) {
        this.toastr.error(this.languageModule.ErrorDateValidateMsg, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else {
        //this.getData(dateStartTemp, dateEndTemp);
        // this.irregularitySend.dateEnd = dateEndTemp;
        // this.irregularitySend.dateInit = dateStartTemp;
      }
    } else if (!this.dateStart) {
      this.toastr.warning(this.languageModule.WarnDateMsg + this.languageModule.DateStart, this.languageGlobal.warning, { positionClass: 'toast-bottom-full-width', closeButton: true });
    } else if (!this.dateEnd) {
      this.toastr.warning(this.languageModule.WarnDateMsg + this.languageModule.DateEnd, this.languageGlobal.warning, { positionClass: 'toast-bottom-full-width', closeButton: true });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
