<block-ui>
  <div class="container-fluid">
    <div class="row divTitle">
      <section style="background:url('../../assets/images/aeromexico-frontPage.jpg');" class="titleCat titleMaster">
        <h1 class="titleComponent">TSU</h1>
      </section>
    </div>
    <!-- INICIA TABLA DONDE CARGARA LOS MATERIALES -->
    <div class="containerTsu">
      <div class="divContainer">
        <p-splitter [style]="{'height': '100%','border-style': 'none', 'width': '100%'}" [panelSizes]="[30,70]">
          <ng-template pTemplate>
            <div>
              <p-table [columns]="cols" [value]="availableMaterialsEdit" #dtt dataKey="tableImagen" [scrollable]="true"
                scrollHeight="650px">
                <ng-template pTemplate="caption">
                  <div style="text-align: left;">
                    <input type="text" class="containerFiltro uppercaseSearch" pInputText size="50" placeholder={{varGlobalFilter}}
                      (input)="filterMaterials($event.target.value)" style="width:auto" (onChange)="ngOnInit()">
                  </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th class="headertable">{{varMaterialsEdit}}</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-tableMaterialEdit let-columns="columns">
                  <tr [pSelectableRow]="contIdEdit" data-toggle="modal" (click)="openModal('#AddSuplyEditModal')">
                    <td class="ui-helper-clearfix" pDraggable="materialsEdit"
                      (onDragStart)="dragStart($event,tableMaterialEdit)" (onDragEnd)="dragEnd($event)">

                      <!-- <i class="fa fa-arrow-right" style="float:right;margin-top:8px"></i> -->
                      <div style="text-align: center; font-weight: bold;">{{tableMaterialEdit.partNumber}} </div>
                      <div class="h-divider">
                        <div class="shadow"></div>
                      </div>

                      <div style="text-align: center">{{tableMaterialEdit.shortDescription}} </div>
                      <div style="text-align: center; margin-bottom: 20px;">{{tableMaterialEdit.familydescription}} </div>
                      <!-- <img src="{{tableMaterialEdit.imege}}" style="float:left; width: 80%;" draggable="false"> -->

                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

          </ng-template>
          <ng-template pTemplate>
            <!-- INICIA TABLA DETALLE MATERIAL -->
            <div style="text-align: center;" class=" drop-column" pDroppable="materialsEdit"
              (onDrop)="drop($event)"
              [ngClass]="{'ui-highlight-material':draggedMaterialEdit}">
              <p-table class="tableMasterEdit" [columns]="cols" [value]="arrayTableEdit" [(selection)]="selectedCar1Edit"
                dataKey="partNumber">
                <ng-template pTemplate="header" let-columns>
                  <tr style="text-align: center;">
                    <th style="width: 3em" class="headertable">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th class="headertable">No. </th>
                    <th class="headertable">{{varName}}</th>
                    <th class="headertable">{{varDescriptionEnglish}}</th>
                    <th class="headertable">{{varQuantity}}</th>
                    <th class="headertable">{{varPartNumber}}</th>
                    <th class="headertable"> {{varObservations}}</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-contIdEdit let-columns="columns">

                  <tr [pSelectableRow]="contIdEdit" style="text-align: center;" class="uppercase">
                    <td>
                      <p-tableCheckbox [value]="contIdEdit"></p-tableCheckbox>
                    </td>
                    <td>{{contIdEdit.id}} </td>
                    <td>{{contIdEdit.name}}</td>
                    <td>{{contIdEdit.descriptionEnglish}}</td>
                    <td pEditableColumn>
                      <p-cellEditor (change)="addQuantity(contIdEdit.quantity, contIdEdit.id)">
                        <ng-template pTemplate="input">
                          <input class="uppercase" pInputText type="text" [(ngModel)]="contIdEdit.quantity">
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{contIdEdit.quantity}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>{{contIdEdit.partNumber}}</td>
                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input class="uppercase" pInputText type="text" [(ngModel)]="contIdEdit.observation">
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{contIdEdit.observation}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                  </tr>

                </ng-template>
                <ng-template pTemplate="summary">
                  <div style="text-align: right;">
                    <button type="button" class="btn-options styleButtonTable" (click)="deleteMaterialEdit ();"><i
                        class="far fa-trash-alt iconDelete"></i>&nbsp; {{varDelete}}</button>
                  </div>
                  <div style="text-align: right; margin-top: 15px;">
                    <button type="button" class="btn-options styleButtonTable"
                      (click)="sendCompleteKitEdit(arrayTableEdit);"><i class="far fa-save"></i>&nbsp;
                      {{varSave}}</button>
                  </div>
                </ng-template>
              </p-table>

              <div class="ui-g col-sm-12 col-md-12 col-12" style="margin-top: 20px;">
                <div class="col-sm-6 col-md-6 col-6 uppercase">
                  <label class="fontSizeLabelModal"><b>&nbsp; {{varName}}:</b> {{nameQuery}}</label>
                  <br>
                  <label class="fontSizeLabelModal"><b>&nbsp; {{varClass}}:</b> {{classeQuery}}
                  </label>
                  <br>
                  <label class="fontSizeLabelModal"><b>&nbsp; {{varCommentary}}:</b> {{commentaryQuery}} </label>

                  <br>
                </div>
              </div>

              <!--<div class="buttonAddGral" *ngIf="canCreate">

                <button style="margin-right: 30px;" type="button" class="btn-options styleButtonTable"
                  (click)="sendCompleteKitEdit(arrayTableEdit)"><i class="far fa-save"></i>&nbsp; {{varSave}}</button>
              </div>-->
            </div>
          </ng-template>
        </p-splitter>
      </div>
    </div>
  </div>
</block-ui>
