import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { CurrentDataService } from './current-data.service';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  constructor(private http: HttpClient,
    private currentData: CurrentDataService) { }
  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Authorization':  localStorage.getItem('token'),
      'Access-Control-Allow-Methods': '*',
     'Access-Control-Allow-Headers': 'X-PINGOTHER',
     'Access-Control-Max-Age': '1728000'

    });
    return headers;
  }
  getData(){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.get(apiUrl + '/SendNotice/GetDataSendNotice', {headers});
  }

  addService(send : any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    return this.http.post(apiUrl + '/SendNotice/AddNotice', send, {headers:headers});
  }
  getNotice(idUser: any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams ({fromObject:{idUser}});
    return this.http.get(apiUrl + '/SendNotice/GetNotice', {headers, params});

  }

  updateNotice(status : string, idNotice : string, idUser : string){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params  = new HttpParams ({fromObject:{status,idNotice, idUser }});
    return this.http.get(apiUrl + '/SendNotice/UpdateStatusNotice',  {headers, params});
  }
  getReadNotice(idNotice: string ){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams ({fromObject:{idNotice}});
    return this.http.get(apiUrl + '/SendNotice/getReadNotice', {headers, params});
  }

  getViewNotice(idNotice: any,idUser: any,language: any){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams ({fromObject:{idNotice,idUser,language}});
    return this.http.get(apiUrl + '/SendNotice/GetNoticePDF', {headers, params});
  }
  getAttachFile(idNotice: any,){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams ({fromObject:{idNotice}});
    return this.http.get(apiUrl + '/SendNotice/GetAttachedFile', {headers, params});
  }
  getTop3(idUSer: any,){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams ({fromObject:{idUSer}});
    return this.http.get(apiUrl + '/SendNotice/getTop3Notice', {headers, params});
  }

  getUnreadNotice(idUser: any,){
    this.currentData.isValidateSession();
    let headers = this.getHeaders();
    let params = new HttpParams ({fromObject:{idUser}});
    return this.http.get(apiUrl + '/SendNotice/GetUnreadNotice', {headers, params});
  }


}
