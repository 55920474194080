import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { LanguageService } from 'src/app/Services/language.service';
import { UntypedFormBuilder } from '@angular/forms';
import { MatrizService } from 'src/app/Services/matriz.service';
import { LoginService } from 'src/app/Services/Login.service';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-edit-matriz',
  templateUrl: './edit-matriz.component.html',
  styleUrls: ['./edit-matriz.component.css']
})
export class EditMatrizComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @BlockUI() blockUI: NgBlockUI;

  // Variables permisos --------------
  public canCreate;
  public canRead;
  public canUpdate;
  public canDelete;

  public idUser: number;
  //Variables Idioma
  public varName: string
  public varObservations: string
  public varLanguage: string
  public varSessionExpired: string
  public varSuccessful: string
  public varWarning: string
  public varKitCreated: string
  public varTryAgain: string
  public varConnectionError: string
  public varLoading: string
  public varLocalStorage: string
  public varStatus: string
  public varAdd: string
  public varEdit: string
  public varDelete: string
  public varYes: string
  public varClose: string
  public varGlobalFilter: string
  public varSave: string
  public varClass: string
  public varEquipmentFamily: string
  public varProduct: string
  public varQuantityPremier: string
  public varQuantityTourist: string
  public varLot: string
  public varCancel: string
  public quantityTouristEdit: string
  public quantityPremierEdit: string
  public varSend: string
  public varEditMatrixForm: string
  public labelEffectiveDate: string;
  //Lote
  public loteSelectEdit: any[];
  public lotesEdit: any[];
  public valueLoteEdit: string;
  public idSelectedLoteEdit: string;
  public idSelectedLoteEditEnglish: string;
  //equipmentFamily
  public equipmentFamilySelectEdit: any[];
  public equipmentsFamilyEdit: any[];
  public valueEquipmentFamilyEdit: string;
  public idSelectedEquipmentFamilyEdit: any;
  public idSelectedEquipmentFamilyEnglishEdit: string;
  public lotEdit: string
  public valueLot: any
  public cont: number = 0
  public sendEdit: any
  public sendEditQuantity: any
  //product
  public productSelectEdit: any[];
  public productsEdit: any[];
  public valueProductEdit: string;
  public idSelectedProductEdit: any;
  public idSelectedProductEnglishEdit: string;
  // Varibales lenguaje
  public language;
  public languageGlobal;
  public languageModule;
  public personal;

  public dateStart: Date;

  constructor(route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private toastr: ToastrService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private MatrizService: MatrizService,
    private languagueService: LanguageService,
    private loginService: LoginService) {
    this.subscription.add(route.queryParams.subscribe(
      data => {
        this.valueLot = data.lote
      }));
    this.language = languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.matrix;
    this.subscription.add(this.languagueService.changeLanguage.subscribe(
      isEnglish => {
        this.language = languagueService.getLanguage();
        this.languageGlobal = this.language.global;
        this.languageModule = this.language.modules.matrix;
        this.getVariables();

      }
    ));
    // Permisos
    this.canCreate = permissionsService.CanSubMenu(2, 24, 'C');
    this.canRead = permissionsService.CanSubMenu(2, 24, 'R');
    this.canUpdate = permissionsService.CanSubMenu(2, 24, 'U');
    this.canDelete = permissionsService.CanSubMenu(2, 24, 'D');
  }

  ngOnInit() {
    this.lotEdit = this.valueLot
    this.varLocalStorage = localStorage.getItem('language')
    this.valueLanguge();
    let personal = this.loginService.getUser();
    this.idUser = parseInt(personal.idUser.toString())
    this.getVariables()
    this.getCatMaster()
  }

  valueLanguge() {
    if (localStorage.getItem('language') === 'true') {
      this.varLanguage = 'Ingles'
    } else if (localStorage.getItem('language') === 'false') {
      this.varLanguage = 'Español'
    }
  }

  getVariables() {
    this.varSessionExpired = this.languageGlobal.sessionExpired;
    this.varSuccessful = this.languageGlobal.successful;
    this.varWarning = this.languageGlobal.warning;
    this.varKitCreated = this.languageModule.kitCreated;
    this.varTryAgain = this.languageGlobal.tryAgain;
    this.varConnectionError = this.languageGlobal.connectionError;
    this.varLoading = this.languageGlobal.loading;
    this.varStatus = this.languageGlobal.status;
    this.varAdd = this.languageGlobal.add;
    this.varDelete = this.languageGlobal.delete;
    this.varEdit = this.languageGlobal.edit;
    this.varYes = this.languageGlobal.yes;
    this.varClose = this.languageGlobal.close;
    this.varGlobalFilter = this.languageGlobal.globalFilter;
    this.varSave = this.languageGlobal.save;
    this.varEquipmentFamily = this.languageModule.equipmentFamily;
    this.varProduct = this.languageModule.product;
    this.varQuantityTourist = this.languageModule.touristQuantity;
    this.varQuantityPremier = this.languageModule.premierQuantity;
    this.varLot = this.languageModule.lote;
    this.varCancel = this.languageGlobal.cancel;
    this.varSend = this.languageGlobal.send;
    this.varEditMatrixForm = this.languageModule.editMatrixForm;
    this.labelEffectiveDate = this.languageModule.effectiveDateLabel;
  }

  getCatMaster() {
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.MatrizService.getData().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();
            //Select Lote
            this.loteSelectEdit = []
            this.lotesEdit = data.lote;
            let lengthCatSupplyng = this.lotesEdit.length
            for (let i = 0; i < lengthCatSupplyng; i++) {
              this.loteSelectEdit.push({ label: this.lotesEdit[i].description, value: this.lotesEdit[i].id })
            }
            //Select Equipment
            this.equipmentFamilySelectEdit = []
            this.equipmentsFamilyEdit = data.equipment;
            let catSupplyngLength = this.equipmentsFamilyEdit.length
            for (let i = 0; i < catSupplyngLength; i++) {
              this.equipmentFamilySelectEdit.push({ name: this.equipmentsFamilyEdit[i].description, code: this.equipmentsFamilyEdit[i].id })
            }
            //Select Product
            this.productSelectEdit = []
            this.productsEdit = data.product;

            let kits = data.kits.kits;
            for (let i = 0; i < kits.length; i++) {
              if (this.lotEdit === kits[i].lote && kits[i].dateModified != null) {
                this.dateStart = new Date(kits[i].dateModified);
                break;
              }
            }
            let supplyngLength = this.productsEdit.length
            for (let i = 0; i < supplyngLength; i++) {
              this.productSelectEdit.push({ name: this.productsEdit[i].description, code: this.productsEdit[i].id })
            }
          }

        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
    this.blockUI.stop();
  }
  NumQuantityPremierEdit(e: any) {
    let input = e.target.value;
    var out = '';
    var filtro = '1234567890';
    for (var i = 0; i < input.length; i++)
      if (filtro.indexOf(input.charAt(i)) != -1)
        out += input.charAt(i);
    this.quantityPremierEdit = out.toLocaleUpperCase()
    return out;
  }

  NumQuantityTouristEdit(e: any) {
    let input = e.target.value;
    var out = '';
    var filtro = '1234567890';
    for (var i = 0; i < input.length; i++)
      if (filtro.indexOf(input.charAt(i)) != -1)
        out += input.charAt(i);
    this.quantityTouristEdit = out.toLocaleUpperCase()
    return out;
  }

  sendDataEdit() {

    this.blockUI.start(this.varLoading);
    if (this.idSelectedEquipmentFamilyEdit != null && this.idSelectedEquipmentFamilyEdit != undefined) {
      if (this.idSelectedProductEdit != null && this.idSelectedProductEdit != undefined) {
        if (this.quantityTouristEdit != null && this.quantityTouristEdit != undefined) {
          if (this.quantityPremierEdit != null && this.quantityPremierEdit != undefined) {
            let sendEdit = {
              lote: this.valueLot,
              equipmentFamily: this.idSelectedEquipmentFamilyEdit.code,
              product: this.idSelectedProductEdit.code,
              quantityTourist: this.quantityTouristEdit,
              quantityPremier: this.quantityPremierEdit,
              idUser: this.idUser,
              EffectiveDate: this.dateStart
            }
            this.MatrizService.update(sendEdit).subscribe((data: any) => {
              this.blockUI.stop();
              if (data.ClassInfo.Status === 200) {
                this.router.navigate(['/catMatriz'], { queryParams: { message: this.valueLot, type: 'edit' } });
              }
              this.blockUI.stop();
            }, error => {
              this.blockUI.stop();
              if (error.status === 401 || error.status === 0) {
                this.blockUI.stop();
                this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
                localStorage.setItem('logued', 'false');
                this.router.navigate(['/login']);
              } else if (error.status === 404) {
                this.blockUI.stop();
                this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
              } else if (error.status === 500 || error.status === 400) {
                this.blockUI.stop();
                this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
              }
            });
          } else {
            this.blockUI.stop();
            this.toastr.warning(this.languageModule.msgQuantityPremier, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        } else {
          this.blockUI.stop();
          this.toastr.warning(this.languageModule.msgQuantityTourist, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
        }
      } else {
        this.blockUI.stop();
        this.toastr.warning(this.languageModule.msgProduct, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    } else {
      this.blockUI.stop();
      this.toastr.warning(this.languageModule.msgEquipmentSelect, this.varWarning, { positionClass: 'toast-bottom-full-width', closeButton: true });
    }


  }

  sendDataQuantity(lote: any, equipment: any, product: any) {
    this.cont++
    if (this.cont === 1) {
    } else if (this.cont === 2) {
      if (product != undefined) {
        this.blockUI.start(this.varLoading);
        this.sendEditQuantity = {
          lote: lote,
          equipmentFamily: equipment.code,
          product: product.code,
          idUser: this.idUser
        }
        this.blockUI.stop();
        this.subscription = this.MatrizService.getQuantity(this.sendEditQuantity).subscribe((data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.quantityTouristEdit = data.quantityTourist
            this.quantityPremierEdit = data.quantityPremier
          }
          this.blockUI.stop();
        }, error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        });
      }
      this.cont = 0
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

