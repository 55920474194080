import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { LanguageService } from 'src/app/Services/language.service';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatrizService } from 'src/app/Services/matriz.service';
import { LoginService } from 'src/app/Services/Login.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-detalle-matriz',
  templateUrl: './detalle-matriz.component.html',
  styleUrls: ['./detalle-matriz.component.css']
})
export class DetalleMatrizComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  lote: string;
  @BlockUI() blockUI: NgBlockUI;

  // Variables permisos --------------
  public canCreate;
  public canRead;
  public canUpdate;
  public canDelete;
  // Varibales lenguaje
  public language;
  public languageGlobal;
  public languageModule;
  public personal;
  // ------------------
  public detailMatrix;
  public materialDetail;
  //-------------------
  rowGroupMetadata: any;
  headers = {
    kitPhoto: null,
    materialDescE: null,
    materialDescS: null,
    titleDetalMatrixE: null,
    titleDetalMatrixS: null,
  };
  products = [];
  equipments = [];
  subheaders = [];

  pictures = [1];
  languageActual: any;
  equipmentSelect: any;
  productSelect: any;
  dataTable2: any;
  totalDistribution: any;
  lengthProducts = [];
  materials = [];
  materialsAll: any;

  constructor(route: ActivatedRoute,
    private languagueService: LanguageService,
    private permissionsService: PermissionsService,
    private toastr: ToastrService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private matrizService: MatrizService,
    private loginService: LoginService) {

    this.language = languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.matrix;
    this.languageActual = localStorage.getItem('language');


    this.subscription.add(this.languagueService.changeLanguage.subscribe(
      isEnglish => {
        this.language = languagueService.getLanguage();
        this.languageGlobal = this.language.global;
        this.languageModule = this.language.modules.matrix;
        this.languageActual = localStorage.getItem('language');

      }
    ));


    // Permisos
    this.canCreate = permissionsService.CanSubMenu(2, 24, 'C');
    this.canRead = permissionsService.CanSubMenu(2, 24, 'R');
    this.canUpdate = permissionsService.CanSubMenu(2, 24, 'U');
    this.canDelete = permissionsService.CanSubMenu(2, 24, 'D');
    this.personal = this.loginService.getUser();

    this.subscription.add(route.queryParams.subscribe(
      data => {
        this.lote = data.lote;
      }));

    this.getData();
  }

  formGroupEq: FormGroup | undefined;
  formGroupPr: FormGroup | undefined;

  ngOnInit() {

    this.formGroupEq = new FormGroup({
      selectedEq: new FormControl<[] | null>(null),
    });

    this.formGroupPr = new FormGroup({
      selectedPro: new FormControl<[] | null>(null),
    });


  }

  getData() {
    this.blockUI.start(this.languageGlobal.loading);
    this.subscription.add(this.matrizService.getDetail(this.lote).
      subscribe(
        (data: any) => {
          this.blockUI.stop();
          if (data.ClassInfo.Status === 200) {
            this.madeTable(data);
          }
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.sessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.tryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.languageGlobal.connectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }

  selectProducts = [];

  madeTable(data) {
    this.headers = data.header;
    this.products = data.product;
    this.equipments = data.equipment;
    this.totalDistribution = data.detailMatrix;
    this.materials = this.totalDistribution.materials
    this.materialsAll = this.totalDistribution.materials;


    for (let index = 0; index < data.product.length; index++) {
      this.lengthProducts.push(index);
    }

    this.subheaders = ['CJ(BC)', 'CY(EC)', 'TOTAL'];

    this.setCjandCyDefault();

    this.products.forEach(x => {
      var obj = {
        name: x
      };
      this.selectProducts.push(obj);
    });
    this.formGroupEq.controls['selectedEq'].setValue(this.equipments[0]);
    this.formGroupPr.controls['selectedPro'].setValue(this.selectProducts[0]);
  }
  onSort() {
  }

  getSelectEquipments() {
    this.equipments.forEach(e => {
    });
  }

  setCjandCyDefault() {
    let tempEquipmet = this.equipments[0];
    let tempProduct = tempEquipmet.products[0];
    this.dataTable2 = {};
    this.dataTable2 = (tempProduct.apply) ? { cj: tempProduct.data.pj, cy: tempProduct.data.py } : { cj: 'NA', cy: 'NA' };

    this.materials = this.materialsAll.filter(m => m.family === tempEquipmet.name && m.materialDescE === tempProduct.product);


    this.materials.forEach(m => {
      m.pj = Number(m.quantityMM) * Number(tempProduct.data.pj);
      m.py = Number(m.quantityMM) * Number(tempProduct.data.py);
    });

  }

  fillCjandCy() {

    let tempEquipmet = this.equipments.find(e => e.name === this.formGroupEq.controls.selectedEq.value.name);
    let tempProduct;

    tempProduct = tempEquipmet.products.find(p => p.product === this.formGroupPr.controls.selectedPro.value.name);
    this.materials = this.materialsAll.filter(m => m.family === tempEquipmet.name && m.materialDescE === this.formGroupPr.controls.selectedPro.value.name);

    this.materials.forEach(m => {
      m.pj = (tempProduct.data.pj === 'NA') ? tempProduct.data.pj : Number(m.quantityMM) * Number(tempProduct.data.pj);
      m.py = (tempProduct.data.py === 'NA') ? tempProduct.data.py : Number(m.quantityMM) * Number(tempProduct.data.py);
    });

    this.dataTable2 = {};
    this.dataTable2 = (tempProduct.apply) ? { cj: tempProduct.data.pj, cy: tempProduct.data.py } : { cj: 'NA', cy: 'NA' };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
