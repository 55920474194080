import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { Auth0RoutingModule } from './auth0-routing.module';
import { AuthenticationService } from './authentication.service';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    Auth0RoutingModule,
    AuthModule.forRoot({
      ...environment.auth0,
      useRefreshTokens: true,
      httpInterceptor: {
        allowedList: [
          `${environment.apiUrl}/auth0identity`,
          `${environment.apiUrl}/auth0identity/sendemailpasswordconfiguration`,
          `${environment.apiUrl}/catsUser/ResetUser`
        ]
      }
    }),
  ],
  providers: [
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ]
})
export class Auth0Module { }
