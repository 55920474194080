import { Component } from '@angular/core';
import { LanguageService } from './Services/language.service';
import { Router } from '@angular/router';
import { LoginService } from './Services/Login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  version = environment.version;

  constructor(private languageService: LanguageService,
    private router: Router, private loginService: LoginService
  ) {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', 'false')
    }
    console.info("FrontEnd: ",this.version);
  }

  title = 'AngularPab';
  isLogued() {
    if (localStorage.getItem('logued') == 'true') {
      return true;

    }
    return false;
  }
}
