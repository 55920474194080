import { Component, OnInit, Input, OnChanges, AfterViewChecked, OnDestroy } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { MasterService } from 'src/app/Services/master.service';
import { MaterialsService } from 'src/app/Services/materials.service';
import { CatMasterComponent } from '../cat-master/cat-master.component';
import { LoginService } from 'src/app/Services/Login.service';
import { LanguageService } from 'src/app/Services/language.service';
import { Subscription } from 'rxjs';
declare var $;

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit, AfterViewChecked, OnDestroy {
  value: any;
  materialsSave: any;
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  private subscription: Subscription = new Subscription();
  isInitial = false;
  ngAfterViewChecked(): void {
    if (this.hijo && !this.isInitial) {
      setTimeout(() => {
        this.dataMaster = this.hijo;
        this.isInitial = true;
      }, 1000);
    }

  }
  //@share
  @Input() hijo;

  @BlockUI() blockUI: NgBlockUI;
  // Variables permisos --------------
  public canCreate;
  public canRead;
  public canUpdate;
  public canDelete;
  //----------------------------------

  public idUser: number;

  lote = '';
  //varLanguage
  public language;
  public languageGlobal;
  public languageModule;

  //Variables Idioma
  public varLanguage: string
  public varSessionExpired: string
  public varSuccessful: string
  public varWarning: string
  public varKitCreated: string
  public varTryAgain: string
  public varConnectionError: string
  public varLoading: string
  public varLocalStorage: string
  public varStatus: string
  public varAdd: string
  public varEdit: string
  public varDelete: string
  public varYes: string
  public varClose: string
  public varGlobalFilter: string
  public varSave: string
  public varClass: string
  public varTitleMaster: string
  public varMaterials: string
  public availableMaterials: Material[];
  public selectedMaterials: Material[];
  public draggedMaterial: Material;
  public filteredBrands: any[];
  public dataset: any[]
  public image: any
  public varQuantity: string
  public varPartNumber: string
  public varShortDescription: string
  public varDescriptionEnglish: string
  public varSupplyType: string
  public varFamilyDescription: string
  public filterArray: any[];
  public selectedCar1: ArrayTable[] = [];
  public materialCard: ArrayTable[] = [];
  public valueId;
  public arrayTable: any[]
  public actualTable: any[]
  public arrayTableSend: any[]
  public quantity: number
  public ocultar: boolean = true;
  public cols: any[];
  public kitTable: any;
  public arrayPartNumberTable: any[]
  public arrayDataDrop
  public varLot: string
  public varDescription: string
  public varContainer: string
  public varLocalStation: string
  public varInternationalStation: string
  public varPicture: string
  public dataMaster
  public varAddKit: string
  public varSupplying: string
  public contId: any
  public contIdEdit: any
  public valorSelectSupplyng: any
  public varUploadFile: any
  public expanded: any
  public varSelectTypeSupply: string
  public varAlertTypeSupply: string
  public varTypeSupplyKits: string


  public MaterialsInfo: any[]
  public AddMaterial: any[]
  public flagArrayTable: string

  //Select supplying

  public supplyingSelect: any[];
  public supplyings: any[];
  public valueSupplying: string;
  public idSelectedSupplying: number;

  public supplying: any[]
  public numberSuply: number
  public valueSuply: string

  //UM
  public umSelect: any[];
  public ums: any[];
  public valueUm: string;
  public idSelectedUm: string;
  public idSelectedUmEnglish: string;
  //LocalStation
  public localStationSelect: any[];
  public localStations: any[];
  public valueLocalStation: string;
  public idSelectedLocalStation: string;
  public idSelectedLocalStationEnglish: string;
  //InternationalStation
  public internationalStationSelect: any[];
  public internationalStations: any[];
  public valueInternationalStation: string;
  public idSelectedInternationalStation: string;
  public idSelectedInternationalStationEnglish: string;
  public commentary: string
  public reusable: string
  public selectRow: any
  public selectRowId: any
  public arrayAdd: any
  public arrayAdding: any

  public partNumberAdd: any
  public shortDescriptionAdd: any
  public descriptionEnglishAdd: any
  public imegeAdd: any
  public idCatMaterialsAdd: any
  public partNumberAdding: any
  public shortDescriptionAdding: any
  public descriptionEnglishAdding: any
  public imegeAdding: any
  public idCatMaterialsAdding: any


  constructor(private toastr: ToastrService,
    private router: Router, private languagueService: LanguageService,
    private permissionsService: PermissionsService, private MasterService: MasterService,
    private materialService: MaterialsService, private CatMasterComponent: CatMasterComponent,
    private loginService: LoginService) {

    this.language = languagueService.getLanguage();
    this.languageGlobal = this.language.global;
    this.languageModule = this.language.modules.master;

    this.subscription.add(this.languagueService.changeLanguage.subscribe(
      isEnglish => {
        this.language = languagueService.getLanguage();
        this.languageGlobal = this.language.global;
        this.languageModule = this.language.modules.master;
        this.getVariables();
        this.generateColums();
      }
    ));
    this.canCreate = permissionsService.CanSubMenu(2, 11, 'C');
    this.canRead = permissionsService.CanSubMenu(2, 11, 'R');
    this.canUpdate = permissionsService.CanSubMenu(2, 11, 'U');
    this.canDelete = permissionsService.CanSubMenu(2, 11, 'D');

  }

  ngOnInit() {
    this.flagArrayTable = 'false'
    this.varLocalStorage = localStorage.getItem('language')
    this.valueLanguge();
    let personal = this.loginService.getUser();
    this.idUser = parseInt(personal.idUser.toString())
    this.getVariables()
    this.getDataMaterials();
    this.selectedMaterials = [];
    this.tableAvaliableMaterials()
    this.getSupplying();
    this.getCatMaster();
  }

  check() {
    if ($('input[name="check"]').is(':checked')) {
      this.reusable = '1' //Si
    } else {
      this.reusable = '0' //No
    }
  }
  valueLanguge() {
    if (localStorage.getItem('language') === 'true') {
      this.varLanguage = 'Ingles'
    } else if (localStorage.getItem('language') === 'false') {
      this.varLanguage = 'Español'
    }
  }


  getCatMaster() {
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.MasterService.getCatMaster().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();
            this.umSelect = []
            this.ums = data.catMeasurementUnits;
            let umLength = this.ums.length
            for (let i = 0; i < umLength; i++) {
              this.umSelect.push({ label: this.ums[i].code, value: this.ums[i].idMeasurementUnit })
            }

            this.localStationSelect = []
            this.localStations = data.CatSupplyng;
            let catSupplyngLength = this.localStations.length
            for (let i = 0; i < catSupplyngLength; i++) {
              this.localStationSelect.push({ label: this.localStations[i].supplyng, value: this.localStations[i].idSupplyng })
            }

          }

        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }

  sendInfoKit() {
    this.blockUI.start(this.varLoading);

    this.MaterialsInfo = []
    let send = {

      idUser: this.idUser,
      lote: this.hijo.lote,
      description: this.hijo.description,
      descriptionEnglish: this.hijo.englishDescription,
      container: this.hijo.container,
      UM: this.hijo.idUM,
      LocalStations: this.hijo.idLocalStations,
      InternationalStaions: this.hijo.idInternationalStaions,
      cycle: this.hijo.cycle,
      commentary: this.hijo.commentary,
      picture: this.hijo.picture,
      nameFile: this.hijo.nameFile,
      status: 1,
      materials: this.arrayTableSend,
      inicioVigencia: this.hijo.validityDate
    }
    this.subscription.add(this.MasterService.addKit(send).subscribe((data: any) => {
      this.blockUI.stop();
      this.toastr.success(this.varKitCreated, this.varSuccessful, { positionClass: 'toast-bottom-full-width', closeButton: true });
      this.CatMasterComponent.getDataMaster();
      this.router.navigate(['/catMaster']);
    }, error => {
      this.blockUI.stop();
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }));

  }

  sendCompleteKit(tableMaterial) {
    this.arrayTableSend = []

    let materialsLength = tableMaterial.length
    for (let i = 0; i < materialsLength; i++) {
      let valueReusable
      if (tableMaterial[i].reusable === true) {
        valueReusable = 1

      } else {
        valueReusable = 0

      }
      this.arrayTableSend.push({
        reusable: tableMaterial[i].reusable,
        commentary: tableMaterial[i].commentary,
        quantity: tableMaterial[i].quantity,
        idMaterials: tableMaterial[i].idCatMaterials.toString()
      })
    }

    this.sendInfoKit()

  }

  getVariables() {
    this.varSessionExpired = this.languageGlobal.sessionExpired;
    this.varSuccessful = this.languageGlobal.successful;
    this.varWarning = this.languageGlobal.warning;
    this.varKitCreated = this.languageModule.kitCreated;
    this.varTryAgain = this.languageGlobal.tryAgain;
    this.varConnectionError = this.languageGlobal.connectionError;
    this.varLoading = this.languageGlobal.loading;
    this.varStatus = this.languageGlobal.status;
    this.varAdd = this.languageGlobal.add;
    this.varDelete = this.languageGlobal.delete;
    this.varEdit = this.languageGlobal.edit;
    this.varYes = this.languageGlobal.yes;
    this.varClose = this.languageGlobal.close;
    this.varGlobalFilter = this.languageGlobal.globalFilter;
    this.varSave = this.languageGlobal.save;
    this.varTitleMaster = this.languageModule.master;
    this.varQuantity = this.languageGlobal.quantity;
    this.varPartNumber = this.languageModule.partNumber;
    this.varShortDescription = this.languageGlobal.shortDescription;
    this.varDescriptionEnglish = this.languageGlobal.descriptionEnglish;
    this.varSupplyType = this.languageModule.supplyType;
    this.varFamilyDescription = this.languageModule.familyDescription;
    this.varMaterials = this.languageModule.materials;
    this.varLot = this.languageModule.lote;
    this.varDescription = this.languageModule.description;
    this.varContainer = this.languageModule.container;
    this.varLocalStation = this.languageModule.localStation;
    this.varInternationalStation = this.languageModule.internationalStation;
    this.varPicture = this.languageGlobal.picture;
    this.varAddKit = this.languageModule.addKit;
    this.varSupplying = this.languageModule.supplying;
    this.varSelectTypeSupply = this.languageModule.selectTypeSupply;
    this.varAlertTypeSupply = this.languageModule.alertTypeSupply;
    this.varTypeSupplyKits = this.languageModule.typeSupplyKits;

  }

  getSupplying() {
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.MasterService.getCatMaster().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();
            //Select supplyng
            this.supplyingSelect = []
            this.supplyings = data.CatSupplyng;
            let supplyngLength = this.supplyings.length
            for (let i = 0; i < supplyngLength; i++) {
              this.supplyingSelect.push({ label: this.supplyings[i].supplyng, value: this.supplyings[i].idSupplyng })
            }
          }
          this.blockUI.stop();
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }

  valorSelectSupllyng(event) {

  }


  getDataMaterials() {
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.materialService.getData().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();
            this.image = []
            let materialsLength = data.materials.length
            for (let i = 0; i < materialsLength; i++) {
              let imagen = data.materials[i].imege;
              this.image.push(data.materials[i].imege)
              this.availableMaterials = data.materials
              this.materialsSave = data.materials
            }

            this.blockUI.stop();
          }
          this.blockUI.stop();
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }

  tableAvaliableMaterials() {
    this.getDataMaterials()
  }

  dragEndKit(algo: any) {
    this.draggedMaterial = null;
  }
  dragStartKit(event, material: Material) {
    this.selectedCar1 = []
    this.draggedMaterial = material;
    this.dropKit(this.draggedMaterial)
    this.selectedCar1.push(material)

    this.deletematerialKit();


  }
  deletematerialKit() {

    let varArrayTable
    varArrayTable = this.arrayTable
    this.arrayTable = []
    this.arrayTable = varArrayTable
    if (this.selectedCar1.length > 0) {
      this.selectedCar1.forEach(element => {
        var index: number = this.arrayTable.indexOf(this.selectedCar1.find(x => x.id == element.id));
        let idAgregar = this.selectedCar1.find(x => x.id == element.id)
        this.arrayTable.splice(index, 1);
      });
      let selectedCar1Length = this.selectedCar1.length
      this.arrayPartNumberTable = []
      for (let i = 0; i < selectedCar1Length; i++) {
        this.arrayPartNumberTable.push(this.selectedCar1[i].partNumber)

      }
      let arrayTableLength = this.selectedMaterials.length
      let arrayPartNumberTableLength = this.arrayPartNumberTable.length
      for (let t = 0; t < arrayTableLength; t++) {
        let numPartMaterial = this.selectedMaterials[t].partNumber
        for (let l = 0; l < arrayPartNumberTableLength; l++) {
          let numPartTable = this.arrayPartNumberTable[l]
          if (numPartMaterial.trim() === numPartTable.trim()) {
            this.availableMaterials.push(this.selectedMaterials[t])
          }
        }
      }
    }
    let arrayCont = this.arrayTable

    this.arrayTable = []

    let actualTableLength = arrayCont.length

    var cont = 0
    for (let i = 0; i < actualTableLength; i++) {
      cont++;
      this.arrayTable.push({
        id: cont,
        quantity: arrayCont[i].quantity,
        partNumber: arrayCont[i].partNumber,
        shortDescription: arrayCont[i].shortDescription,
        descriptionEnglish: arrayCont[i].descriptionEnglish,
        imege: arrayCont[i].imege,
        idCatMaterials: arrayCont[i].idCatMaterials,
      })
    }

  }
  idKit(row: any) {
    let id = row.id
    this.arrayAdd = []
    this.actualTable = []
    this.selectRow = []
    this.arrayAdd.push(row)
    this.actualTable = this.arrayTable
    this.selectRowId = row
    this.selectRow.push(row)
  }



  dropKit(arrayAdd: any) {
    var cont = 0
    this.arrayAdding = []
    this.arrayAdd = []
    this.arrayAdd.push(arrayAdd)
    this.selectRow.forEach(element => {
      var index: number = this.arrayTable.indexOf(this.selectRow.find(x => x.id == element.id));
      this.arrayAdding.push(this.selectRow.find(x => x.id == element.id))
      this.arrayTable.splice(index, 1);
    });

    let arrayAddLength = this.arrayAdd.length
    let arrayAddingLength = this.arrayAdding.length
    for (let i = 0; i < arrayAddLength; i++) {
      this.partNumberAdd = this.arrayAdd[i].partNumber
      this.shortDescriptionAdd = this.arrayAdd[i].shortDescription
      this.descriptionEnglishAdd = this.arrayAdd[i].descriptionEnglish
      this.imegeAdd = this.arrayAdd[i].imege
      this.idCatMaterialsAdd = this.arrayAdd[i].idCatMaterials
      for (let j = 0; j < arrayAddingLength; j++) {
        this.partNumberAdding = this.arrayAdding[j].partNumber
        this.shortDescriptionAdding = this.arrayAdding[j].shortDescription
        this.descriptionEnglishAdding = this.arrayAdding[j].descriptionEnglish
        this.imegeAdding = this.arrayAdding[j].imege
        this.idCatMaterialsAdding = this.arrayAdding[j].idCatMaterials
      }

      cont++;
      this.AddMaterial = []
      this.arrayTable.push({
        id: cont,
        quantity: 1,
        partNumber: this.partNumberAdd + ', ' + this.partNumberAdding,
        shortDescription: this.shortDescriptionAdd + ', ' + this.shortDescriptionAdding,
        descriptionEnglish: this.descriptionEnglishAdd + ', ' + this.descriptionEnglishAdding,
        imege: this.imegeAdd + ', ' + this.imegeAdding,
        idCatMaterials: this.idCatMaterialsAdd + ', ' + this.idCatMaterialsAdding,
      })
      this.flagArrayTable = 'true'


    }


  }

  drop(algo: any) {
    var cont = 0;
    if (this.flagArrayTable === 'true') {
      let arrayTable: any
      arrayTable = this.arrayTable
      this.arrayTable = []
      let arrayTableLength = arrayTable.length
      for (let i = 0; i < arrayTableLength; i++) {
        cont++;

        this.arrayTable.push({
          id: cont,
          quantity: arrayTable[i].quantity,
          partNumber: arrayTable[i].partNumber,
          shortDescription: arrayTable[i].shortDescription,
          descriptionEnglish: arrayTable[i].descriptionEnglish,
          imege: arrayTable[i].imege,
          idCatMaterials: arrayTable[i].idCatMaterials,
          commentary: arrayTable[i].commentary,
          reusable: arrayTable[i].reusable,
        })
      }

      let arrayDrag = []
      arrayDrag.push(this.draggedMaterial)
      for (let j = 0; j < arrayDrag.length; j++) {
        cont++;

        this.arrayTable.push({
          id: cont,
          quantity: this.quantity,
          partNumber: arrayDrag[j].partNumber,
          shortDescription: arrayDrag[j].shortDescription,
          descriptionEnglish: arrayDrag[j].descriptionEnglish,
          imege: arrayDrag[j].imege,
          idCatMaterials: arrayDrag[j].idCatMaterials,
          commentary: this.commentary,
          reusable: false,
        })

      }
      let draggedMaterialIndex = this.findIndex(this.draggedMaterial);
      this.selectedMaterials = [...this.selectedMaterials, this.draggedMaterial];
      this.availableMaterials = this.availableMaterials.filter((val, i) => i != draggedMaterialIndex);
      this.AddMaterial = []
      for (let j = 0; j < this.arrayTable.length; j++) {
        this.AddMaterial.push({ idMaterials: this.arrayTable[j].idCatMaterials, quality: this.arrayTable[j].quantity, reusable: this.arrayTable[j].reusable, commentary: this.arrayTable[j].commentary })
      }
    } else {
      this.arrayTable = []
      this.quantity = 1
      this.commentary = ''
      if (this.draggedMaterial) {
        let draggedMaterialIndex = this.findIndex(this.draggedMaterial);
        this.selectedMaterials = [...this.selectedMaterials, this.draggedMaterial];
        this.availableMaterials = this.availableMaterials.filter((val, i) => i != draggedMaterialIndex);
        let contador = this.selectedMaterials.length

        for (let i = 0; i < contador; i++) {
          cont++;

          this.arrayTable.push({
            id: cont,
            quantity: this.quantity,
            partNumber: this.selectedMaterials[i].partNumber,
            shortDescription: this.selectedMaterials[i].shortDescription,
            descriptionEnglish: this.selectedMaterials[i].descriptionEnglish,
            imege: this.selectedMaterials[i].imege,
            idCatMaterials: this.selectedMaterials[i].idCatMaterials,
          })

        }

      }
      this.AddMaterial = []
      let arrayTableLength = this.arrayTable.length
      for (let j = 0; j < arrayTableLength; j++) {
        this.AddMaterial.push({ idMaterials: this.arrayTable[j].idCatMaterials, quality: this.arrayTable[j].quantity, reusable: this.arrayTable[j].reusable, commentary: this.arrayTable[j].commentary })
      }
      this.flagArrayTable = 'true'

    }



  }



  saveTable(event) {
  }


  dragEnd(algo: any) {
    this.draggedMaterial = null;
  }
  dragStart(event, material: Material) {
    this.draggedMaterial = material;

  }




  deleteMaterial() {
    if (this.selectedCar1.length > 0) {
      this.selectedCar1.forEach(element => {
        var index: number = this.arrayTable.indexOf(this.selectedCar1.find(x => x.id == element.id));
        this.arrayTable.splice(index, 1);


      });
      let selectedCar1Length = this.selectedCar1.length
      this.arrayPartNumberTable = []
      for (let i = 0; i < selectedCar1Length; i++) {
        this.arrayPartNumberTable.push(this.selectedCar1[i].partNumber)

      }
      let arrayTableLength = this.selectedMaterials.length
      let arrayPartNumberTableLength = this.arrayPartNumberTable.length
      for (let t = 0; t < arrayTableLength; t++) {
        let numPartMaterial = this.selectedMaterials[t].partNumber
        for (let l = 0; l < arrayPartNumberTableLength; l++) {
          let numPartTable = this.arrayPartNumberTable[l]
          if (numPartMaterial.trim() === numPartTable.trim()) {
            this.availableMaterials.push(this.selectedMaterials[t])
          }
        }
      }
    }
    this.selectedCar1 = []
  }
  findIndex(material: Material) {
    let index = -1;
    for (let i = 0; i < this.availableMaterials.length; i++) {
      if (material.partNumber === this.availableMaterials[i].partNumber) {
        index = i;
        break;
      }
    }
    return index;
  }

  generateColums() {
    this.cols = [
      { field: 'id', header: 'No.' },
      { field: 'quantity', header: this.varQuantity },
      { field: 'partNumber', header: this.varPartNumber },
      { field: 'shortDescription', header: this.varShortDescription },
      { field: 'descriptionEnglish', header: this.varDescriptionEnglish }
    ];
  }

  filterMaterials(input) {

    let materialsTemp = this.materialsSave.filter(m => m.partNumber.includes(input.toUpperCase()));

    this.availableMaterials = [];
    this.availableMaterials = materialsTemp;
  }

}

export interface Material {
  quantity?;
  partNumber?;
  shortDescription?;
  descriptionEnglish?;
  typeofSupply?;
  familydescription?;
  imege?;
  idCatMaterials?;

}

export interface ArrayTable {
  id?;
  quantity?;
  partNumber?;
  shortDescription?;
  descriptionEnglish?;
  typeofSupply?;
  familydescription?;
  imege?;
  idCatMaterials?;
}
